import React, { Component, Fragment } from "react";
import { Panel } from "scripts/components/parts";
import { Link } from "react-router-dom";
import { LayoutPanel } from "scripts/components/layouts";
import { authenticationService } from "scripts/_services";

class LogOut extends Component {
  state = {
    firstName: "",
  };

  componentDidMount() {
    const { history } = this.props;
    const currentUser = authenticationService.currentUserValue;

    if (currentUser) {
      this.setState({
        firstName: authenticationService.currentUserValue.firstName,
      });
      authenticationService.logout();
    } else {
      // If successful, redirect to login, show succes message
      history.push("/login");
    }
  }

  render() {
    const { firstName } = this.state;
    const title = "Haere rā!";
    const text = (
      <Fragment>
        <p>Thanks for working with us {firstName}.</p>
        <p>We hope to see you again soon.</p>
      </Fragment>
    );

    const veryBottom = (
      <p>
        <Link to="/login">Back to log in</Link>
      </p>
    );

    return (
      <LayoutPanel className="log-out">
        <Panel title={title} text={text} veryBottom={veryBottom} />
      </LayoutPanel>
    );
  }
}

export default LogOut;
