import React, { Component, Fragment } from "react";

import { LayoutPage } from "scripts/components/layouts";
import {
  Button,
  Search,
  Panel,
  Tab,
  LinkGroup,
  ButtonGroup,
} from "scripts/components/parts";
import { Select } from "scripts/components/form";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "scripts/components/parts/Table";

import { updateEquipmentSerialNo } from "scripts/_helpers";
import { Link } from "react-router-dom";
import { AppContext } from "scripts/context/app-context";

import { contactService, authenticationService } from "scripts/_services";

class ListContacts extends Component {
  static contextType = AppContext;

  state = {
    contacts: null,
    machConts: null,
    equipments: null,
    contactTypeFilter: "",
    nameFilter: "",
    sortByCol: null,
    sortDirection: false,
    filteredEquipments: null,
  };

  async componentDidMount() {
    const { showLoading, hideLoading } = this.context.loading;
    showLoading();

    console.log("mounted ListContacts");

    try {
      const {
        contacts,
        machConts,
        machines: equipments,
      } = await contactService.getAll();

      equipments.forEach(updateEquipmentSerialNo);
      // Don't include myself
      console.log("contacts", contacts);
      hideLoading();
      this.setState({ contacts, machConts, equipments });
    } catch (err) {
      console.log(err);
      hideLoading();
    }
  }

  filterByType = ({ value }) => {
    this.setState({ contactTypeFilter: value });
  };

  filterBySearch = (searchValue) => {
    const { equipments } = this.state;

    if (searchValue.trim() === "") {
      this.setState({ filteredEquipments: null, nameFilter: "" });
      return;
    }

    // Find any equipments with search
    const filteredEquipments = equipments?.filter(
      (item) =>
        item.serialNo &&
        item.serialNo.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );

    // If found some seralNum
    if (filteredEquipments && filteredEquipments.length > 0) {
      this.setState({
        filteredEquipments,
        nameFilter: "",
      });
    } else {
      this.setState({ filteredEquipments: null, nameFilter: searchValue });
    }
  };

  deleteContact = (contNo) => {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;
    const { contacts } = this.state;
    const { openModal, closeModal } = this.context.modal;

    const index = contacts.findIndex((u) => u.contNo === contNo);
    const { firstName, lastName } = contacts[index];

    const title = "Delete?";
    const text = (
      <Fragment>
        <p>
          <span className="panel__person">
            {firstName} {lastName}
          </span>
          will be deleted from your account.
        </p>
        <p>This will remove them as a contact for&nbsp;any and all machines.</p>
      </Fragment>
    );

    const submitDelete = () => {
      showLoading();

      contactService.deleteContact(contNo).then((success) => {
        const newContacts = JSON.parse(JSON.stringify(contacts)).filter(
          (u) => u.contNo !== contNo
        );
        this.setState({ contacts: newContacts });
        hideLoading();
        closeModal();
        bar.showBar({ content: `Your contact has been deleted.` });
      });
    };

    const children = (
      <Panel
        title={title}
        text={text}
        onClose={closeModal}
        className={`panel--action`}
      >
        <ButtonGroup>
          <Button modifier="cancel" onClick={closeModal}>
            Cancel
          </Button>
          <Button modifier="action" onClick={submitDelete}>
            Delete
          </Button>
        </ButtonGroup>
      </Panel>
    );

    openModal(children);
  };

  filteredContacts = () => {
    const { contactTypeFilter, nameFilter, machConts } = this.state;
    let contacts = JSON.parse(JSON.stringify(this.state.contacts));

    console.log("contactTypeFilter", contactTypeFilter);
    if (contactTypeFilter !== "") {
      contacts = contacts.filter((c) => {
        const { contNo } = c;

        // Find all the machines for the contNo
        const machines = machConts.filter((m) => m.contNo === contNo);

        return machines.some(
          (m) => m.contType.toLowerCase() === contactTypeFilter.toLowerCase()
        );
      });
    }

    if (nameFilter !== "") {
      contacts = contacts.filter(
        (u) =>
          `${u.firstName} ${u.lastName}`
            .toLowerCase()
            .indexOf(nameFilter.toLowerCase()) !== -1
      );
    }

    return contacts;
  };

  renderTable = (contacts) => {
    const { sortByCol, sortDirection } = this.state;
    const currentUser = authenticationService.currentUserValue;

    contacts.forEach((contact) => {
      contact.fullName = `${contact.firstName} ${contact.lastName}`;
    });

    let sorted = contacts;

    if (sortByCol) {
      sorted = sorted.sort((a, b) => a[sortByCol].localeCompare(b[sortByCol]));

      if (sortDirection) {
        Array.prototype.reverse.call(sorted);
      }
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              modifier="contact-name"
              sort={() => {
                this.setState({
                  sortByCol: "fullName",
                  sortDirection: !sortDirection,
                });
              }}
            >
              Contact
            </TableCell>
            <TableCell className="hide-md">Email</TableCell>
            <TableCell
              className="hide-md"
              style={{ width: "10em" }}
              sort={() => {
                this.setState({
                  sortByCol: "location",
                  sortDirection: !sortDirection,
                });
              }}
            >
              Location
            </TableCell>
            <TableCell style={{ width: "11em" }} className="hide-md">
              Job title
            </TableCell>
            {currentUser.permissions.edit_contact ? (
              <TableCell modifier="contact-action">Action</TableCell>
            ) : null}
          </TableRow>
        </TableHead>

        <TableBody>
          {sorted.map((contact, idx) => {
            return (
              <TableRow key={idx}>
                <TableCell modifier="contact-name">
                  {currentUser.permissions.view_contact ? (
                    <Link
                      to={`/accounts/our-contacts/view-contact/${contact.contNo}`}
                    >
                      {contact.fullName}
                    </Link>
                  ) : (
                    contact.fullName
                  )}
                </TableCell>
                <TableCell className="hide-md">
                  <span style={{ wordBreak: "break-all" }}>
                    {contact.email}
                  </span>
                </TableCell>
                <TableCell className="hide-md">{contact.location}</TableCell>
                <TableCell className="hide-md">{contact.jobTitle}</TableCell>

                {currentUser.permissions.edit_contact ? (
                  <TableCell modifier="contact-action" type="narrow">
                    <LinkGroup>
                      <Link
                        to={`/accounts/our-contacts/replace-contact/${contact.contNo}`}
                      >
                        Replace
                      </Link>
                      <Link
                        to={`/accounts/our-contacts/edit-contact/${contact.contNo}`}
                      >
                        Edit
                      </Link>
                      <div onClick={() => this.deleteContact(contact.contNo)}>
                        Delete
                      </div>
                    </LinkGroup>
                  </TableCell>
                ) : null}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  renderTab = (equipment, index) => {
    const { machinesID, serialNo } = equipment;
    // Filter to only include machineConts with current MachineId
    const machConts = this.state.machConts.filter(
      (m) => m.machinesID === machinesID
    );
    let contacts = this.filteredContacts();

    console.log(machConts, this.state.machConts, machinesID);
    // Filter to only include contacts that have a paired value inside machineConts
    contacts = contacts.filter((c) =>
      machConts.some((m) => m.contNo === c.contNo)
    );

    return contacts.length > 0 ? (
      <Tab title={`Serial number: ${serialNo}`} key={index} isOpen={true}>
        {this.renderTable(contacts)}
      </Tab>
    ) : null;
  };

  render() {
    const { contacts, nameFilter, filteredEquipments } = this.state;
    if (!contacts) return null;
    const currentUser = authenticationService.currentUserValue;

    const title = "Our contacts";
    const options = [
      { value: "", label: "All contacts" },
      { value: "Meter", label: "Meter reading contacts" },
      { value: "Service", label: "Service contacts" },
      { value: "Consumables", label: "Consumable contacts" },
      { value: "Billing", label: "Billing contacts" },
    ];

    let data = this.filteredContacts();
    const error =
      (!data || data.length === 0) && nameFilter !== "" && !filteredEquipments
        ? { message: "No results match your search, ngana anō - try again." }
        : false;

    const control = (
      <Fragment>
        <div className="layout-page__control-select">
          <Search
            pre={"Search"}
            placeholder={`for a person or serial number`}
            onChange={this.filterBySearch}
            error={error}
            fieldWidth={380}
          />
        </div>
        <div className="layout-page__control-select">
          <Select
            label={{ text: "Showing", pos: "inline" }}
            modifiers={["yellow"]}
            options={options}
            onSelect={this.filterByType}
            placeholder={"All contacts"}
            fieldWidth={380}
          />
        </div>
      </Fragment>
    );

    const action = currentUser.permissions.add_contact ? (
      <Button to="/accounts/our-contacts/add-contact" modifier={"solid-inline"}>
        Add New Contact
      </Button>
    ) : null;

    return (
      <LayoutPage
        className="our-contacts"
        title={title}
        control={control}
        action={action}
      >
        {filteredEquipments
          ? filteredEquipments.map(this.renderTab)
          : this.renderTable(this.filteredContacts())}
      </LayoutPage>
    );
  }
}

export default ListContacts;
