/*
 * Function to clean / check serialNo for validity
 */
export const getSerialNo = (serialNo) => {
  if (typeof serialNo === "string") {
    const value = serialNo.trim();

    if (value === "") {
      return null;
    }

    if (value.toLowerCase().indexOf("non") !== -1) {
      return null;
    }

    if (value.toLowerCase().indexOf("awaiting") !== -1) {
      return null;
    }

    return value;
  }

  if (typeof serialNo === "number") {
    return serialNo;
  }
};
