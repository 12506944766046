import React, {Component} from 'react';
import { Button } from "scripts/components/parts";

class PanelNotification extends Component {
    render() {
        const { onClose, text, className } = this.props;
        
        return (
            <div className={`panel-notification ${className || ''}`}>
                <div className={`panel-notification__wrap`}>
                    { text ? 
                        <div className={`panel-notification__text`}>{text}</div> 
                    : '' }
                    <div className={`panel-notification__control`}>
                        <Button modifier={`solid-medium`} onClick={onClose}>Ok</Button>
                    </div>
                </div>
            </div>
        );
    }
}

export {PanelNotification};
                