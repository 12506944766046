import React, { Component } from "react";
import { LayoutPage } from "scripts/components/layouts";
import { Formik, Form } from "formik";
import { Button, DetailsForm } from "scripts/components/parts";
import { getError, updateEquipmentSerialNo } from "scripts/_helpers";
import {
  Input,
  isInitialValid,
  detailsSchema,
  passwordSchema,
  getContactFromFields,
  getMachinesFromFields,
  mapContactEquipments,
} from "scripts/components/form";
import { AppContext } from "scripts/context/app-context";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import {
  userService,
  authenticationService,
  equipmentService,
  contactService,
} from "scripts/_services";

import * as Yup from "yup";

class MyDetails extends Component {
  static contextType = AppContext;

  state = {
    contact: null,
    user: null,
    equipments: null,
  };

  async componentDidMount() {
    const user = authenticationService.currentUserValue;
    const { showLoading, hideLoading } = this.context.loading;

    this.setState({ user });
    showLoading();

    try {
      const { equipments } = await equipmentService.getAll();
      const { contact, machineContacts } = await contactService.getById(
        user.contact.contNo
      );

      equipments.forEach(updateEquipmentSerialNo);

      hideLoading();
      this.setState({
        contact,
        equipments: mapContactEquipments(equipments, machineContacts),
      });
    } catch (err) {
      hideLoading();
    }
  }

  renderForm = (props) => {
    const { isValid, status, dirty } = props;
    const { equipments, contact } = this.state;

    return (
      <Form className="form">
        <div className="form__group">
          <div className="form__col">
            <Input
              label={{ text: "Username" }}
              isRequired={true}
              name={`userName`}
              form={props}
            />
          </div>
        </div>
        <div className="form__group">
          <div className="form__col">
            <Input
              label={{ text: "New password" }}
              name={`newPassword`}
              type={`password`}
              autoComplete="off"
              tooltip={true}
              strength={true}
              form={props}
            />
          </div>
        </div>

        <DetailsForm
          type={"edit"}
          form={props}
          user={contact}
          equipments={equipments}
        />

        {status && <div className="form__error">{status}</div>}

        <div className="form__submit">
          <Button
            type="submit"
            disabled={!(isValid && dirty)}
            modifier={"solid-inline"}
          >
            Save changes
          </Button>
        </div>
      </Form>
    );
  };

  handleSubmit = async (
    fields,
    { setStatus, setSubmitting, resetForm },
    initialValues
  ) => {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;
    const { contNo } = authenticationService.currentUserValue.contact;
    const contact = getContactFromFields(fields, contNo);
    let fieldsCopy = JSON.parse(JSON.stringify(fields));

    showLoading();
    setStatus();

    const contactData = {
      contact,
      machineContacts: getMachinesFromFields(fields, contNo),
    };

    const changed = (name) => {
      return fields[name] !== initialValues[name];
    };

    try {
      /*
       * If userName or password changed
       * Do a separate "user update" call
       */
      if (changed("userName") || changed("newPassword")) {
        await userService.updateUser({
          userName: fields.userName,
          password: fields.newPassword,
        });
        fieldsCopy.newPassword = "";
        resetForm({ values: fieldsCopy });

        /*
         * If changed userName
         * Make sure to update username in localCache
         */
        if (changed("userName")) {
          authenticationService.updateUser((user) => {
            user.userName = fields.userName;
            return user;
          });
        }
      }

      /*
       * Submit
       * Do a "contact update" call
       */
      await contactService.editContact(contactData);

      /*
       * Update current user with new contact
       */
      authenticationService.updateUser((user) => {
        user.contact = Object.assign(user.contact, contact);
        return user;
      });

      hideLoading();
      // Reset the form with submitted values
      // resetForm({ values: data });
      bar.showBar({ content: `Your changes have been saved.` });

      window.scrollTo(0, 0);
    } catch (error) {
      hideLoading();
      setSubmitting(false);
      setStatus(getError(error));
    }
  };

  render() {
    if (!this.state.user || !this.state.contact) return null;
    const { userName } = this.state.user;
    const {
      firstName,
      lastName,
      address,
      cellPhone,
      phone,
      jobTitle,
      department,
      email,
    } = this.state.contact;
    const title = "My details";

    const initialValues = {
      userName: userName || "",
      newPassword: "",
      firstName: firstName || "",
      lastName: lastName || "",
      address: address || "",
      cellPhone: cellPhone || "",
      phone: phone || "",
      jobTitle: jobTitle || "",
      department: department || "",
      email: email || "",
    };

    return (
      <LayoutPage className="my-details" title={title}>
        <BreadcrumbsItem to="/my-details">My details</BreadcrumbsItem>

        <Formik
          initialValues={initialValues}
          isInitialValid={isInitialValid}
          validationSchema={Yup.object().shape(
            Object.assign(
              {
                userName: Yup.string().required("Username is required"),
              },
              passwordSchema("newPassword"),
              detailsSchema
            )
          )}
          onSubmit={(fields, actions) => {
            this.handleSubmit(fields, actions, initialValues);
          }}
          children={this.renderForm}
        />
      </LayoutPage>
    );
  }
}

export default MyDetails;
