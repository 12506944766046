import React, { Component } from "react";
import Tablelvl2Context from "./Tablelvl2Context";

const tablelvl2 = {
  variant: "head",
};

class TableHead extends Component {
  render() {
    const { modifier } = this.props;

    let classes = [];
    if (modifier) classes.push(`table-head--${modifier}`);

    return (
      <Tablelvl2Context.Provider value={tablelvl2}>
        <thead className={`table-head ${classes.join(" ")}`}>
          {this.props.children}
        </thead>
      </Tablelvl2Context.Provider>
    );
  }
}

export { TableHead };
