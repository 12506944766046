import React, { Component } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { AppContext } from "scripts/context/app-context";
import { Formik, Form } from "formik";
import { registrationService } from "scripts/_services";
import { getError } from "scripts/_helpers";

import { LayoutPanel } from "scripts/components/layouts";
import { Panel, Button } from "scripts/components/parts";
import { isInitialValid, Input } from "scripts/components/form";

class ForgottenDetails extends Component {
  static contextType = AppContext;

  renderForm = (props) => {
    const { status, isValid } = props;

    return (
      <Form className="form">
        <div className="form__group">
          <Input
            label={{ text: "Email address" }}
            name={`email`}
            form={props}
          />
        </div>

        {status && <div className="form__error">{status}</div>}

        <div className="form__submit">
          <Button type="submit" disabled={!isValid}>
            CONTINUE
          </Button>
        </div>
      </Form>
    );
  };

  handleSubmit = ({ email }, { setStatus, setSubmitting, resetForm }) => {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;

    showLoading();
    setStatus();

    registrationService.forgottenPassword(email).then(
      (data) => {
        hideLoading();
        // If succedful show succes message
        const content = `An email has been sent to you with a temporary link.`;
        const props = { content };

        bar.showBar(props);

        resetForm();
      },
      (error) => {
        hideLoading();
        setSubmitting(false);
        setStatus(getError(error));
      }
    );
  };

  render() {
    const title = "Forgotten<br/>details";
    const text = (
      <p>
        If you’ve forgotten your
        <br />
        Username or password, we will send you a temporary link to reset it.
      </p>
    );
    const bottom = (
      <p>
        <Link to="/login">Return to log in</Link>
      </p>
    );

    return (
      <LayoutPanel className="forgotten-details">
        <Panel title={title} text={text} bottom={bottom}>
          <Formik
            initialValues={{
              email: "",
            }}
            isInitialValid={isInitialValid}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Invalid email")
                .required("Email is required"),
            })}
            onSubmit={this.handleSubmit}
            children={this.renderForm}
          />
        </Panel>
      </LayoutPanel>
    );
  }
}

export default ForgottenDetails;
