import React, { Component } from "react";
import { LayoutPage } from "scripts/components/layouts";

import { Button, DetailsForm } from "scripts/components/parts";
import {
  isInitialValid,
  detailsSchema,
  getContactFromFields,
  getMachinesFromFields,
  mapContactEquipments,
} from "scripts/components/form";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getError, updateEquipmentSerialNo } from "scripts/_helpers";

import { contactService, equipmentService } from "scripts/_services";
import { AppContext } from "scripts/context/app-context";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

class EditContact extends Component {
  static contextType = AppContext;

  state = {
    contact: null,
    equipments: null,
    // machineContacts: null,
  };

  async componentDidMount() {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;
    const { id } = this.props.match.params;

    showLoading();

    try {
      const { contact, machineContacts } = await contactService.getById(id);
      const { equipments } = await equipmentService.getAll();

      equipments.forEach(updateEquipmentSerialNo);

      this.setState({
        contact,
        equipments: mapContactEquipments(equipments, machineContacts),
      });
      hideLoading();
    } catch (err) {
      bar.showBar({
        content: `Error, no user with this ID.`,
        modifier: "red",
      });
      hideLoading();
    }
  }

  renderForm = (props) => {
    const { contact } = this.state;
    const { isValid, dirty, status } = props;

    return (
      <Form className="form">
        <DetailsForm
          form={props}
          user={contact}
          equipments={this.state.equipments}
          type={"edit"}
        />

        {/* <Tab title={`MyAccount permissions`} isOpen={true} modifier="equipment">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User Groups</TableCell>
                <TableCell modifier="member-of">Member of</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {userGroups.map((group, idx) => (
                <TableRow key={idx} isSelected={props.values[group.value]}>
                  <TableCell>{group.display}</TableCell>
                  <TableCell align="center">
                    <Checkbox
                      name={group.value}
                      form={props}
                      modifiers={["table"]}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Tab> */}

        {status && <div className="form__error">{status}</div>}

        <div className="form__submit">
          <Button
            type="submit"
            //disabled={!(isValid && dirty)}
            //disabled={!(isValid)}
            modifier={"solid-inline"}
          >
            SAVE CHANGES
          </Button>
        </div>
      </Form>
    );
  };

  handleSubmit = async (fields, { setStatus, setSubmitting, resetForm }) => {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;
    const { id: contNo } = this.props.match.params;

    const data = {
      contact: getContactFromFields(fields, contNo),
      machineContacts: getMachinesFromFields(fields, contNo),
    };

    showLoading();
    setStatus();

    try {
      const response = await contactService.editContact(data);
      console.log("response", response);
      hideLoading();
      bar.showBar({ content: `This contact has been updated.` });
      this.props.history.push("/accounts/our-contacts");
    } catch (error) {
      hideLoading();
      setSubmitting(false);
      setStatus(getError(error));
    }
  };

  render() {
    const { contact } = this.state;
    if (!contact) return null;
    const {
      firstName,
      lastName,
      address,
      cellPhone,
      phone,
      jobTitle,
      department,
      email,
    } = contact;

    const title = "Edit contact";

    return (
      <LayoutPage className="edit-contact" title={title}>
        <BreadcrumbsItem to="/accounts/our-contacts/edit-contact">
          Edit contact
        </BreadcrumbsItem>

        <Formik
          initialValues={{
            firstName: firstName || "",
            lastName: lastName || "",
            address: address || "",
            cellPhone: cellPhone || "",
            phone: phone || "",
            jobTitle: jobTitle || "",
            department: department || "",
            email: email || "",
          }}
          isInitialValid={isInitialValid}
          validationSchema={Yup.object().shape(detailsSchema)}
          onSubmit={this.handleSubmit}
          children={this.renderForm}
        />
      </LayoutPage>
    );
  }
}

export default EditContact;
