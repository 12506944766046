export const getError = (error, defaultError = null) => {
  if (typeof error === "string") {
    return error;
  }

  if (typeof error === "object") {
    return (
      error.errormessage ||
      error.message ||
      error.title ||
      defaultError ||
      "Error"
    );
  }
};
