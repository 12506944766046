import React, {Component} from 'react';
import { NavLink } from "react-router-dom";

class Dropdown extends Component {

    renderItem = (item, index) => {
        const { title, children, link, onClick, href, target } = item;
        let Tag = link ? NavLink : 'div';
        const props = {};
        
        if (link) {
            props.to = link;
            props.activeClassName = 'is-current';
        }

        if (href) {
            Tag = 'a'
            props.href = href;

            if (target) {
                props.target = target;
            }
        }
        
        return (
            <div className={`dropdown__item`} key={index}>
                <Tag 
                    {...props} 
                    onClick={onClick}
                    className={`dropdown__title dropdown__title--${children ? 'dropdown' : ''}`}
                >
                    {title}
                </Tag>
                { children ? 
                    <div className={`dropdown__dropdown`}>
                        {children.map(this.renderItem)}
                    </div> 
                : ''}
            </div>
        );
    };

    render() {
        const { items } = this.props;

        return (
            <div className={`dropdown`}>
                {items.map(this.renderItem)}
            </div>
        );
    }
}

export {Dropdown};
                