import React, { Component } from "react";
import { LayoutPage } from "scripts/components/layouts";
import { Button, Panel, ButtonGroup } from "scripts/components/parts";
import {
  Select,
  isInitialValid,
  FormField,
  Checkbox,
} from "scripts/components/form";
import { Formik, Form } from "formik";
import { AppContext } from "scripts/context/app-context";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import * as Yup from "yup";
import { getError } from "scripts/_helpers";

import { contactService } from "scripts/_services";

/*
 * Value is used to send to API
 */
const contactTypes = [
  { name: "meter", text: "Meter reading", value: "Meter" },
  { name: "billing", text: "Billing", value: "Billing" },
  { name: "service", text: "Service", value: "Service" },
  { name: "consumables", text: "Consumables", value: "Consumables" },
];
class ReplaceContact extends Component {
  static contextType = AppContext;

  state = {
    contacts: null,
    selected: null,
  };

  async componentDidMount() {
    const { showLoading, hideLoading } = this.context.loading;
    const { id } = this.props.match.params;

    showLoading();

    try {
      const { contacts } = await contactService.getAll();

      const options = contacts.map((c) => {
        return {
          value: c.contNo,
          label: `${c.firstName} ${c.lastName}`,
        };
      });

      this.setState({
        contacts: options,
        selected: options.find((c) => c.value === id),
      });

      hideLoading();
    } catch (err) {
      hideLoading();
    }
  }

  changeReplace = (option) => {
    this.setState({ selected: option });
  };

  renderForm = (props) => {
    const { contacts, selected } = this.state;
    const { isValid, status } = props;

    return (
      <Form className="form">
        <div className="form__group">
          <div className="form__col">
            <Select
              label={{ text: "Replace" }}
              name={`value_replace`}
              form={props}
              modifiers={["white"]}
              options={contacts}
              onSelect={this.changeReplace}
              placeholder={"Select contact"}
            />
          </div>
        </div>

        <div className="form__group">
          <div className="form__col">
            <Select
              label={{ text: "With" }}
              name={`value_with`}
              form={props}
              modifiers={["white"]}
              options={contacts.filter((u) => u.value !== selected.value)}
              placeholder={"Select contact"}
            />
          </div>
        </div>

        <div className="form__group">
          <FormField label={{ text: "As a: " }}>
            {contactTypes.map((item, index) => {
              const { name, text } = item;

              return (
                <Checkbox
                  key={index}
                  name={name}
                  label={{ text }}
                  form={props}
                  modifiers={["inline"]}
                />
              );
            })}
          </FormField>
        </div>

        <div className="form__note">Contact for any linked serial numbers.</div>

        {status && <div className="form__error">{status}</div>}

        <div className="form__submit">
          <Button type="submit" disabled={!isValid} modifier={"solid-inline"}>
            Save changes
          </Button>
        </div>
      </Form>
    );
  };

  handleSubmit = (fields, { setStatus, setSubmitting, resetForm }) => {
    const { showLoading, hideLoading } = this.context.loading;
    const { openModal, closeModal } = this.context.modal;
    const { bar } = this.context;
    const { contacts } = this.state;

    const { value_replace, value_with } = fields;
    const contactReplace = contacts.find((c) => c.value === value_replace);
    const contactWith = contacts.find((c) => c.value === value_with);

    const ContTypes = [];

    contactTypes.forEach((c) => {
      // If the checkbox under this name is true
      if (fields[c.name]) {
        ContTypes.push(c.value);
      }
    });
    const data = {
      OldContNo: value_replace,
      NewContNo: value_with,
      ContTypes,
    };

    // Function to be used when clicked "Replace"
    const replaceAction = async () => {
      showLoading();
      setStatus();

      try {
        const response = await contactService.replaceContact(data);
        console.log(response);
        closeModal();
        hideLoading();

        // Reset the form with submitted values
        // resetForm({ })
        bar.showBar({ content: `These contacts have been updated.` });
        this.props.history.push("/accounts/our-contacts");
      } catch (err) {
        closeModal();
        hideLoading();
        setSubmitting(false);
        setStatus(getError(err));
      }
    };

    const title = "Replace";
    const text = (
      <p>
        Any serial numbers that had
        <span className="panel__person">{contactReplace.label}</span>
        as the selected type of contact
        <br />
        will now have
        <span className="panel__person">{contactWith.label}</span>
        set as a contact instead.
      </p>
    );

    const bottom = (
      <p>
        This will not delete the account of
        <span className="panel__person">{contactReplace.label}</span>
      </p>
    );

    const children = (
      <Panel
        title={title}
        text={text}
        onClose={closeModal}
        bottom={bottom}
        className={`panel--action`}
      >
        <ButtonGroup>
          <Button modifier="cancel" onClick={closeModal}>
            Cancel
          </Button>
          <Button modifier="action" onClick={replaceAction}>
            Replace
          </Button>
        </ButtonGroup>
      </Panel>
    );

    openModal(children);
  };

  render() {
    const { contacts, selected } = this.state;
    if (!contacts) return null;
    const title = "Replace contact";

    return (
      <LayoutPage className="add-contact" title={title}>
        <BreadcrumbsItem to="/accounts/replace-contact">
          Replace contact
        </BreadcrumbsItem>
        <Formik
          initialValues={{
            value_replace: selected ? selected.value : "",
          }}
          isInitialValid={isInitialValid}
          validationSchema={Yup.object().shape({
            value_replace: Yup.string().required("Replace value is required"),
            value_with: Yup.string().required("With value is required"),
            meter: Yup.string(),
            billing: Yup.string(),
            service: Yup.string(),
            consumables: Yup.string(),
            checkboxes: Yup.bool().when(
              ["meter", "billing", "service", "consumables"],
              {
                is: (meter, billing, service, consumables) => {
                  const t = (value) => {
                    return value === "true";
                  };

                  const check = !(
                    t(meter) ||
                    t(billing) ||
                    t(service) ||
                    t(consumables)
                  );
                  // console.log(check, !!t(meter), t(billing), t(service), t(consumables))
                  return check;
                },
                then: Yup.bool().required("At least one checkbox is required"),
                otherwise: Yup.bool(),
              }
            ),
          })}
          children={this.renderForm}
          onSubmit={this.handleSubmit}
        />
      </LayoutPage>
    );
  }
}

export default ReplaceContact;
