import React, { Component, Fragment } from "react";
import { PrivateRoute } from "scripts/components/routing/PrivateRoute";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import ListContacts from "./ListContacts";
import AddContact from "./AddContact";
import ViewContact from "./ViewContact";
import ReplaceContact from "./ReplaceContact";
import EditContact from "./EditContact";

class OurContacts extends Component {
  render() {
    return (
      <Fragment>
        <BreadcrumbsItem to="/accounts/our-contacts/">
          Our contacts
        </BreadcrumbsItem>

        <PrivateRoute
          exact
          path="/accounts/our-contacts/"
          component={ListContacts}
        />
        <PrivateRoute
          path="/accounts/our-contacts/add-contact"
          permissions={["add_contact"]}
          component={AddContact}
        />
        <PrivateRoute
          path="/accounts/our-contacts/view-contact/:id"
          permissions={["view_contact"]}
          component={ViewContact}
        />
        <PrivateRoute
          path="/accounts/our-contacts/edit-contact/:id"
          permissions={["edit_contact"]}
          component={EditContact}
        />
        <PrivateRoute
          path="/accounts/our-contacts/replace-contact/:id?"
          permissions={["edit_contact"]}
          component={ReplaceContact}
        />
      </Fragment>
    );
  }
}

export default OurContacts;
