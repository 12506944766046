import React, { Component } from "react";
import { Link } from "react-router-dom";

/*
Possible props:

- modifier (modifier class)
- tooltip (tooltip content)
- type (for example "submit") - will use <button></button>
- to (For link) - will use Link
- disabled = true / false
*/

class Button extends Component {
  render() {
    const {
      children,
      modifier,
      tooltip,
      type,
      className,
      disabled,
      noClick,
      fullWidth,
      ...rest
    } = this.props;

    let Tag = "button";
    if (this.props.to) Tag = Link;
    if (noClick) Tag = "div";

    return (
      <Tag
        className={`button button--${modifier || "solid"} ${
          disabled ? "is-button-disabled" : ""
        } ${fullWidth && "button--fullWidth"}
        ${className || ""} ${noClick ? "is-no-action" : ""}`}
        {...rest}
      >
        <div className="button__content">{children}</div>
        {tooltip ? (
          <div
            className="button__tooltip"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {tooltip}
          </div>
        ) : (
          ""
        )}
      </Tag>
    );
  }
}

export { Button };
