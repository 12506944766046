import React, { Component } from "react";

class LinkGroup extends Component {
  render() {
    const { children } = this.props;

    return <div className={`link-group`}>{children}</div>;
  }
}

export { LinkGroup };
