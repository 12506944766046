export const showPdf = (data) => {
  let binaryString = window.atob(data);
  let binaryLen = binaryString.length;
  let bytes = new Uint8Array(binaryLen);

  for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }

  //Create a Blob from the PDF Stream
  const file = new Blob([bytes], {
    type: "application/pdf",
  });

  //Build a URL from the file
  const fileURL = URL.createObjectURL(file);
  //Open the URL on new Window
  window.open(fileURL);
};
