import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { registrationService } from "scripts/_services";
import { AppContext } from "scripts/context/app-context";

import { LayoutPanel } from "scripts/components/layouts";
import { Button, Panel } from "scripts/components/parts";
import { Input, isInitialValid } from "scripts/components/form";

class CheckAccount extends Component {
  static contextType = AppContext;

  renderForm = (props) => {
    const { isValid, status } = props;

    return (
      <Form className="form">
        <div className="form__group">
          <Input
            label={{ text: "Account number" }}
            name={`accountNumber`}
            form={props}
            autofocus={true}
            tooltip={
              <Fragment>
                <p>
                  You can locate your account number
                  on&nbsp;a&nbsp;recent&nbsp;invoice.
                </p>
                <p>
                  For further assistance please
                  call&nbsp;0800&nbsp;493&nbsp;769.
                </p>
              </Fragment>
            }
          />
        </div>
        <div className="form__group">
          <Input
            label={{ text: "Serial number" }}
            name={`serialNumber`}
            form={props}
            tooltip={
              <Fragment>
                <p>You can locate your serial number on&nbsp;your machine.</p>
                <p>
                  For further assistance please
                  call&nbsp;0800&nbsp;493&nbsp;769.
                </p>
              </Fragment>
            }
          />
        </div>

        {status && <div className="form__error">{status}</div>}

        <div className="form__submit">
          <Button type="submit" disabled={!isValid}>
            CONTINUE
          </Button>
        </div>
      </Form>
    );
  };

  handleSubmit = (
    { accountNumber, serialNumber },
    { setStatus, setSubmitting }
  ) => {
    const { showLoading, hideLoading } = this.context.loading;
    const { data } = this.props;
    const { finishStep } = this.props.injectedProps;

    showLoading();
    setStatus();

    registrationService.checkAccount(accountNumber, serialNumber).then(
      (response) => {
        const newData = JSON.parse(JSON.stringify(data));

        newData.tradeName = response.tradeName;
        newData.AccountNum = accountNumber;
        newData.SerialNo = serialNumber;
        hideLoading();
        // Finish this step of registration, update with new data
        finishStep(newData);
      },
      (error) => {
        hideLoading();
        setSubmitting(false);
        setStatus(`Account or serial number doesn't exist`);
      }
    );
  };

  render() {
    const { accountNumber, serialNumber } = this.props.data;
    const title = "Register";
    const text = (
      <p>
        Welcome to our new-look MyAccount.
        <br />
        Are you <Link to="/login">already a user?</Link>
      </p>
    );

    return (
      <LayoutPanel className="check-account">
        <Panel title={title} text={text}>
          <Formik
            initialValues={{
              accountNumber: accountNumber || "",
              serialNumber: serialNumber || "",
            }}
            isInitialValid={isInitialValid}
            validationSchema={Yup.object().shape({
              accountNumber: Yup.string().required(
                "Account number is required"
              ),
              serialNumber: Yup.string().required("Serial number is required"),
            })}
            onSubmit={this.handleSubmit}
            children={this.renderForm}
          />
        </Panel>
      </LayoutPanel>
    );
  }
}

export default CheckAccount;
