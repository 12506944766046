import React, { Component } from "react";

class IconPrinter extends Component {
  render() {
    const { color } = this.props;

    const fill = color || `#fff`;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="67.711"
        height="91.409"
        viewBox="0 0 67.711 91.409"
      >
        <path
          fill={fill}
          d="M64.969 16.378h-31.11V9.733l31.4-7.021a1.373 1.373 0 0 0-.6-2.679L33.853 6.92V3.568H2.743A2.745 2.745 0 0 0-.002 6.313v75.944a2.745 2.745 0 0 0 2.745 2.745h8.235v5a1.4 1.4 0 0 0 1.217 1.4 1.373 1.373 0 0 0 1.528-1.364v-5.033h40.26v5a1.4 1.4 0 0 0 1.217 1.4 1.373 1.373 0 0 0 1.528-1.364v-5.033h8.241a2.745 2.745 0 0 0 2.745-2.745v-63.14a2.745 2.745 0 0 0-2.745-2.745ZM2.748 6.313h28.365v10.065H2.745Zm62.221 75.944H2.745V66.704h23.79v5.49H42.09v-5.49h22.879ZM29.284 66.702h10.065v2.745H29.28Zm35.685-2.745H2.745V48.404h23.79v5.49H42.09v-5.49h22.879ZM29.284 48.402h10.065v2.745H29.28Zm35.685-2.745H2.745V19.123h62.224Z"
          data-name="multifunction printer_green"
        />
      </svg>
    );
  }
}

export { IconPrinter };
