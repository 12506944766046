import React, { Component } from "react";
import { LayoutPage } from "scripts/components/layouts";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

function isVowel(c) {
  return ["a", "e", "i", "o", "u"].indexOf(c.toLowerCase()) !== -1;
}

class Confirmation extends Component {
  render() {
    const { renderStepNav, callType } = this.props;
    const title = "Confirmation";

    let text = (
      <span>
        Your request for {isVowel(callType[0]) ? "an" : "a"}{" "}
        {callType.toLowerCase()} has been received.
      </span>
    );

    if (callType === "Analyst") {
      text = `Your request for an analyst has been received.`;
    }

    if (callType === "Training") {
      text = `Your request for a trainer has been received.`;
    }

    const action = renderStepNav(this.props.injectedProps);

    return (
      <LayoutPage className="service-call" title={title} action={action}>
        <BreadcrumbsItem to="/service-call/select-equipment">
          Confirmation
        </BreadcrumbsItem>

        <div className="confirmation">
          <div className="confirmation__h">Thank you!</div>
          <div className="confirmation__text">
            <p>{text}</p>
            <p>One of our team will be in touch with you shortly.</p>
          </div>
        </div>
      </LayoutPage>
    );
  }
}

export default Confirmation;
