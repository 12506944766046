import "es6-promise/auto";
import "core-js/es/symbol";
import "core-js/es/object";
import "core-js/es/function";
import "core-js/es/parse-int";
import "core-js/es/parse-float";
import "core-js/es/number";
import "core-js/es/math";
import "core-js/es/string";
import "core-js/es/date";
import "core-js/es/array";
import "core-js/es/regexp";
import "core-js/es/map";
import "core-js/es/weak-map";
import "core-js/es/set";

// Remove IE11 tooltip for Capslock
if (document.msCapsLockWarningOff === false) {
  document.msCapsLockWarningOff = true;
}
