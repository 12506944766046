import React, { Component } from "react";

class IconInk extends Component {
  render() {
    const { color } = this.props;

    const fill = color || `#fff`;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48.084"
        height="86.5"
        viewBox="0 0 48.084 86.5"
      >
        <g fill={fill}>
          <path
            d="M24.844 81.135c-.435-3.837-5.16-11.405-5.16-11.405s-4.726 7.568-5.161 11.405l-.017.152a4.804 4.804 0 0 0-.021.422 5.216 5.216 0 0 0 10.4 0c0-.134-.008-.276-.021-.422a3.951 3.951 0 0 1-.02-.152Z"
            data-name="Path 1828"
          />
          <path
            d="m5.343 46.623 8.9 4.452v-23.11l-8.9-4.535Zm2.671-18.871 3.562 1.781v17.22l-3.562-1.781Z"
            data-name="Path 1829"
          />
          <path
            d="M48.084 9.492 31.002.002l-31 13.919v38.435l7.124 3.562v4.69c0 3.316 4.976 5.647 11.576 5.647s11.576-2.331 11.576-5.647v-4.026l10.685-5.11-.013-19.938 7.137-3.706Zm-24.273 4.373-2.085-1.03L35.669 5.65l2.02 1.122Zm7.064-10.878 2.287 1.271-17.489 8.569 8.124 4.01L40.93 8.573l2.9 1.614-24.568 11.75-14.473-7.245Zm-28.2 47.718v-34.08l15.137 7.568v34.079Zm24.932 9.9c0 1.252-3.551 3.144-8.9 3.144s-8.9-1.892-8.9-3.144v-3.353l9.328 4.664 8.481-4.056Zm17.809-34.4-7.123 3.7.013 19.885-17.821 8.523v-34l24.932-11.921Z"
            data-name="Path 1830"
          />
        </g>
      </svg>
    );
  }
}

export { IconInk };
