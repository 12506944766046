import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./assets.js";
import "./polyfills.js";

import React from "react";
import ReactDOM from "react-dom";
// import {ThroughProvider} from 'react-through'
import App from "./scripts/App";
import * as serviceWorker from "./serviceWorker";

import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

ReactDOM.render(
  <BreadcrumbsProvider>
    <App />
  </BreadcrumbsProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
