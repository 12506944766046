import React, { Component } from "react";

class CardExternal extends Component {
    render() {
        const { title, icon, to } = this.props;

        return (
            <a href={to} target="_blank" rel="noopener noreferrer" className={`cardExternal `}>
                <div className={`card__icon`}>{icon}</div>
                <div
                    className={`card__title`}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
            </a>
        );
    }
}

export { CardExternal };