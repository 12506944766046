import React, { Component, Fragment } from "react";

import { Button, Address } from "scripts/components/parts";
import { isMobile } from "scripts/utils/browser-detect";
import { LayoutPage } from "scripts/components/layouts";
import {
  Input,
  Select,
  Textarea,
  isInitialValid,
  FormField,
} from "scripts/components/form";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { AppContext } from "scripts/context/app-context";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import { equipmentService, authenticationService } from "scripts/_services";

const typesOfCall = [
  { value: "Service", label: "Service call" },
  { value: "Analyst", label: "Analyst call" },
  { value: "Training", label: "Training call" },
];

class LogServiceCall extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.textareaRef = React.createRef();
  }

  focus = () => {
    // If mobile, don't focus
    if (isMobile) return;

    if (this.textareaRef.current) {
      setTimeout(() => {
        this.textareaRef.current.focus();
        // this.textareaRef.current.value = "";
      }, 1);
    }
  };

  componentDidMount() {
    // Focus textarea
    this.focus();
    console.log("componentDidMount");
  }

    renderForm = (props) => {
        const { updateDescription } = this.props;
        const { serialNo } = this.props.selectedEquipment;
        const { status, isValid } = props;
/*        const { modelDescription } = this.props.modelDescription;*/
        const { prevStep } = this.props.injectedProps;

        const noActions = this.props.selectedEquipment.modelDescription ? this.props.selectedEquipment.modelDescription.substring(0, 3) === 'EOL' : false ;

        const handleChange = (e) => {
            props.handleChange(e);
            updateDescription(e.target.value);
        };

        return (
          
          <Form className="form">
                {noActions
                    ?
                    <Fragment>
                        <p>
                            The machine serial number you have entered has reached its End of Service life and can no longer have toner or service calls ordered for it. Please contact your Account Manager to discuss options.<br />

                        </p>
                    </Fragment>
                    :

                    <div>
                        <div className="form__group">
                            <FormField
                                label={{ text: "Location of equipment" }}
                                isRequired={true}
                                name={`address`}
                            >
                                <Address form={props} name={`address`} modifier="default" />
                            </FormField>
                        </div>
                        <div className="form__group">
                            <div className="form__col">
                                <Input
                                    label={{ text: "Service call contact" }}
                                    name={`contact`}
                                    isRequired={true}
                                    form={props}
                                />
                            </div>

                            <div className="form__col">
                                <Input
                                    label={{ text: "Phone" }}
                                    name={`phone`}
                                    form={props}
                                    isRequired={true}
                                />
                            </div>
                        </div>
                        <div className="form__group">
                            <Input
                                label={{ text: "Email" }}
                                name={`email`}
                                type={`email`}
                                form={props}
                                isRequired={true}
                            />
                        </div>
                        <div className="form__group">
                            <Textarea
                                label={{
                                    text: `Please describe the fault or your request for serial ${serialNo?.trim() || ""
                                        }:`,
                                }}
                                name={`description`}
                                action={
                                    <div className={"text-link-small"} onClick={prevStep}>
                                        Change serial number
                                    </div>
                                }
                                isRequired={true}
                                form={props}
                                onChange={handleChange}
                                innerRef={this.textareaRef}
                            />
                        </div>
                        <div className="form__group">
                            <div className="form__col form__col--typeOfCall">
                                <Select
                                    label={{ text: "Type of call" }}
                                    name={`typeOfCall`}
                                    form={props}
                                    modifiers={["yellow"]}
                                    options={typesOfCall}
                                    tooltip={
                                        <Fragment>
                                            <p>
                                                Service calls are for repair work on the hardware of a
                                                machine.
                                            </p>
                                            <p>
                                                Analyst calls are for software, network, or cost recovery
                                                solutions.
                                            </p>
                                        </Fragment>
                                    }
                                />
                            </div>

                            <div className="form__col form__col--submit">
                                <Button type="submit" disabled={!isValid} modifier={"solid-inline"}>
                                    Send Request
                                </Button>
                            </div>
                        </div>

                        {status && <div className="form__error">{status}</div>}
                    </div>
                }
          </Form>
    );
  };

  handleSubmit = async (fields, { setStatus, setSubmitting }) => {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;
    const {
      updateCallType,
      updateAddress,
      updateDescription,
      selectEquipment,
    } = this.props;
    const { finishStep } = this.props.injectedProps;
    const { machinesID, serialNo, address } = this.props.selectedEquipment;

    showLoading();
    setStatus();

    const RequestService = {
      SerialNo: serialNo,
      MachinesID: machinesID,
      ContactName: fields.contact,
      ContactPhone: fields.phone,
      ContactEmail: fields.email,
      CallType: fields.typeOfCall,
      CallDescription: fields.description,
    };

    try {
      await equipmentService.requestServiceCall(RequestService);

      // Update address, if the field was changed
      if (address !== fields.address) {
        await equipmentService.changeLocation({
          MachineID: machinesID,
          AddressKey: fields.address,
        });

        updateAddress(machinesID, fields.address);
      }

      hideLoading();
      updateCallType(fields["typeOfCall"]);
      selectEquipment(null);
      updateDescription("");
      finishStep();

      bar.showBar({
        content: `Your ${fields[
          "typeOfCall"
        ].toLowerCase()} request has been received.`,
      });
    } catch (error) {
      console.log(error);
      hideLoading();
      setSubmitting(false);

      bar.showBar(
        {
          content: "There has been a problem with logging a service call",
          modifier: "red",
        },
        4000
      );
    }
  };

  render() {
    const { contact } = authenticationService.currentUserValue;
    const { selectedEquipment, renderStepNav, description } = this.props;
    if (!selectedEquipment) return null;

    const { address } = selectedEquipment;
    console.log("address", address);
    const title = "Log a service call";

    const action = renderStepNav(this.props.injectedProps);

    return (
      <LayoutPage className="service-call" title={title} action={action}>
        <BreadcrumbsItem to="/service-call/select-equipment">
          Log a service call
        </BreadcrumbsItem>

        {this.renderDisplay}
        <Formik
          initialValues={{
            address,
            contact: `${contact.firstName} ${contact.lastName}`,
            phone: contact.phone || "",
            email: contact.email || "",
            description: description || "",
            typeOfCall: typesOfCall[0].value,
          }}
          isInitialValid={isInitialValid}
          validationSchema={Yup.object().shape({
            address: Yup.string().required("Location is required"),
            contact: Yup.string().required("Contact is required"),
            phone: Yup.string().required("Phone is required"),
            description: Yup.string().required("Description is required"),
            email: Yup.string()
              .email("Invalid email")
              .required("Email is required"),
          })}
          onSubmit={this.handleSubmit}
          children={this.renderForm}
        />
      </LayoutPage>
    );
  }
}

export default LogServiceCall;
