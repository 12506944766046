import { createContext } from "react";

export const AppContext = createContext({
    modal: {
        isOpen: false,
        children: null,
        openModal: () => {},
        closeModal: () => {},
    },
    bar: {
        isOpen: false,
        props: {},
        showBar: () => {},
    }
});
