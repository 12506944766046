export default [
  { value: "Administration", label: "Administration" },
  { value: "Advertising", label: "Advertising" },
  { value: "Customer Service", label: "Customer Service" },
  { value: "Design", label: "Design" },
  {
    value: "Executive Management",
    label: "Executive Management",
  },
  { value: "Finance", label: "Finance" },
  { value: "General", label: "General" },
  { value: "Human Resources", label: "Human Resources" },
  {
    value: "Information Services",
    label: "Information Services",
  },
  { value: "Logistics", label: "Logistics" },
  { value: "Marketing", label: "Marketing" },
  { value: "Operational", label: "Operational" },
  { value: "Personnel", label: "Personnel" },
  { value: "Public Relations", label: "Public Relations" },
  { value: "Publishing", label: "Publishing" },
  { value: "Purchasing", label: "Purchasing" },
  { value: "Sales", label: "Sales" },
  { value: "Technical", label: "Technical" },
  { value: "Other", label: "Other" },
];
