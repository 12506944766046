import React, {Component} from 'react';

class Loading extends Component {

    render() {
        const { isOpen } = this.props;
        let ticks = [];
        for (let i = 0; i < 12; i++) {
            ticks.push(<i key={i}/>);                 
        }

        if (isOpen) {
            return (
                <div className={`loading`}>
                    <div className={`loading__icon`}>
                        {ticks.map(t => t)}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

export {Loading};
                