import React, { Component } from "react";

class StrengthMeter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      strength: 0,
      message: "",
    };
  }

  componentDidMount() {
    this.check();
  }

  componentDidUpdate(prevProps) {
    const { password } = this.props;
    if (password !== prevProps.password) {
      this.check();
    }
  }

  check = () => {
    const { password } = this.props;
    let strength = 0;
    let message = "Weak";

    if (password.length >= 6) {
      strength = 1;

      if (/(.*[0-9]){2}/.test(password)) {
        strength = 2;
        message = "Getting there";

        if (password.length >= 9) {
          strength = 3;
          message = "Ka pai - Good";
        }

        if (
          password.length >= 13 &&
          /[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/.test(password)
        ) {
          strength = 4;
          message = "Ka mau te wehi - Fantastic!";
        }
      }
    }

    this.setState({
      strength,
      message,
    });
  };

  render() {
    const { strength, message } = this.state;

    return (
      <div className="strength-meter">
        <div
          className={`strength-meter__bar strength-meter__bar--${strength}`}
        ></div>
        <div className={`strength-meter__message`}>{message}</div>
      </div>
    );
  }
}

export { StrengthMeter };
