import React, { Component } from "react";
import { ErrorMessage } from "formik";
import { Tooltip } from "scripts/components/parts";

/*
Possible props:

- label: {
    pos: 'above', 'inline',
    className: '',
    text: String
}

- tooltip
- isRequired - true / false
- name: String (Formik name id)
*/

class FormField extends Component {
  renderError = () => {
    const { name, form, capsLockOn } = this.props;

    let error = "";

    if (name) {
      error = (
        <ErrorMessage
          name={name}
          component="div"
          className="form-field__error"
        />
      );
    }

    if (form) {
      if (!form.errors[name] && capsLockOn) {
        error = <div className="form-field__error">Caps lock is on</div>;
      }
    }

    return error;
  };

  render() {
    const { label, name, children, tooltip, isRequired } = this.props;

    // Check if there is a label, and whats it's position
    // If no position, assign default
    const labelPos = label ? label.pos || "above" : null;

    return (
      <div className={`form-field form-field--label-${labelPos}`}>
        {label ? (
          <div className="form-field__label">
            <label htmlFor={name}>
              {label.text}
              {isRequired ? (
                <span className={`form-field__asterisk`}></span>
              ) : null}
              {tooltip ? (
                <Tooltip
                  className="form-field__tooltip"
                  size="normal"
                  align="left"
                >
                  {tooltip}
                </Tooltip>
              ) : (
                ""
              )}
            </label>
            {this.renderError()}
          </div>
        ) : (
          ""
        )}

        <div className="form-field__field">{children}</div>
      </div>
    );
  }
}

export { FormField };
