import React, { Component } from "react";
import { LayoutPage } from "scripts/components/layouts";
import { DetailsForm } from "scripts/components/parts";
import { Formik, Form } from "formik";
import { AppContext } from "scripts/context/app-context";
import {
  contactService,
  equipmentService,
  // authenticationService,
} from "scripts/_services";
import { updateEquipmentSerialNo } from "scripts/_helpers";
import { mapContactEquipments } from "scripts/components/form";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

class ViewContact extends Component {
  static contextType = AppContext;

  state = {
    contact: null,
  };

  async componentDidMount() {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;
    const { id } = this.props.match.params;

    showLoading();

    try {
      const { contact, machineContacts } = await contactService.getById(id);
      const { equipments } = await equipmentService.getAll();

      equipments.forEach(updateEquipmentSerialNo);
      this.setState({
        contact,
        equipments: mapContactEquipments(equipments, machineContacts),
      });

      hideLoading();
    } catch (err) {
      bar.showBar({
        content: `Error, no user with this ID.`,
        modifier: "red",
      });
      hideLoading();
    }
  }

  render() {
    const { contact, equipments } = this.state;
    if (!contact) return null;
    const {
      firstName,
      lastName,
      address,
      mobile,
      phone,
      jobTitle,
      dept,
      email,
      department,
    } = contact;

    const title = "View contact";

    return (
      <LayoutPage className="view-contact" title={title}>
        <BreadcrumbsItem to="/accounts/view-contact/">
          View contact
        </BreadcrumbsItem>
        <Formik
          initialValues={{
            firstName,
            lastName,
            address,
            mobile,
            phone,
            jobTitle,
            dept,
            email,
            department,
          }}
          children={(props) => {
            return (
              <Form className="form">
                <DetailsForm
                  form={props}
                  user={contact}
                  disabled={true}
                  equipments={equipments}
                />
              </Form>
            );
          }}
        />
      </LayoutPage>
    );
  }
}

export default ViewContact;
