import { authHeader, handleResponse, config } from "scripts/_helpers";
import { authenticationService } from "scripts/_services";

export const invoiceService = {
  getAllUnpaid,
  getInvoiceData,
};

function getAllUnpaid() {
  const {
    clientNo: ClientNo,
    accountNum: AccountNum,
  } = authenticationService.currentUserValue.client;
  console.log("getAllUnpaid invoiceService", ClientNo, AccountNum);

  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ ClientNo, AccountNum }),
  };

  return fetch(
    `${config.apiUrl}/client/getUnpaidInvoices`,
    requestOptions
  ).then(handleResponse);
}

function getInvoiceData({ id, invoiceNo, invoiceDetail }) {
  const {
    accountNum: AccountNum,
  } = authenticationService.currentUserValue.client;

  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ id, invoiceNo, AccountNum, invoiceDetail }),
  };

  return fetch(`${config.apiUrl}/client/getInvoiceData`, requestOptions).then(
    handleResponse
  );
}
