import React, {Component} from 'react';

class NotificationBar extends Component {

    render() {
        const { props, isOpen } = this.props;
        const { content, modifier } = props;

        if (isOpen) {
            return (
                <div className={`notification-bar notification-bar--${modifier || ''}`}>
                    <div className={`notification-bar__content`}>{content}</div>
                </div>
            );
        } else {
            return null;
        }
    }
}

export {NotificationBar};
                