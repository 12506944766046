// import config from 'config';
import { authHeader, handleResponse, config } from "scripts/_helpers";

export const adminService = {
  searchAccountById,
};

function searchAccountById(AccountNum) {
  console.log("searchAccountById", AccountNum);

  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ AccountNum }),
  };

  return fetch(`${config.apiUrl}/client/getByAccountNum`, requestOptions).then(
    handleResponse
  );
}
