import React, { Component } from "react";
import { LayoutPage } from "scripts/components/layouts";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

class ConsumableReturnsPolicy extends Component {
    
    render() {
        const title = "Consumable Returns Policy";
        return (
            <LayoutPage
                className="conumable-returns-policy"
                title={title}
            >
                <BreadcrumbsItem to="/conumable-returns-policy">
                    Consumable Returns Policy
                </BreadcrumbsItem>

                <div className={`consumable-return-policy-panel`}>
                    <div className={`consumable-return-policy-panel__content`}>
                        <div className={`consumable-return-policy-panel__head`}>
                            {/*<h2>Welcome to MyAccount</h2>*/}
                            <p>
                                If it is necessary to return products to FUJIFILM the following procedures must be adhered to:
                            </p>
                            <br/>
                            <p>
                                In all cases before returning products, please call our contact centre on 0800 493 769 to obtain a Return Authority Number. This number must be quoted on all documentation that accompanies returned goods. Credit returns cannot be accepted without a Return Authority Number.
                            </p>
                            <p>
                                Please enclose a copy of the original dispatch docket with the returned goods. The contact centre must be notified of the intent to return goods within 7 working days of receipt (for faulty goods 30 working days). If the return is because the wrong product was ordered, the freight cost of the return is payable by the purchaser.
                            </p>
                            <br />
                            <br />
                            <p>If returning goods, please send to:</p>
                        </div>
                        <div className={`consumable-return-policy-panel__item`}>
                            <div className={`consumable-return-policy-panel__item-h`}>Auckland Returns</div>
                            <div className={`consumable-return-policy-panel__item-text`}>
                                <p>Mainfreight Auckland<br />
                                    38 Business Parade North<br />
                                    High Brook<br />
                                    Auckland</p>
                            </div>
                        </div>
                        <div className={`consumable-return-policy-panel__item`}>
                            <div className={`consumable-return-policy-panel__item-h`}>Wellington Returns</div>
                            <div className={`consumable-return-policy-panel__item-text`}>
                                <p>Mainfreight Wellington<br />
                                    5-7 Toop Street<br />
                                    Seaview<br />
                                    Wellington</p>
                            </div>
                        </div>
                        <div className={`consumable-return-policy-panel__item`}>
                            <div className={`consumable-return-policy-panel__item-h`}>Christchurch Returns</div>
                            <div className={`consumable-return-policy-panel__item-text`}>
                                <p>Mainfreight Christchurch<br />
                                    8 Islington Avenue<br />
                                    Islington<br />
                                    Christchurch
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </LayoutPage>
        );
    }
}

export default ConsumableReturnsPolicy;