import * as Yup from "yup";

export function isInitialValid(props) {
  if (!props.validationSchema) return true;
  return props.validationSchema.isValidSync(props.initialValues);
}

export function isFieldValid(name, props) {
  const { errors, touched } = props;
  return touched[name] && typeof errors[name] === "undefined";
}

export function isFieldValidClass(name, props) {
  return isFieldValid(name, props) ? "is-valid" : "is-invalid";
}

export const detailsSchema = {
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phone: Yup.string().required("Phone is required"),
  address: Yup.string().required("Phone is required"),
  jobTitle: Yup.string().required("Job title is required"),
  department: Yup.string().required("Department is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
};

export const passwordSchema = (name) => {
  return {
    [name]: Yup.string()
      .min(6, "Minimum 6 characters")
      .max(15, "Maximum 15 characters")
      .matches(/(.*[0-9]){2}/, { message: "At least 2 numerals" })
      .matches(/(.*[a-z]){2}/, { message: "At least 2 letters" }),
  };
};

export function checkCapsLock(e) {
  return e.getModifierState("CapsLock");
}

/*
 * Usually each contact has "machineContacts" array
 * It's a map of equipments that belongs to the contact
 * "Equipments" array with all the data returned separately.
 * This function is to map out "Equipments" with "machineContacts" array
 * To de used in Details Form
 */

export function mapContactEquipments(equipments, machineContacts) {
  const copy = JSON.parse(JSON.stringify(equipments));

  const check = (equipment, machine, type) => {
    const { machinesID, contType } = machine;
    return machinesID === equipment.machinesID && contType === type;
  };

  copy.forEach((e) => {
    e.meters = machineContacts.some((m) => check(e, m, "Meter")) ? "1" : "0";
    e.service = machineContacts.some((m) => check(e, m, "Service")) ? "1" : "0";
    e.consum = machineContacts.some((m) => check(e, m, "Consumables"))
      ? "1"
      : "0";
    e.billing = machineContacts.some((m) => check(e, m, "Billing")) ? "1" : "0";
  });

  return copy;
}

export function getContactFromFields(fields, contNo) {
  const contact = {
    // contNo: "021 171 88",
    // title: "Mr",
    firstName: fields.firstName,
    lastName: fields.lastName,
    email: fields.email,
    // stdCode: "09",
    phone: fields.phone,
    cellPhone: fields.cellPhone,
    department: fields.department,
    jobTitle: fields.jobTitle,
    // location: "Albany",
    address: fields.address,
    // clientNo: "CL123456",
  };

  // If contNo provided - atm doesnt exist for AddContact
  if (contNo) {
    contact.contNo = contNo;
  }

  return contact;
}

export function getMachinesFromFields(fields, contNo) {
  const machines = [];
  /*
   * Map out machine checkboxes
   */
  for (const prop in fields) {
    const arr = prop.split("-");

    /*
     * Check if prop starts with "machine-" and it's checked
     */
    if (arr[0] === "machine" && fields[prop]) {
      const machine = {
        machinesID: arr[1],
        contType: arr[2],
      };

      // If contNo provided - atm doesnt exist for AddContact
      if (contNo) {
        machine.contNo = contNo;
      }
      machines.push(machine);
    }
  }

  return machines;
}
