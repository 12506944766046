import React, { Component } from "react";

class Reveal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    const { isOpen } = this.props;
    if (isOpen) {
      this.setState({ isOpen: true });
    }
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { children } = this.props;
    const { isOpen } = this.state;

    return (
      <div
        className={`reveal ${isOpen ? "is-open" : ""}`}
        onClick={(e) => {
          if (!isOpen) {
            this.toggle();
          }
        }}
      >
        {children}
        <div className={`reveal__icon`} onClick={this.toggle}></div>
      </div>
    );
  }
}

export { Reveal };
