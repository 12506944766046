import { handleResponse, config } from "scripts/_helpers";

export const addressService = {
  getAll,
  forRegistration,
};

function getAll({ ClientNo, accessToken }) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      { Authorization: `Bearer ${accessToken}` }
    ),
    body: JSON.stringify({ ClientNo }),
  };

  console.log("requestAddresses API", requestOptions);

  return fetch(`${config.apiUrl}/address/getAll`, requestOptions).then(
    handleResponse
  );
}

function forRegistration({ AccountNum, SerialNum }) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign({ "Content-Type": "application/json" }),
    body: JSON.stringify({ AccountNum, SerialNum }),
  };

  console.log("forRegistration API", requestOptions);

  return fetch(`${config.apiUrl}/address/forRegistration`, requestOptions).then(
    handleResponse
  );
}
