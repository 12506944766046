import React, {Component} from 'react';
import { Field } from 'formik';

/*
Possible props:

- label: {
    text: String
}

- name: String (Formik name id)
*/

class Checkbox extends Component {
    render() {
        const { label, name, modifiers, ...rest } = this.props;

        return (
            <div className={`checkbox ${modifiers ? modifiers.map(m => `checkbox--${m}`) : ''}`} label={label ? label.text : ''} name={name}>
                
                <Field 
                    id={name}
                    name={name}
                    type={`checkbox`} 
                    className={`checkbox__input`}
                    {...rest}
                />
                <label 
                    className={`checkbox__label`}
                    htmlFor={name}
                >
                    { label ? label.text : '' }
                </label> 
            </div>
        );
    }
}

export { Checkbox };