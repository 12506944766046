import React, { Component } from "react";

class TableRow extends Component {
  render() {
    const { isSelected, ...rest } = this.props;
    return (
      <tr
        {...rest}
        className={`table-row ${isSelected ? `table-row--selected` : ""}`}
      >
        {this.props.children}
      </tr>
    );
  }
}

export { TableRow };
