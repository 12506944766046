import React, { Component } from "react";
import { default as SelectPlugin } from "react-select";
import { authenticationService } from "scripts/_services";

class Address extends Component {
  state = {
    inputValue: "",
    selectedOption: null,
  };

  componentDidMount() {
    const { form, name } = this.props;
    if (form) {
      const value = form.initialValues[name];

      if (value && value !== "") {
        this.setState({ selectedOption: { value, label: value } });
      }
    }
  }

  handleChange = (selectedOption) => {
    const { name, form, onSelect } = this.props;
    this.setState({ selectedOption });

    // If not inside a form, don't
    if (form) {
      // Hook to update form value
      const value = selectedOption ? selectedOption.value : null;
      form.setFieldValue(name, value);
    }

    if (onSelect) {
      onSelect(selectedOption);
    }
  };

  handleInputChange = (newValue) => {
    const inputValue = newValue; //.replace(/\W/g, '');
    this.setState({ inputValue });
    return inputValue;
  };

  promiseOptions = (inputValue) => {
    // new Promise((resolve) => {
    //   if (inputValue === "") {
    //     resolve([]);
    //     return;
    //   }
    //   addressService.getAll().then(
    //     ({ addresses }) => {
    //       console.log("addresses", addresses);
    //       resolve(
    //         addresses.map(({ address }) => {
    //           return { value: address, label: address };
    //         })
    //       );
    //     },
    //     (error) => {
    //       resolve([]);
    //     }
    //   );
    // });
  };

  handleBlur = () => {
    const { form, name, onBlur } = this.props;
    // this is going to call setFieldTouched and manually update touched.topcis
    if (form) form.setFieldTouched(name, true);
    if (onBlur) onBlur();
  };

  render() {
    const { form, onSelect, onBlur, name, modifier, options, small, ...rest } =
      this.props;
    const { selectedOption, inputValue } = this.state;

    let addresses;

    if (!options) {
      addresses =
        authenticationService?.currentUserValue?.client?.addresses.map((a) => {
          return {
            value: a.clientNo,
            label: a.address,
          };
        });
    }

    // console.log(options, addresses);

    return (
      <SelectPlugin
        {...rest}
        className={`address ${modifier ? `address--${modifier}` : ""} ${
          small ? "address--small" : ""
        }`}
        classNamePrefix="react-select"
        cacheOptions
        value={selectedOption}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        options={options || addresses}
        defaultOptions
        placeholder={"Start typing the address..."}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() =>
          inputValue === "" ? null : "No addresses were found."
        }
        onInputChange={this.handleInputChange}
      />
    );
  }
}

export { Address };
