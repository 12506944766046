import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { registrationService } from "scripts/_services";

import { LayoutPanel } from "scripts/components/layouts";
import { Button, Panel } from "scripts/components/parts";
import { isInitialValid, Input } from "scripts/components/form";
import { AppContext } from "scripts/context/app-context";
import { getError } from "scripts/_helpers";

class LoginDetails extends Component {
  static contextType = AppContext;

  state = {
    isTaken: false,
  };

  renderForm = (props) => {
    const { status, isValid } = props;

    return (
      <Form className="form">
        <div className="form__group">
          <Input
            label={{ text: "Username" }}
            name={`userId`}
            autoComplete="off"
            form={props}
            autofocus={true}
          />
        </div>
        <div className="form__group">
          <Input
            label={{ text: "Password" }}
            name={`password`}
            type={`password`}
            autoComplete="off"
            tooltip={true}
            strength={true}
            form={props}
          />
        </div>

        {status && <div className="form__error">{status}</div>}

        <div className="form__submit">
          <Button type="submit" disabled={!isValid}>
            Send Confirmation
          </Button>
        </div>
      </Form>
    );
  };

  handleSubmit = (
    { userId, password },
    { setStatus, setSubmitting, validateForm }
  ) => {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;
    const { data } = this.props;
    const { finishStep } = this.props.injectedProps;
    const newData = JSON.parse(JSON.stringify(data));

    // Add new details to dataObj
    newData.UserName = userId;
    newData.Password = password;

    showLoading();
    setStatus();

    registrationService.register(newData).then(
      (data) => {
        hideLoading();
        // If succedful, redirect to login, show succes message
        // history.push("/login");

        finishStep(newData);
        const content = `Please check your email to activate your account.`;
        const props = { content };

        bar.showBar(props, 8000);
      },
      (error) => {
        console.log("error", error);
        hideLoading();
        setSubmitting(false);

        if (error.taken) {
          this.setState({ isTaken: userId });
          validateForm();
        } else {
          setStatus(getError(error));
        }
      }
    );
  };

  render() {
    const { data } = this.props;

    const title = "Create log in";
    const text = (
      <p>
        You're nearly registered, {data.FirstName.trim()}!<br />
        These details are how you'll log in next time.
      </p>
    );

    return (
      <LayoutPanel className="login-details">
        <Panel title={title} text={text}>
          <Formik
            initialValues={{
              userId: "",
              password: "",
            }}
            isInitialValid={isInitialValid}
            validationSchema={Yup.object().shape({
              userId: Yup.string()
                .required("Username is required")
                .test("Taken", "Username is already taken", (value) => {
                  // console.log('Check taken', this.state.isTaken);
                  return value !== this.state.isTaken;
                }),
              password: Yup.string()
                .required("Password is required")
                .min(6, "Minimum 6 characters")
                .max(15, "Maximum 15 characters")
                .matches(/(.*[0-9]){2}/, { message: "At least 2 numerals" })
                .matches(/(.*[a-z]){2}/, { message: "At least 2 letters" }),
            })}
            onSubmit={this.handleSubmit}
            children={this.renderForm}
          />
        </Panel>
      </LayoutPanel>
    );
  }
}

export default LoginDetails;
