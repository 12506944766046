import React, { Component } from "react";
import { Tooltip, StrengthMeter } from "scripts/components/parts";
import { Field } from "formik";
import { isFieldValidClass } from "scripts/components/form";

class Password extends Component {
  state = {
    showingPassword: false,
    isFocused: false,
  };

  toggleShow = () => {
    const { showingPassword } = this.state;
    const { focusFunc } = this.props;

    this.setState({
      showingPassword: !showingPassword,
    });

    focusFunc(true);
  };

  handleFocus = (e) => {
    this.setState({ isFocused: true });
  };

  handleBlur = (e) => {
    const { handleBlur } = this.props.form;

    handleBlur(e);
    this.setState({ isFocused: false });
  };

  render() {
    const { showingPassword, isFocused } = this.state;
    const { name, form, tooltip, strength, focusFunc, ...rest } = this.props;
    const password = form.values[name];

    return (
      <div className={`password`}>
        <div className="password__show" onClick={this.toggleShow}>
          {showingPassword ? "Hide" : "Show"}
        </div>

        <Field
          name={name}
          type={showingPassword ? "text" : "password"}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          className={`password__input ${
            form ? isFieldValidClass(name, form) : ""
          }`}
          {...rest}
        />

        {tooltip ? (
          <Tooltip
            className="password__tooltip"
            size="small"
            type="field"
            noIcon={true}
            showTooltip={form.errors[name] && isFocused}
          >
            <p>
              6-15 characters long
              <br />
              At least 2 numerals
            </p>
          </Tooltip>
        ) : (
          ""
        )}

        {strength && password.length > 0 ? (
          <div className="password__strength-meter">
            <StrengthMeter password={password} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export { Password };
