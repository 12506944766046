import { BehaviorSubject } from "rxjs";

import { addressService, refreshService } from "scripts/_services";
import { handleResponse, config, authHeader, roles } from "scripts/_helpers";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);

const currentUser = currentUserSubject.asObservable();

const userGroups = [
  { value: "AuthenticatedUsers", display: "Authenticated Users" },
  { value: "MeterReaders", display: "Meter Readers" },
  { value: "LocationAdmins", display: "Location Admins" },
  { value: "OrganisationAdmins", display: "Organisation Admins" },
  { value: "Accounts", display: "Accounts" },
  { value: "ICBSAdministrators", display: "ICBS Administrators" },
  { value: "ReportViewers", display: "Report Viewers" },
  { value: "UserAdministrators", display: "User Administrators" },
];

export const authenticationService = {
  login,
  logout,
  switchTo,
  getByClientNo,
  refresh,
  currentUser,
  userGroups,
  checkAccess,
  updateUser,
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

function checkAccess(permissionType) {
  if (currentUserSubject.value) {
    if (currentUserSubject.value.permissions) {
      return currentUserSubject.value.permissions[permissionType];
    }
  }

  return false;
}

/*
 * Allows to update user object
 * Primarily used in MyDetails, when successfully update details
 */
function updateUser(updateFn) {
  let newUser = JSON.parse(
    JSON.stringify(authenticationService.currentUserValue)
  );

  /*
   * Run update function, that returns new user object
   */
  newUser = updateFn(newUser);

  localStorage.setItem("currentUser", JSON.stringify(newUser));
  currentUserSubject.next(newUser);
}

async function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  try {
    let response = await fetch(
      `${config.apiUrl}/security/authenticate`,
      requestOptions
    ).then(handleResponse);

    const { user, accessToken, contact, refreshToken } = response;
    const client = response.clients[0];
    user.client = client;
    user.contact = contact;
    user.accessToken = accessToken;

    // Role specific permissions
    const rolePermissions = user.roles.reduce((a, b) => {
      console.log(b, roles[b]);
      return Object.assign(a, roles[b]);
    }, {});

    // Merge with default permissions for any user
    user.permissions = Object.assign({}, roles.default, rolePermissions);

    /*
     * Get all addresses for this client on login
     * To be used for address search
     */
    const { addresses } = await addressService.getAll({
      ClientNo: client.clientNo,
      accessToken,
    });

    user.client.addresses = addresses;

    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem("currentUser", JSON.stringify(user));
    currentUserSubject.next(user);

    // store refresh token in session storage
    sessionStorage.setItem("refreshToken", refreshToken);

    return user;
  } catch (err) {
    throw err;
  }
}

function logout() {
  if (!currentUserSubject.value) return;

  const body = {
    UserID: currentUserSubject.value.userID,
    UserName: currentUserSubject.value.userName,
    Token: sessionStorage.getItem("refreshToken"),
  };

  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify(body),
  };

  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);

  // remove refresh token from session storage
  sessionStorage.removeItem("refreshToken");

  /*
   * Stop token refresh cycle, when user logs out
   */
  refreshService.stopRefreshCycle();

  return fetch(`${config.apiUrl}/security/token/revoke`, requestOptions)
    .then(handleResponse)
    .then((success) => {
      console.log("Logout Successful", body);

      return true;
    });
}

function refresh() {
  const body = {
    UserID: currentUserSubject.value.userID,
    UserName: currentUserSubject.value.userName,
    Token: sessionStorage.getItem("refreshToken"),
  };

  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify(body),
  };

  console.log("Request refresh", body);

  return fetch(`${config.apiUrl}/security/token/refresh`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      console.log("Refresh Successful", response);
      const { accessToken, refreshToken } = response;

      const newUser = JSON.parse(
        JSON.stringify(authenticationService.currentUserValue)
      );
      newUser.accessToken = accessToken;

      // switch user from local storage to new one
      localStorage.setItem("currentUser", JSON.stringify(newUser));
      currentUserSubject.next(newUser);

      // store refresh token in session storage
      sessionStorage.setItem("refreshToken", refreshToken);

      return true;
    });
}

function getByClientNo(clientNo) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ clientNo }),
  };
  console.log("getByClientNo", requestOptions);
  return fetch(`${config.apiUrl}/clients/getbyclientno`, requestOptions).then(
    handleResponse
  );
}

async function switchTo({ AccountNum, ClientNo, client }) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({
      UserID: currentUserSubject.value.userID,
      UserName: currentUserSubject.value.userName,
      RefreshToken: sessionStorage.getItem("refreshToken"),
      AccountNum,
      ClientNo,
    }),
  };

  try {
    let response = await fetch(
      `${config.apiUrl}/security/switchTo`,
      requestOptions
    ).then(handleResponse);

    console.log("Switch Successful", response);
    const {
      accessToken,
      // accountNum,
      // clientName,
      clientNo,
      refreshToken,
    } = response;

    console.log("old", authenticationService.currentUserValue.accessToken);
    const newUser = JSON.parse(
      JSON.stringify(authenticationService.currentUserValue)
    );

    console.log("new", accessToken);
    newUser.accessToken = accessToken;
    newUser.client = client;

    /*
     * Get all addresses for this client on login
     * To be used for address search
     */
    const { addresses } = await addressService.getAll({
      ClientNo: clientNo,
      accessToken,
    });

    newUser.client.addresses = addresses;

    // switch user from local storage to new one
    localStorage.setItem("currentUser", JSON.stringify(newUser));
    currentUserSubject.next(newUser);

    // store refresh token in session storage
    sessionStorage.setItem("refreshToken", refreshToken);
  } catch (err) {
    throw err;
  }
}
