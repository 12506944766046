import React, { Component } from "react";
import { Panel} from "scripts/components/parts";
import { LayoutPanel } from "scripts/components/layouts";

class LogOut extends Component {


    render() {
        const title = 'Account disabled';
        const text = (
            <p>
                Your MyAccount log in has been disabled<br/>
                If this is an error<br/>
                Please call 0800 493 769 or<br/>
                email <a href="mailto:shm-fbnz-contact.centre@fujifilm.com">shm-fbnz-contact.centre@fujifilm.com</a>
            </p>
        );

        return (
            <LayoutPanel className="log-out">
                <Panel title={title} text={text}/>
            </LayoutPanel>
        );
    }
}

export default LogOut;
