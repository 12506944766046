import React, { Component } from "react";

class IconTools extends Component {
  render() {
    const { color } = this.props;

    const fill = color || `#fff`;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="44.495"
        height="82.399"
        viewBox="0 0 44.495 82.399"
      >
        <path
          fill={fill}
          d="M34.608 0v9.064h-5.764V0c-5.655 1.317-9.888 6.769-9.888 13.1a13.448 13.448 0 0 0 6.592 11.622v51.5a6.18 6.18 0 0 0 12.36 0v-51.5A13.449 13.449 0 0 0 44.5 13.1C44.495 6.769 40.263 1.317 34.608 0Zm2.049 22.884-1.225.715v52.559a3.815 3.815 0 0 1-3.5 3.763 3.712 3.712 0 0 1-3.913-3.7V23.896l-1.225-1.012a11.553 11.553 0 0 1-5.367-9.786 11 11 0 0 1 4.944-9.237v7.674h10.712v-8.24c2.986 1.913 4.945 5.965 4.945 9.8a11.553 11.553 0 0 1-5.371 9.789Z"
          data-name="Path 1822"
        />
        <path
          fill={fill}
          d="M13.52 43.914a2.9 2.9 0 0 1-.047-1.031l.9-6.636a2.922 2.922 0 0 0-2.927-3.287H8.246V1.235A1.284 1.284 0 0 0 6.955.001h.052a1.233 1.233 0 0 0-1.233 1.236v31.724H3.331a2.922 2.922 0 0 0-2.923 3.295l.913 6.634a2.83 2.83 0 0 1-.046 1.022l-1.2 5.192a2.912 2.912 0 0 0-.074.652v25.367a7.358 7.358 0 0 0 7.431 7.275h.009a7.334 7.334 0 0 0 7.393-7.294v-25.11a2.892 2.892 0 0 0-.073-.644Z"
          data-name="Path 1823"
        />
      </svg>
    );
  }
}

export { IconTools };
