import React, { Component } from "react";

class Tab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    const { isOpen } = this.props;
    if (isOpen) {
      this.setState({ isOpen: true });
    }
  }

  toggle = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { title, children, modifier, content } = this.props;
    const { isOpen } = this.state;

    return (
      <div
        className={`tab ${isOpen ? "is-tab-open" : ""} tab--${
          modifier ? modifier : ""
        }`}
      >
        <div className={`tab__toggle`} onClick={this.toggle}>
          <div className={`tab__title`}>{title}</div>
          <div className={`tab__content`}>{content}</div>
          <div className={`tab__arrow`} />
        </div>
        <div className={`tab__children`}>{children}</div>
      </div>
    );
  }
}

export { Tab };
