import React, { Component } from "react";
import { LayoutPage } from "scripts/components/layouts";
import { Tab, Button } from "scripts/components/parts";
import { AppContext } from "scripts/context/app-context";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { meterService } from "scripts/_services";
import queryString from "query-string";
import { getParam, getError } from "scripts/_helpers";

import {
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "scripts/components/parts/Table";

class Confirm extends Component {
  static contextType = AppContext;

  state = {
    // Internal meters state - validated
    meters: null,
  };

  async componentDidMount() {
    const { bar } = this.context;
    const { showLoading, hideLoading } = this.context.loading;
    const { fromEmail, location } = this.props;
    showLoading();

    try {
      let response;
      /*
        Only submit for validation. Not actually submitting.
      */
      if (fromEmail) {
        const params = queryString.parse(location.search);

        const RunNum = getParam(params, "RunNum");
        const Email = getParam(params, "Email");
        const MetersID = getParam(params, "MetersID") || getParam(params, "id");

        response = await meterService.submitReadingsByEmailToken({
          RunNum: parseInt(RunNum),
          Email: Email,
          MetersID: MetersID,
          Meters: this.props.selected,
          ValidateOnly: true,
          Resend: true,
        });
      } else {
        response = await meterService.submitReadings(this.props.selected, true);
      }

      const { meters } = response;

      console.log("valid", meters);

      this.setState({ meters });
      hideLoading();
    } catch (err) {
      bar.showBar(
        {
          content: getError(err),
          modifier: "red",
        },
        8000
      );
      console.log(err);
      hideLoading();

      this.setState({ meters: [] });
    }
  }

  renderMeters = () => {
    const { meters } = this.state;

    const machines = [];

    meters.forEach((meter) => {
      const machine = machines.find((m) => m.serialNo === meter.serialNo);

      if (machine) {
        machine.meters.push(meter);
      } else {
        machines.push({
          serialNo: meter.serialNo,
          machinesID: meter.machinesID,
          address: meter.address,
          meters: [meter],
        });
      }
    });

    return machines.map(this.renderMachine);
  };

  renderMachine = (machine, index) => {
    const { focusMeter } = this.props;
    const { prevStep } = this.props.injectedProps;
    const { serialNo } = machine;

    return (
      <Tab title={`Serial number: ${serialNo}`} key={index} isOpen={true}>
        <Table layout="fixed">
          <TableBody>
            {machine.meters.map((meter, idx) => (
              <TableRow key={idx}>
                <TableCell>{meter.meterName}</TableCell>
                <TableCell style={{ width: "8em" }}>
                  {meter.newReading}
                </TableCell>
                <TableCell className="hide-md">
                  <span
                    className={
                      meter.importStatus === "Valid" ? "" : "text-error"
                    }
                  >
                    {meter.importError}
                  </span>
                </TableCell>
                <TableCell style={{ width: "5em" }}>
                  <Button
                    onClick={() => {
                      focusMeter(meter.metersID);
                      prevStep();
                    }}
                    modifier={"text"}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Tab>
    );
  };

  render() {
    const { submitReadings } = this.props;
    const { meters } = this.state;
    const { goToStep } = this.props.injectedProps;
    if (!meters) return null;

    const title = "Please confirm";

    const action = (
      <Button
        type="submit"
        onClick={() => {
          submitReadings();
          goToStep(0);
        }}
        modifier={"solid-inline"}
      >
        Confirm readings
      </Button>
    );

    return meters.length === 0 ? (
      <LayoutPage className="meter-readings" title={title}>
        <BreadcrumbsItem to="/meter-readings?step=confirm">
          Please confirm
        </BreadcrumbsItem>
        <div className="notice">
          <div className="notice__text">
            <p>There has been a problem with validating meters.</p>
          </div>
        </div>
      </LayoutPage>
    ) : (
      <LayoutPage className="meter-readings" title={title} action={action}>
        <BreadcrumbsItem to="/meter-readings?step=confirm">
          Please confirm
        </BreadcrumbsItem>
        {this.renderMeters()}
      </LayoutPage>
    );
  }
}

export default Confirm;
