import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "scripts/context/app-context";

class MobileMenu extends Component {
  static contextType = AppContext;

  state = {
    idsOpen: [],
  };

  toggleLevel = (ids, level) => {
    const { idsOpen } = this.state;

    // If already open -> then close
    if (idsOpen[level] === ids[level]) {
      // console.log('same', idsOpen, level, idsOpen.slice(0, level))
      this.setState({ idsOpen: idsOpen.slice(0, level) });
    } else {
      this.setState({ idsOpen: ids });
    }
  };

  renderLevel = (item, index, level, parent_ids) => {
    const { idsOpen } = this.state;
    const { toggle } = this.props;
    const { title, children, link, target, href } = item;
    const ids = parent_ids.concat([index]);
    let Tag = link ? NavLink : "div";
    const props = {};
    const isOpen = idsOpen[level] === index;

    if (link) {
      props.to = link;
      props.onClick = toggle;
      props.activeClassName = "is-current";
    }

    if (href) {
      Tag = "a";
      props.href = href;

      if (target) {
        props.target = target;
      }
    }

    if (children) {
      props.onClick = (e) => {
        e.preventDefault();
        this.toggleLevel(ids, level);
      };
    }

    return (
      <div
        className={`mobile-menu__item mobile-menu__item--${level} ${
          isOpen ? "is-open" : ""
        }`}
        key={index}
      >
        <Tag
          {...props}
          className={`mobile-menu__title mobile-menu__title--${
            children ? "dropdown" : ""
          }`}
        >
          {title}
        </Tag>
        {children ? (
          <div className={`mobile-menu__dropdown`}>
            {children.map((i, index) =>
              this.renderLevel(i, index, level + 1, ids)
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  render() {
    const { menu } = this.props;

    return (
      <div className={`mobile-menu`}>
        {menu.map((item, index) => this.renderLevel(item, index, 0, []))}
      </div>
    );
  }
}

export { MobileMenu };
