import React, { Component } from "react";
import { LayoutPanel } from "scripts/components/layouts";
import queryString from "query-string";
import { AppContext } from "scripts/context/app-context";
import { registrationService } from "scripts/_services";
import { getParam } from "scripts/_helpers";

class ValidateUser extends Component {
  static contextType = AppContext;

  async componentDidMount() {
    const { location, history } = this.props;
    const { bar } = this.context;
    const { showLoading, hideLoading } = this.context.loading;
    showLoading();

    try {
      const params = queryString.parse(location.search);

      const VKey = getParam(params, "VKey") || getParam(params, "a");
      const UserID = getParam(params, "UserID") || getParam(params, "b");

      const { message } = await registrationService.validateUser({
        VKey,
        UserID: parseInt(UserID),
      });

      // console.log(response);
      bar.showBar({ content: message }, 4000);

      // If successful, redirect to login
      history.push("/login");
      hideLoading();
    } catch (err) {
      bar.showBar(
        {
          content: "Couldn't validate.",
          modifier: "red",
        },
        8000
      );
      console.log(err);
      hideLoading();
    }
  }

  render() {
    return <LayoutPanel />;
  }
}

export default ValidateUser;
