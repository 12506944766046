import React, { Component } from "react";
import { FormField } from "scripts/components/form";
import { default as SelectPlugin } from "react-select";

/*
Possible props:

- label: {
    pos: 'above', 'inline',
    className: '',
    text: String
}

- name: String (Formik name id)
- options: Array[{
    title: String, 
    value: String,
    selected: true / false
}, ..] (Array of options)
- pre - pre-text to use in the current option display
- modifiers: Array(String, String...) (Modifiers)
- form: props object (Passed from Formik)
- onSelect - optional - pass function to execute on change / select
- tooltip
- placeholder - custom placrholder when nothing is selected
*/

// const NOOP = () => {};
const NOOP_REACT_SELECT_STYLES = {
  // clearIndicator: NOOP,
  // container: NOOP,
  // control: NOOP,
  // dropdownIndicator: NOOP,
  // group: NOOP,
  // groupHeading: NOOP,
  // indicatorsContainer: NOOP,
  // indicatorSeparator: NOOP,
  // input: NOOP,
  // loadingIndicator: NOOP,
  // loadingMessage: NOOP,
  // menu: NOOP,
  // menuList: NOOP,
  // menuPortal: NOOP,
  // multiValue: NOOP,
  // multiValueLabel: NOOP,
  // multiValueRemove: NOOP,
  // noOptionsMessage: NOOP,
  // option: NOOP,
  // placeholder: NOOP,
  // singleValue: NOOP,
  // valueContainer: NOOP
};

class Select extends Component {
  state = {
    selectedOption: null,
  };

  componentDidMount() {
    const { defaultValue, form, name, options } = this.props;

    if (defaultValue) {
      this.setState({ selectedOption: defaultValue });
    } else {
      if (form) {
        const selectedOption = options.find(
          (o) => o.value === form.initialValues[name]
        );

        if (selectedOption) {
          this.setState({ selectedOption });
        }
      }
    }
  }

  componentDidUpdate() {
    const { selectedOption } = this.state;
    const { options } = this.props;

    // When reloading component
    // If new this.props.options doesnt have selectedOption,
    // Reset selectedOption
    if (
      selectedOption !== null &&
      options.findIndex((o) => o.value === selectedOption.value) === -1
    ) {
      this.handleChange(null);
    }
  }

  handleChange = (selectedOption) => {
    const { name, form, onSelect } = this.props;
    this.setState({ selectedOption });

    // If not inside a form, don't
    if (form) {
      // Hook to update form value
      const value = selectedOption ? selectedOption.value : null;
      form.setFieldValue(name, value);
    }

    if (onSelect) {
      onSelect(selectedOption);
    }
  };

  render() {
    const { selectedOption } = this.state;
    const {
      options,
      form,
      pre,
      modifiers,
      label,
      name,
      tooltip,
      defaultValue,
      fieldWidth,
      isRequired,
      ...rest
    } = this.props;

    let value;
    if (form) {
      value = options.find((o) => o.value === form.values[name]) || null;
    } else {
      value = selectedOption;
    }

    return (
      <FormField
        label={label}
        isRequired={isRequired}
        name={name}
        tooltip={tooltip}
      >
        <div
          style={{ width: fieldWidth }}
          className={`select ${
            modifiers ? modifiers.map((m) => `select--${m}`) : ""
          }`}
        >
          <SelectPlugin
            className="react-select-container"
            classNamePrefix="react-select"
            value={value}
            onChange={this.handleChange}
            options={options}
            isSearchable={false}
            // menuIsOpen={true}
            styles={NOOP_REACT_SELECT_STYLES}
            {...rest}
          />
        </div>
      </FormField>
    );
  }
}

export { Select };
