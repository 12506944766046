// import config from 'config';
import { authHeader, handleResponse, config } from "scripts/_helpers";
import { authenticationService } from "scripts/_services";

export const orderService = {
  getPendingOrders,
  cancelOrder,
  consolidateOrder,
  shipTodayOrder,

  //   getPendingOrdersByEmailToken,
  //   cancelOrderByEmailToken,
  //   consolidateOrderByEmailToken,
  //   shipTodayOrderByEmailToken,
};

function getPendingOrders() {
  const ContNo = authenticationService.currentUserValue.contact.contNo;

  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ ContNo }),
  };

  return fetch(
    `${config.apiUrl}/orders/getOrdersByContact`,
    requestOptions
  ).then(handleResponse);
}

function cancelOrder({ orderId, clientno, lcnClient }) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ orderId, clientno, lcnClient }),
  };

  console.log("cancelOrder API", requestOptions);

  return fetch(`${config.apiUrl}/orders/CancelOrder`, requestOptions).then(
    handleResponse
  );
}

function consolidateOrder({ orderId, clientno, lcnClient, addressKey }) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ orderId, clientno, lcnClient, addressKey }),
  };

  console.log("consolidateOrder API", requestOptions);

  return fetch(`${config.apiUrl}/orders/ConsolidateOrder`, requestOptions).then(
    handleResponse
  );
}

function shipTodayOrder({ orderId, clientno, lcnClient, addressKey }) {
  console.log(orderId, clientno, lcnClient, addressKey);
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ orderId, clientno, lcnClient, addressKey }),
  };

  console.log("consolidateOrder API", requestOptions);

  return fetch(`${config.apiUrl}/orders/ShipOrder`, requestOptions).then(
    handleResponse
  );
}

// function getPendingOrdersByEmailToken(token) {
//   const requestOptions = { method: "GET", headers: authHeader() };
//   return fetch(
//     `${config.apiUrl}/pending-orders-by-email/${token}`,
//     requestOptions
//   ).then(handleResponse);
// }

// function cancelOrderByEmailToken(order_id, token) {
//   const requestOptions = {
//     method: "POST",
//     headers: Object.assign(
//       { "Content-Type": "application/json" },
//       authHeader()
//     ),
//     body: JSON.stringify({ order_id, token }),
//   };

//   console.log("cancelOrder API", requestOptions);

//   return fetch(
//     `${config.apiUrl}/pending-orders/cancel-by-email`,
//     requestOptions
//   ).then(handleResponse);
// }

// function consolidateOrderByEmailToken(order_id, address, token) {
//   const requestOptions = {
//     method: "POST",
//     headers: Object.assign(
//       { "Content-Type": "application/json" },
//       authHeader()
//     ),
//     body: JSON.stringify({ order_id, address, token }),
//   };

//   console.log("consolidateOrder API", requestOptions);

//   return fetch(
//     `${config.apiUrl}/pending-orders/consolidate-by-email`,
//     requestOptions
//   ).then(handleResponse);
// }

// function shipTodayOrderByEmailToken(order_id, address, token) {
//   const requestOptions = {
//     method: "POST",
//     headers: Object.assign(
//       { "Content-Type": "application/json" },
//       authHeader()
//     ),
//     body: JSON.stringify({ order_id, address, token }),
//   };

//   console.log("consolidateOrder API", requestOptions);

//   return fetch(
//     `${config.apiUrl}/pending-orders/ship-today-by-email`,
//     requestOptions
//   ).then(handleResponse);
// }
