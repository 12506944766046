import React, { Component } from "react";
import { Button, Address } from "scripts/components/parts";

class LocationField extends Component {
  state = {
    isEditing: false,
    selectedOption: null,
  };

  select = (selectedOption) => {
    this.setState({ selectedOption });
  };

  edit = (e) => {
    e.stopPropagation();
    this.setState({ isEditing: true });
  };

  unedit = () => {
    this.setState({ isEditing: false });
  };

  save = () => {
    if (this.state.selectedOption === null) return;
    const { onSubmit } = this.props;

    onSubmit(this.state.selectedOption).then(() => {
      this.setState({ isEditing: false });
    });
  };

  render() {
    const { location, small } = this.props;
    const { isEditing } = this.state;
    const { fromEmail } = this.props;

    return (
      <div className={`location-field ${isEditing ? "is-editing" : ""} `}>
        <div className={`location-field__value`}>{location}</div>
        {fromEmail ? null : (
          <div className={`location-field__change`} onClick={this.edit}>
            Change location
          </div>
        )}
        <div
          className={`location-field__field`}
          onClick={(e) => e.stopPropagation()}
        >
          <Address onSelect={this.select} {...{ small }} />
          <Button modifier="solid-small" onClick={this.save}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export { LocationField };
