import { authenticationService } from "scripts/_services";

export function handleResponse(response) {
  return response.text().then((text) => {
    if (response.ok) {
      const data = text && JSON.parse(text);
      return data;
    } else {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.logout();
        // window.location.reload(true);
      }

      // const data = text && JSON.parse(text);

      let error;

      // console.log(JSON.parse(text));

      if (typeof text === "string") {
        error = text && JSON.parse(text);
      } else {
        error = (text && JSON.parse(text)) || response.statusText;
      }

      return Promise.reject(error);
    }
  });
}
