import React, { Component } from "react";
import { Footer, WelcomePanel } from "scripts/components/parts";

class LayoutPanel extends Component {
  render() {
    const { children, className } = this.props;

    return (
      <div className={`layout-panel ${className}`}>
        <div className={`layout-panel__wrap`}>
          <div className={`layout-panel__welcome`}>
            <WelcomePanel />
          </div>
          <div className={`layout-panel__content`}>{children}</div>
        </div>
        <div className={`layout-panel__footer`}>
          <Footer variation="light" />
        </div>

        <div className={`layout-panel__bg`} />
      </div>
    );
  }
}

export { LayoutPanel };
