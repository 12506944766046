// import config from 'config';
import { authHeader, handleResponse, config } from "scripts/_helpers";
import { authenticationService } from "scripts/_services";

export const usageService = {
  getAllBilled,
  getUsagePdf,
};

function getAllBilled() {
  const ClientNo = authenticationService.currentUserValue.client.clientNo;

  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ ClientNo }),
  };

  return fetch(
    `${config.apiUrl}/equipment/getByClientNoForUsage`,
    requestOptions
  ).then(handleResponse);
}

function getUsagePdf(MachinesID, Model, SerialNo, Year) {
  const AccountNum = authenticationService.currentUserValue.client.accountNum;

  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({
      AccountNum,
      MachinesID,
      Model,
      SerialNo,
      Year,
    }),
  };

  return fetch(
    `${config.apiUrl}/equipment/usageByMachineID`,
    requestOptions
  ).then(handleResponse);
}
