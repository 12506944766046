import React, { Component } from "react";
import { Field } from "formik";
import { isFieldValidClass, FormField } from "scripts/components/form";

/*
Possible props:

- label: {
    pos: 'above', 'inline',
    className: '',
    text: String
}

- name: String (Formik name id)
- form: props object (Passed from Formik)
*/

class Textarea extends Component {
  render() {
    const { label, name, form, action, isRequired, ...rest } = this.props;

    return (
      <FormField label={label} isRequired={isRequired} name={name}>
        <div className="textarea">
          {action ? <div className="textarea__action">{action}</div> : ""}

          {form ? (
            <Field
              name={name}
              component="textarea"
              className={`textarea__textarea ${
                form ? isFieldValidClass(name, form) : ""
              }`}
              {...rest}
            />
          ) : (
            <textarea
              name={name}
              className={`textarea__textarea ${
                form ? isFieldValidClass(name, form) : ""
              }`}
              {...rest}
            ></textarea>
          )}
        </div>
      </FormField>
    );
  }
}

export { Textarea };
