import React, { Component } from "react";
import Tablelvl2Context from "./Tablelvl2Context";

const tablelvl2 = {
  variant: "body",
};

class TableBody extends Component {
  render() {
    return (
      <Tablelvl2Context.Provider value={tablelvl2}>
        <tbody className={`table-body`}>{this.props.children}</tbody>
      </Tablelvl2Context.Provider>
    );
  }
}

export { TableBody };
