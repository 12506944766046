import React, { Component } from "react";
import {
  Footer,
  // Breadcrumbs
} from "scripts/components/parts";
import { Breadcrumbs } from "react-breadcrumbs-dynamic";
import { Link } from "react-router-dom";

class LayoutPage extends Component {
  render() {
    const { children, className, title, control, action, message, grey } =
      this.props;

    return (
      <div
        className={`layout-page ${className || ""} ${
          grey && `layout-page--grey`
        }`}
      >
        <div className={`layout-page__wrap`}>
          <div className={`layout-page__body`}>
            {/* <div className={`layout-page__sidebar`}>
              <a
                href="https://www.fujifilm.com/fbnz/en/Insights"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={require("images/sidebanner.png")} alt="" />
              </a>
            </div> */}

            <div className={`layout-page__content`}>
              <div className={`layout-page__breadcrumbs`}>
                {/* <Breadcrumbs/> */}
                <Breadcrumbs
                  container={"div"}
                  containerProps={{ className: "breadcrumbs" }}
                  item={Link}
                  finalItem={"span"}
                />
              </div>

              {title || control ? (
                <div className={`layout-page__content-top`}>
                  <div className={`layout-page__title`}>{title}</div>
                  <div className={`layout-page__control`}>{control}</div>
                </div>
              ) : null}

              <div className={`layout-page__content-bottom`}>
                {message ? (
                  <div className={`layout-page__message`}>{message}</div>
                ) : (
                  ""
                )}
                {children}
                {action ? (
                  <div className={`layout-page__action`}>{action}</div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={`layout-page__footer`}>
          <Footer variation="light" />
        </div>
        <div className={`layout-page__bg`} />
      </div>
    );
  }
}

export { LayoutPage };
