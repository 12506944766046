import React, { Component } from "react";

import CheckAccount from "./CheckAccount";
import Welcome from "./Welcome";
import LoginDetails from "./LoginDetails";
import EmailSent from "./EmailSent";
import { MultiStep } from "scripts/components/parts";

const steps = [
  { param: "", title: "Check account", component: CheckAccount },
  { param: "welcome", title: "Welcome", component: Welcome },
  {
    param: "login-details",
    title: "Create log in",
    component: LoginDetails,
  },
  {
    param: "email-sent",
    title: "Email sent",
    component: EmailSent,
  },
];

class Register extends Component {
  state = {
    data: {},
  };

  saveData = (data) => {
    this.setState({ data });
  };

  render() {
    let { data } = this.state;

    return (
      <MultiStep
        onFinishStep={this.saveData}
        steps={steps}
        data={data}
        {...this.props}
      />
    );
  }
}

export default Register;
