import { authHeader, handleResponse, config } from "scripts/_helpers";
import { authenticationService } from "scripts/_services";

export const userService = {
  updateUser,
};

function updateUser(data) {
  const { userID } = authenticationService.currentUserValue;
  data.userID = userID;

  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify(data),
  };

  console.log("updateUser API", data);

  return fetch(`${config.apiUrl}/users/update`, requestOptions).then(
    handleResponse
  );
}
