import React, { Component, Fragment } from "react";
import { Panel} from "scripts/components/parts";
import { Link } from "react-router-dom";
import { LayoutPanel } from "scripts/components/layouts";
import { authenticationService } from 'scripts/_services';

class ContactUs extends Component {

    componentDidMount() {
        const currentUser = authenticationService.currentUserValue;
        
        if (currentUser) {
            authenticationService.refresh();
        }
    }
    
    back = (e) => {
        e.preventDefault();
        // console.log(this.props.location, this.props.history);
        // const { from } = this.props.location.state || { from: { pathname: "/" } };
        // this.props.history.push(from);
        this.props.history.goBack();
    };

    render() {
        const title = 'Contact us';
        const text = (
            <Fragment>
                <p>
                    Please call 0800 493 769 or{" "}<br/>
                    <a href="mailto:shm-fbnz-contact.centre@fujifilm.com">Email us</a>
                </p>
            </Fragment>
        );

        const veryBottom = (
            <p>
                <Link to="/register" onClick={this.back}>Back</Link>
            </p>
        );

        return (
            <LayoutPanel className="contact-us">
                <Panel title={title} text={text} veryBottom={veryBottom}/>
            </LayoutPanel>
        );
    }
}

export default ContactUs;
