import React, { Component, Fragment } from "react";

import { LayoutPage } from "scripts/components/layouts";
import { Tab, Button, LocationField, Search } from "scripts/components/parts";
import { Select } from "scripts/components/form";
import { isMobile } from "scripts/utils/browser-detect";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "scripts/components/parts/Table";
import { AppContext } from "scripts/context/app-context";
import { equipmentService } from "scripts/_services";

class Edit extends Component {
  static contextType = AppContext;

  state = {
    searchValue: "",
    meterTypeFilter: "",
  };

  constructor(props) {
    super(props);
    this.focusInput = React.createRef();
  }

  focus = () => {
    // If mobile, don't focus
    if (isMobile) return;

    if (this.focusInput.current) {
      setTimeout(() => {
        this.focusInput.current.focus();
      }, 1);
    }
  };

  componentDidMount() {
    // Focus field if this.props.focused exists
    // When coming back from "Confrim" to "Edit"
    this.focus();
  }

  renderMeters = () => {
    const { meters } = this.props;
    const { meterTypeFilter } = this.state;

    if (meterTypeFilter !== "") {
    }
    const machines = [];

    meters
      .filter((meter) => {
        if (meterTypeFilter === "mine") {
          return meter.contact;
        }

        return true;
      })
      .forEach((meter) => {
        const machine = machines.find((m) => m.serialNo === meter.serialNo);

        if (machine) {
          machine.meters.push(meter);
        } else {
          machines.push({
            serialNo: meter.serialNo,
            machinesID: meter.machinesID,
            address: meter.address,
            meters: [meter],
          });
        }
      });

    return machines.map(this.renderMachine);
  };

  handleChange = (e) => {
    // Replace any letters from the input
    let newReading = e.target.value.replace(/\D/, "").trim(); //.replace(/^0+/, "");

    // Remove any emoji char
    newReading = newReading.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    );

    if (newReading !== "") {
      newReading = parseInt(newReading);
    }

    e.currentTarget.value = newReading;
  };

  renderMachine = (machine, index) => {
    const { serialNo, machinesID } = machine;
    const { searchValue } = this.state;
    const { focusedID, justSubmitted, selected, fromEmail, updateLocation } =
      this.props;

    const hasFocusedInput = machine.meters.some(
      ({ metersID }) => metersID === focusedID
    );

    // If serialNo doesnt' have searchValue inside
    if (serialNo.toLowerCase().indexOf(searchValue.toLowerCase()) === -1) {
      return null;
    }

    const submitLocation = async ({ value, label }) => {
      const { showLoading, hideLoading } = this.context.loading;
      const { bar } = this.context;

      showLoading();

      try {
        await equipmentService.changeLocation({
          MachineID: machinesID,
          AddressKey: value,
        });

        updateLocation({ address: label, serialNo });

        bar.showBar({
          content: `Location for ${serialNo} has been changed.`,
        });

        hideLoading();
      } catch (err) {
        hideLoading();
        bar.showBar({ content: `Error: ${err}.`, modifier: "red" });
        console.log(err);
      }
    };

    // Check if some value in the column was just submitted
    const colSubmitted = justSubmitted.some((item) =>
      machine.meters.some((meter) => meter.metersID === item.metersID)
    );

    return (
      <Tab
        title={`Serial number: ${serialNo}`}
        key={index}
        isOpen={hasFocusedInput}
        content={
          <LocationField
            onSubmit={submitLocation}
            location={machine.address}
            fromEmail={fromEmail}
            small
          />
        }
      >
        <Table layout="fixed">
          <TableHead>
            <TableRow>
              <TableCell>Meter</TableCell>
              <TableCell style={{ width: "17%" }} className="hide-md">
                Billing type
              </TableCell>
              <TableCell
                style={{ width: "17%" }}
                className={`hide-md ${colSubmitted ? "is-submitted" : ""}`}
              >
                Last reading
              </TableCell>
              <TableCell
                style={{ width: "17%" }}
                className={`hide-md ${colSubmitted ? "is-submitted" : ""}`}
              >
                Last updated
              </TableCell>
              <TableCell modifier={"reading"}>Reading</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {machine.meters.map((meter, idx) => {
              const { metersID } = meter;
              let defaultValue;

              // Check if value in the cell was just submitted
              const cellSubmitted = justSubmitted.some(
                (item) => item.metersID === meter.metersID
              );

              // Default value = if meters in the selected array
              defaultValue =
                selected?.find((s) => s.metersID === metersID)?.newReading ||
                "";

              // Otherwise, if meter has newReading provided by the API
              if (!defaultValue) {
                defaultValue = meter.newReading > 0 ? meter.newReading : "";
              }

              return (
                <TableRow key={idx}>
                  <TableCell>{meter.meterName}</TableCell>
                  <TableCell className="hide-md">{meter.billingType}</TableCell>
                  <TableCell
                    className={`hide-md ${cellSubmitted ? "is-submitted" : ""}`}
                  >
                    {meter.lastReading}
                  </TableCell>
                  <TableCell
                    className={`hide-md ${cellSubmitted ? "is-submitted" : ""}`}
                  >
                    {meter.lastUpdated}
                  </TableCell>
                  <TableCell type="narrow">
                    <input
                      type="text"
                      // disabled={meter.importStatus === "Imported"}
                      maxLength="10"
                      autoComplete="off"
                      // value={inputValue}
                      defaultValue={defaultValue}
                      name={`meter-${metersID}`}
                      onChange={this.handleChange}
                      ref={focusedID === metersID ? this.focusInput : null}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Tab>
    );
  };

  filterBySearch = (searchValue) => {
    this.setState({ searchValue });
  };

  filterByType = ({ value }) => {
    this.setState({ meterTypeFilter: value });
  };

  sendReadings = () => {
    const { updateSelected } = this.props;
    const { finishStep } = this.props.injectedProps;
    const { bar } = this.context;

    const inputs = Array.from(
      document.querySelectorAll('input[name^="meter-"]')
    );

    const meters = inputs
      .filter((i) => i.value !== "")
      .map((i) => {
        return { metersID: i.name.split("-")[1], newReading: i.value };
      });

    // If no values avaliable
    if (meters.length === 0) {
      bar.showBar({ content: "No meters changed", modifier: "blue" });
      return;
    }

    updateSelected(meters);
    finishStep();
  };

  render() {
    const { isFinished, meters, fromEmail } = this.props;
    if (!meters) return null;

    // const title = `Meter readings for ${company || dateDisplay}`;
    const title = `Meter readings`;

    const action = (
      <Button type="submit" modifier="solid-inline" onClick={this.sendReadings}>
        Send readings
      </Button>
    );
    const control = (
      <Fragment>
        <div className="layout-page__control-select">
          <Search
            pre={"Search"}
            placeholder={`for serial number`}
            onChange={this.filterBySearch}
            fieldWidth={380}
          />
        </div>
        {fromEmail ? null : (
          <div className="layout-page__control-select">
            <Select
              label={{ text: "Showing", pos: "inline" }}
              modifiers={["yellow"]}
              options={[
                { value: "", label: "All serials in account" },
                { value: "mine", label: "Serials linked to me" },
              ]}
              onSelect={this.filterByType}
              placeholder={"All serials in account"}
              fieldWidth={380}
            />
          </div>
        )}
      </Fragment>
    );

    let message = null;

    if (isFinished) {
      message = (
        <Fragment>
          You have already entered meter readings for this month.
          <br />
          If this is incorrect please contact your Administration Officer.
        </Fragment>
      );
    }

    return (
      <LayoutPage
        title={title}
        action={action}
        control={control}
        message={message}
      >
        {this.renderMeters()}
      </LayoutPage>
    );
  }
}

export default Edit;
