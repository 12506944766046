import React, { Component } from "react";

class Footer extends Component {
  render() {
    const { variation } = this.props;

    return (
      <div className={`footer footer--${variation || "light"}`}>
        <div className={`footer__wrap`}>
          <div className={`footer__links`}>
            <a
              href="https://www.fujifilm.com/fbnz/en/Privacy-Policy"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
            <a
              href="https://www.fujifilm.com/fbnz/en/Terms-of-Use-GDPR-NZ"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms of use
            </a>
          </div>
          <div className={`footer__copy`}>
            Copyright FUJIFILM Business Innovation New Zealand Ltd 2006-2021 All
            rights reserved.
          </div>
        </div>
      </div>
    );
  }
}

export { Footer };
