import React, { Component } from "react";

class IconMeter extends Component {
  render() {
    const { color } = this.props;

    const fill = color || `#fff`;

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="94.948"
        height="56.105"
        data-name="Group 175"
        viewBox="0 0 94.948 56.105"
      >
        <path
          fill={fill}
          d="M91.711 0H3.237A3.232 3.232 0 0 0 0 3.237v49.631a3.232 3.232 0 0 0 3.237 3.237h88.474a3.232 3.232 0 0 0 3.237-3.237V3.238A3.232 3.232 0 0 0 91.711 0Zm0 52.868H3.237V3.238h88.474Z"
          data-name="Path 1824"
        />
        <path
          fill={fill}
          d="M17.823 17.263h3.722v19.146h-4.162v-9.837c0-1.59.059-4.15.118-5.147h-.059c-.469.469-1.436 1.289-2.491 2.139l-1.055.85-2.023-2.755Zm18.533 9.661c0 6.077-1.99 9.76-6.83 9.76s-6.911-3.63-6.911-9.546c0-6.7 2.232-9.869 7.045-9.869 4.432.001 6.691 3.228 6.691 9.655Zm-3.765.08c0-4.652-1.1-6.964-3.092-6.964s-3.092 2.286-3.092 6.776c0 4.733 1.1 7.1 3.119 7.1 1.958-.001 3.06-2.368 3.06-6.912Zm18.716-.08c0 6.077-1.99 9.76-6.829 9.76s-6.911-3.63-6.911-9.546c0-6.7 2.232-9.869 7.045-9.869 4.431.001 6.69 3.228 6.69 9.655Zm-3.764.08c0-4.652-1.1-6.964-3.093-6.964s-3.092 2.286-3.092 6.776c0 4.733 1.1 7.1 3.119 7.1 1.958-.001 3.061-2.368 3.061-6.912Zm18.716-.08c0 6.077-1.99 9.76-6.83 9.76s-6.911-3.63-6.911-9.546c0-6.7 2.232-9.869 7.045-9.869 4.432.001 6.691 3.228 6.691 9.655Zm-3.764.08c0-4.652-1.1-6.964-3.093-6.964s-3.092 2.286-3.092 6.776c0 4.733 1.1 7.1 3.119 7.1 1.958-.001 3.061-2.368 3.061-6.912Zm18.717-.08c0 6.077-1.99 9.76-6.83 9.76s-6.911-3.63-6.911-9.546c0-6.7 2.232-9.869 7.045-9.869 4.432.001 6.69 3.228 6.69 9.655Zm-3.764.08c0-4.652-1.1-6.964-3.093-6.964s-3.092 2.286-3.092 6.776c0 4.733 1.1 7.1 3.119 7.1 1.957-.001 3.06-2.368 3.06-6.912Z"
          data-name="Path 1825"
        />
      </svg>
    );
  }
}

export { IconMeter };
