import React, { Component } from "react";
import queryString from "query-string";

class MultiStep extends Component {
  // same as stepId
  // Used to allow url change, before state was updated
  currentId = 0;

  state = {
    stepId: 0, // current last finished step
  };

  componentDidMount() {
    this.checkUrl();
  }

  componentDidUpdate(prevProps) {
    // Listen to url change
    if (this.props.location !== prevProps.location) {
      // console.log("Url changed", this.props.location, prevProps.location);
      this.checkUrl();
    }
  }

  checkUrl() {
    const { steps, location } = this.props;
    const { currentId } = this;
    const { step } = queryString.parse(location.search);
    const index = steps.findIndex((s) => s.param === step);

    // console.log("CheckUrl", currentId);

    // if has step
    if (step) {
      // console.log("Exists: step", step);

      // Does it exists in array
      if (index < 0) {
        // if not, redirect to current
        // console.log("Redirect / replace: step is not part of array", step);
        this.props.history.replace(this.getStepUrl(currentId));
      } else {
        // console.log("Valid step.");

        if (index === currentId) {
          // console.log("Current step. do nothing");
        } else if (index < currentId) {
          // console.log("Previous step. change state");

          // go to that step
          this.moveTo(index);
        } else {
          // console.log("Next step. Update url to current");
          this.props.history.replace(this.getStepUrl(currentId));
        }
      }
    } else {
      // if went to url with no params - i.e. first step
      // switch to that step, if not already
      // console.log("Not defined: step - landed on first step", step);
      this.moveTo(0);
    }
  }

  moveTo = (id) => {
    this.setState({ stepId: id });
    this.currentId = id;
    window.scrollTo(0, 0);
  };

  getStepUrl = (stepId) => {
    const { steps, location } = this.props;
    const search = queryString.parse(location.search);

    if (stepId === 0) {
      // If first step, delete step param from search string
      delete search.step;
    } else {
      // otherwise assign that step
      search.step = steps[stepId].param;
    }

    const stringified = queryString.stringify(search);
    location.search = stringified;
    // console.log(location.search);

    return location;
  };

  finishStep = (data) => {
    const { stepId } = this.state;
    const { onFinishStep } = this.props;
    if (onFinishStep) onFinishStep(data, stepId);
    this.nextStep();
  };

  nextStep = () => {
    const { steps } = this.props;
    const { stepId } = this.state;

    if (stepId < steps.length - 1) {
      this.goToStep(stepId + 1);
    }
  };

  prevStep = () => {
    const { stepId } = this.state;

    if (stepId > 0) {
      this.goToStep(stepId - 1);
    }
  };

  goToStep = (stepId) => {
    this.moveTo(stepId);
    this.props.history.push(this.getStepUrl(stepId));
  };

  render() {
    const { steps, ...rest } = this.props;
    const { stepId } = this.state;
    const { finishStep, prevStep, nextStep, goToStep } = this;

    const Component = steps[stepId].component;

    const injectedProps = { finishStep, prevStep, nextStep, goToStep, stepId };

    return <Component injectedProps={injectedProps} steps={steps} {...rest} />;
  }
}

export { MultiStep };
