import React, { Component } from "react";

class Search extends Component {
  state = {
    value: "",
  };

  handleChange = (e) => {
    const { onChange } = this.props;
    const value = e.target.value.toString();

    this.setState({ value });

    if (onChange) onChange(value);
  };

  search = () => {
    const { onSearch } = this.props;
    const { value } = this.state;

    if (onSearch) onSearch(value);
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.search();
    }
  };

  render() {
    const { value } = this.state;
    const { pre, placeholder, error, errorPosition, fieldWidth } = this.props;

    return (
      <div className={`search ${value !== "" ? "is-search-active" : ""}`}>
        <div className={`search__pre`}>{pre}</div>
        <div className={`search__field`} style={{ width: fieldWidth }}>
          {error ? (
            <div
              className={`search__error search__error--${
                errorPosition || "top"
              }`}
            >
              {error.message}
            </div>
          ) : null}
          <div className={`search__input`}>
            <input
              type="text"
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
              placeholder={placeholder}
            />
            <div className={`search__button`} onClick={this.search}></div>
          </div>
        </div>
      </div>
    );
  }
}

export { Search };
