import { handleResponse, config } from "scripts/_helpers";

export const registrationService = {
  register,
  forgottenPassword,
  checkAccount,
  validateUser,
};

function checkAccount(account, serial) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ AccountNum: account, SerialNo: serial }),
  };

  console.log("checkAccount", requestOptions);

  return fetch(`${config.apiUrl}/security/checkaccount`, requestOptions)
    .then(handleResponse)
    .then((account) => {
      console.log("account", account);
      return account;
    });
}

function register(data) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };

  return fetch(`${config.apiUrl}/users/registerUser`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user;
    });
}

function forgottenPassword(EmailAddress) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ EmailAddress }),
  };

  return fetch(`${config.apiUrl}/users/passwordReset`, requestOptions)
    .then(handleResponse)
    .then((account) => {
      return account;
    });
}

function validateUser({ VKey, UserID }) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ VKey, UserID }),
  };

  console.log("validateUser", requestOptions);

  return fetch(`${config.apiUrl}/users/validateUser`, requestOptions)
    .then(handleResponse)
    .then((account) => {
      console.log("account", account);
      return account;
    });
}
