// import config from 'config';
import { authHeader, handleResponse, config } from "scripts/_helpers";
import { authenticationService } from "scripts/_services";

export const equipmentService = {
  getAll,
  getServiceHistory,
  requestServiceCall,
  changeLocation,
};

function getAll() {
  const ClientNo = authenticationService.currentUserValue.client.clientNo;
  console.log("getAll equipmentService", ClientNo);
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ ClientNo }),
  };

  return fetch(`${config.apiUrl}/equipment/getByClientNo`, requestOptions).then(
    handleResponse
  );
}

function changeLocation({ MachineID, AddressKey }) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ MachineID, AddressKey }),
  };

  console.log("changeLocation API", requestOptions);

  return fetch(
    `${config.apiUrl}/equipment/updateLocation`,
    requestOptions
  ).then(handleResponse);
}

function getServiceHistory() {
  const ClientNo = authenticationService.currentUserValue.client.clientNo;
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ ClientNo }),
  };
  console.log("getServiceHistory API", requestOptions);

  return fetch(
    `${config.apiUrl}/servicehistory/getByClientNo`,
    requestOptions
  ).then(handleResponse);
}

function requestServiceCall(RequestService) {
  const ClientNo = authenticationService.currentUserValue.client.clientNo;
  RequestService.AddressKey = ClientNo;
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify(RequestService),
  };

  console.log("requestServiceCall API", requestOptions);

  return fetch(
    `${config.apiUrl}/servicehistory/requestservice`,
    requestOptions
  ).then(handleResponse);
}
