import React, { Component } from "react";
import { LayoutPage } from "scripts/components/layouts";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Button, Search } from "scripts/components/parts";

import { adminService, authenticationService } from "scripts/_services";
import { AppContext } from "scripts/context/app-context";

class SwitchAccounts extends Component {
  static contextType = AppContext;

  state = {
    client: null,
    error: null,
  };

  search = async (value) => {
    const { showLoading, hideLoading } = this.context.loading;
    showLoading();

    try {
      const client = await adminService.searchAccountById(value);
      console.log(client);
      hideLoading();
      this.setState({ client, error: null });
    } catch (error) {
      hideLoading();
      this.setState({ client: null, error });
    }
  };

  switch = async () => {
    const { history } = this.props;
    const { client } = this.state;
    const { showLoading, hideLoading } = this.context.loading;
    showLoading();

    try {
      await authenticationService.switchTo({
        AccountNum: client.accountNum,
        ClientNo: client.clientNo,
        client,
      });
      hideLoading();
      history.push("/");
    } catch (err) {
      hideLoading();
      console.log(err);
    }
  };

  render() {
    const { client } = this.state;
    const title = "Switch accounts";

    return (
      <LayoutPage title={title}>
        <BreadcrumbsItem to="/switch-accounts">Switch accounts</BreadcrumbsItem>

        <div className="switch-accounts">
          <div className="switch-accounts__intro">
            As an FX Super Admin, you can perform actions inside other accounts.
          </div>
          <div className="switch-accounts__content">
            <Search
              pre={"Search for account number"}
              onSearch={this.search}
              // onChange={this.filter}
            />
          </div>

          {client ? (
            <div className="switch-accounts__selected">
              Selected: {client.name}
            </div>
          ) : null}

          <div className="switch-accounts__cta">
            <Button
              modifier={"solid-medium"}
              disabled={!client}
              onClick={this.switch}
            >
              Switch To Account
            </Button>
          </div>

          {/* {error ? <div className="switch-accounts__error">{error}</div> : null} */}
        </div>
      </LayoutPage>
    );
  }
}

export default SwitchAccounts;
