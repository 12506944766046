import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { authenticationService } from 'scripts/_services';

export const PrivateRoute = ({ component: Component, roles, permissions, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;
        
        // console.log(currentUser);
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }

        // check if route is restricted by role
        // if (roles && roles.findIndex(r => r.role_id === currentUser.role.role_id) === -1) {
        //     // role not authorised so redirect to home page
        //     return <Redirect to={{ pathname: '/'}} />
        // }

        // check if route is restricted by permissions
        if (permissions && permissions.findIndex(p => typeof currentUser.permissions[p] !== 'undefined' && currentUser.permissions[p]) === -1) {
            // role not authorised so redirect to home page
            console.log('no permissions');
            return <Redirect to={{ pathname: '/'}} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)