import React, {Component} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { AppContext } from "scripts/context/app-context";

class CopyClipboard extends Component {
    static contextType = AppContext;

    handleCopy = () => {
        const { bar } = this.context;
        const content = `Text has been copied to your clipboard.`;
        const modifier = `blue`;
        const props = {content, modifier};

        bar.showBar(props);
    };

    render() {
        const { value } = this.props;

        return (
            <CopyToClipboard 
                text={value}
                onCopy={this.handleCopy}
                className='copy-clipboard'
            >
                <div></div>
            </CopyToClipboard>
        );
    }
}

export {CopyClipboard};
                