import React, { Component, Fragment } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "scripts/components/parts/Table";
import { Tab, Address } from "scripts/components/parts";
import { Input, Checkbox, FormField, Select } from "scripts/components/form";

import { AppContext } from "scripts/context/app-context";
import { authenticationService } from "scripts/_services";

import deptOptions from "scripts/_helpers/getDepartments";

class DetailsForm extends Component {
  static contextType = AppContext;

  renderTable = () => {
    if (!this.props.equipments) return null;

    const { disabled, form } = this.props;
    const { equipments } = this.props;

    const renderHeadCheckbox = (heading, key) => {
      const id = `all-${key}`;

      const toggleAll = (e) => {
        const { checked } = e.target;

        equipments.forEach((equipment) => {
          const { machinesID } = equipment;
          const name = `machine-${machinesID}-${key}`;

          form.setFieldValue(name, checked);
        });
      };

      const allChecked = !equipments.some((equipment) => {
        const { machinesID } = equipment;
        const name = `machine-${machinesID}-${key}`;
        return !form.values[name];
      });

      return (
        <Fragment>
          {heading}
          {disabled ? null : (
            <Fragment>
              <input
                id={id}
                type={"checkbox"}
                checked={allChecked}
                onChange={toggleAll}
              />
              <label htmlFor={id}></label>
            </Fragment>
          )}
        </Fragment>
      );
    };

    return (
      <div className="form__group">
        <Tab title="All equipment" modifier="equipment" isOpen={true}>
          <Table layout="fixed">
            <TableHead modifier="dark">
              <TableRow>
                <TableCell modifier={"equip-serial"}>Serial number</TableCell>
                <TableCell className="hide-md" style={{ width: "16em" }}>
                  Model
                </TableCell>
                <TableCell className="hide-md">Location</TableCell>
                <TableCell align={"center"} modifier={"checkbox"}>
                  {renderHeadCheckbox("Meters", "Meter")}
                </TableCell>
                <TableCell align={"center"} modifier={"checkbox"}>
                  {renderHeadCheckbox("Service", "Service")}
                </TableCell>
                <TableCell align={"center"} modifier={"checkbox"}>
                  {renderHeadCheckbox("Consum", "Consumables")}
                </TableCell>
                <TableCell align={"center"} modifier={"checkbox"}>
                  {renderHeadCheckbox("Billing", "Billing")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {equipments.map((equipment, idx) => {
                const { machinesID, model, address, serialNo } = equipment;

                const createCheckbox = (value, key) => {
                  const name = `machine-${machinesID}-${key}`;

                  // Update table default value
                  // Should be moved outside, to actual formik initialisation
                  form.initialValues[name] = value === "1";

                  return (
                    <Checkbox
                      name={name}
                      disabled={disabled}
                      form={form}
                      modifiers={["table"]}
                    />
                  );
                };

                return (
                  <TableRow key={idx}>
                    <TableCell>{serialNo}</TableCell>
                    <TableCell className="hide-md">{model}</TableCell>
                    <TableCell className="hide-md">{address}</TableCell>
                    <TableCell>
                      {createCheckbox(equipment.meters, "Meter")}
                    </TableCell>
                    <TableCell>
                      {createCheckbox(equipment.service, "Service")}
                    </TableCell>
                    <TableCell>
                      {createCheckbox(equipment.consum, "Consumables")}
                    </TableCell>
                    <TableCell>
                      {createCheckbox(equipment.billing, "Billing")}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Tab>
      </div>
    );
  };

  render() {
    const { disabled, form, type, suggestions } = this.props;
    let firstNameDisabled = disabled;
    let lastNameDisabled = disabled;
    const { currentUserValue } = authenticationService;

    if (!currentUserValue.permissions.edit_name) {
      firstNameDisabled = true;
      lastNameDisabled = true;
    }

    if (type === "add") {
      firstNameDisabled = false;
      lastNameDisabled = false;
    }

    return (
      <div className="form__details">
        <div className="form__group">
          <div className="form__col">
            <Input
              label={{ text: "First name", pos: "inline" }}
              name={`firstName`}
              form={form}
              autoComplete="off"
              disabled={firstNameDisabled}
              isRequired={!firstNameDisabled}
              suggestions={suggestions}
              autofocus={true}
            />
          </div>

          <div className="form__col">
            <Input
              label={{ text: "Last name", pos: "inline" }}
              name={`lastName`}
              form={form}
              disabled={lastNameDisabled}
              isRequired={!lastNameDisabled}
            />
          </div>
        </div>

        <div className="form__group">
          <FormField
            label={{ text: "Location", pos: "inline" }}
            name={`address`}
            isRequired={!disabled}
          >
            <Address
              form={form}
              name={`address`}
              isDisabled={disabled}
              modifier="default"
            />
          </FormField>
        </div>

        <div className="form__group">
          <div className="form__col">
            <Input
              label={{ text: "Mobile", pos: "inline" }}
              type="tel"
              name={`cellPhone`}
              form={form}
              disabled={disabled}
              disabledLink={{
                href: `tel:${form.values["cellPhone"]}`,
                target: "_blank",
              }}
            />
          </div>

          <div className="form__col">
            <Input
              label={{ text: "Phone", pos: "inline" }}
              name={`phone`}
              type="tel"
              form={form}
              disabled={disabled}
              isRequired={!disabled}
              disabledLink={{
                href: `tel:${form.values["phone"]}`,
                target: "_blank",
              }}
            />
          </div>
        </div>

        <div className="form__group">
          <div className="form__col">
            <Input
              label={{ text: "Job title", pos: "inline" }}
              name={`jobTitle`}
              form={form}
              disabled={disabled}
              isRequired={!disabled}
            />
          </div>

          <div className="form__col">
            <Select
              label={{ text: "Dept", pos: "inline" }}
              name={`department`}
              form={form}
              isDisabled={disabled}
              isRequired={!disabled}
              placeholder="Department"
              defaultValue={deptOptions.find(
                (o) => o.value === form.initialValues.department
              )}
              options={deptOptions}
            />
          </div>
        </div>

        <div className="form__group">
          <Input
            label={{ text: "Email", pos: "inline" }}
            name={`email`}
            type={`email`}
            form={form}
            disabled={disabled}
            isRequired={!disabled}
          />
        </div>

        {this.renderTable()}
      </div>
    );
  }
}

export { DetailsForm };
