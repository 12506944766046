import React, { Component, Fragment } from "react";

import SelectEquipment from "./SelectEquipment";
import LogServiceCall from "./LogServiceCall";
import Confirmation from "./Confirmation";

import { LayoutPage } from "scripts/components/layouts";
import { MultiStep, StepsNav } from "scripts/components/parts";
import { AppContext } from "scripts/context/app-context";
import { equipmentService, authenticationService } from "scripts/_services";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { getSerialNo } from "scripts/_helpers";

const steps = [
  {
    param: "",
    title: "Select equipment",
    component: SelectEquipment,
  },
  { param: "log-a-call", title: "Log a call", component: LogServiceCall },
  {
    param: "confirmation",
    title: "Receive confirmation",
    component: Confirmation,
  },
];

class ServiceCall extends Component {
  static contextType = AppContext;

  state = {
    equipments: null,
    selectedEquipment: null,
    description: "",
    callType: "",
  };

  async componentDidMount() {
    const { showLoading, hideLoading } = this.context.loading;

    showLoading();

    try {
      const { equipments } = await equipmentService.getAll();
      console.log("equipments", equipments);

      equipments.forEach((item) => {
        item.serialNo = getSerialNo(item.serialNo);
      });

      this.setState({ equipments });
      hideLoading();
    } catch (err) {
      console.log(err);
      hideLoading();
    }
  }

  updateCallType = (callType) => {
    this.setState({ callType });
  };

  updateAddress = (machinesID, addressKey) => {
    const equipmentsCopy = JSON.parse(JSON.stringify(this.state.equipments));
    equipmentsCopy.forEach((e) => {
      if (e.machinesID === machinesID) {
        // Find that address in the array of clients addresses
        // Todo: remove that with better approach
        e.address = authenticationService?.currentUserValue?.client?.addresses.find(
          (a) => a.clientNo === addressKey
        )?.address;
      }
    });

    this.setState({ equipments: equipmentsCopy });
  };

  updateDescription = (description) => {
    this.setState({ description });
  };

  selectEquipment = (machinesID) => {
    // If deliberately unselecting
    if (machinesID === null) {
      this.setState({ selectedEquipment: null, description: "" });
    }

    const { equipments } = this.state;
    const selectedEquipment = equipments.find(
      (e) => e.machinesID === machinesID
    );

    if (typeof selectedEquipment !== "undefined") {
      this.setState({ selectedEquipment, description: "" });
    }
  };

  renderStepNav = ({ stepId, goToStep }) => {
    return (
      <StepsNav
        currentIndex={stepId}
        items={steps.map(({ title }, index) => {
          const item = { title };
          if (index < stepId)
            item.onClick = () => {
              goToStep(index);
            };
          return item;
        })}
      />
    );
  };

  render() {
      const { equipments, selectedEquipment, description, callType } = this.state;

      if (equipments === null || equipments.length <= 0) {
          const title = "Select equipment";
          return (
              <LayoutPage className="service-call" title={title}>
                  <BreadcrumbsItem to="/service-call">
                      Select equipment
                  </BreadcrumbsItem>

                  <div className="confirmation">
                      <div className="confirmation__text">
                          <p>There are no active equipment for service request.</p>
                      </div>
                  </div>
              </LayoutPage>
          );
      }
      else {
          return (
              <Fragment>
                  <BreadcrumbsItem to="/service-call">Select equipment</BreadcrumbsItem>

                  <MultiStep
                      steps={steps}
                      renderStepNav={this.renderStepNav}
                      equipments={equipments}
                      selectEquipment={this.selectEquipment}
                      selectedEquipment={selectedEquipment}
                      description={description}
                      callType={callType}
                      updateCallType={this.updateCallType}
                      updateDescription={this.updateDescription}
                      updateAddress={this.updateAddress}
                      {...this.props}
                  />
              </Fragment>
          );
      }
  }
}

export default ServiceCall;
