import React, { Component, Fragment } from "react";

import { LayoutPage } from "scripts/components/layouts";
import { Search, Tab } from "scripts/components/parts";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "scripts/components/parts/Table";
import { Select } from "scripts/components/form";

import { showPdf, getSerialNo } from "scripts/_helpers";
import { AppContext } from "scripts/context/app-context";
import { usageService } from "scripts/_services";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

class BilledUsage extends Component {
  static contextType = AppContext;

  state = {
    equipments: null,
    years: [],
    yearSelected: null,
    searchValue: "",
  };

  async componentDidMount() {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;
    showLoading();

    try {
      const { equipments } = await usageService.getAllBilled();
      this.processData({ equipments });
      hideLoading();
    } catch (err) {
      bar.showBar(
        {
          content: "There has been a problem with getting billed usage",
          modifier: "red",
        },
        8000
      );
      console.log(err);
      hideLoading();
    }
  }

  processData = ({ equipments }) => {
    const years = [];

    equipments.forEach((item) => {
      item.serialNo = getSerialNo(item.serialNo);
      item.usageYears.forEach((year) => {
        // If this year is not included in the array
        // Add it
        if (years.findIndex((y) => y.value === parseInt(year)) === -1) {
          years.push({ value: parseInt(year), label: year });
        }
      });
    });

    function compare(a, b) {
      return parseInt(a) < parseInt(b) ? -1 : 1;
    }

    years.sort(compare);

    this.setState({ equipments, years, yearSelected: years[0] });
  };

  renderTable = (equipments) => {
    if (!equipments || equipments.length === 0) return null;
    const { yearSelected } = this.state;
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;

    return (
      <Table>
        <TableBody>
          {equipments.map((item, idx) => {
            const { machinesID, serialNo, model } = item;

            // When cliked on view
            const handleView = async () => {
              showLoading();

              // Try retrive that pdf
              try {
                const { machineUsage } = await usageService.getUsagePdf(
                  machinesID,
                  model,
                  serialNo,
                  yearSelected.label
                );

                showPdf(machineUsage);
                console.log("Example PDF", machineUsage);

                hideLoading();
              } catch (err) {
                bar.showBar(
                  {
                    content:
                      typeof err === "string"
                        ? err
                        : "There has been a problem with viewing the pdf",
                    modifier: "red",
                  },
                  8000
                );
                console.log(err);
                hideLoading();
              }
            };

            return (
              <TableRow key={idx}>
                <TableCell align="right">
                  Serial number {serialNo}
                  <button onClick={handleView} className="text-link-main">
                    View
                  </button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  applyYearFilter = (equipments) => {
    const { yearSelected } = this.state;
    if (yearSelected === "") return null;
    return equipments.filter((item) =>
      item.usageYears.some((year) => year === yearSelected.label)
    );
  };

  applySearchFilter = (equipments) => {
    const { searchValue } = this.state;
    if (searchValue === "") return null;
    return equipments.filter(
      (item) =>
        item.serialNo &&
        item.serialNo.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    );
  };

  renderAll = () => {
    const { equipments } = this.state;
    let filtered = JSON.parse(JSON.stringify(equipments));

    filtered = this.applyYearFilter(filtered);
    return this.renderTable(filtered);
  };

  renderResults = () => {
    const { equipments } = this.state;
    let filtered = JSON.parse(JSON.stringify(equipments));

    filtered = this.applyYearFilter(filtered);
    filtered = this.applySearchFilter(filtered);

    if (!filtered || filtered.length === 0) return;

    return (
      <Tab title="Results" modifier="equipment" isOpen={true}>
        {this.renderTable(filtered)}
      </Tab>
    );
  };

  filterByYear = (yearSelected) => {
    this.setState({ yearSelected });
  };

  filterBySearch = (searchValue) => {
    this.setState({ searchValue });
  };

  render() {
    const { equipments, years, yearSelected, searchValue } = this.state;
      if (!equipments) return null;
      
      if (equipments.length > 0) {
          const title = `Billed usage for ${yearSelected.label}`;

          let filtered = this.applySearchFilter(
              JSON.parse(JSON.stringify(equipments))
          );
          const error =
              (!filtered || filtered.length === 0) && searchValue !== ""
                  ? { message: "No results match your search, ngana anō - try again." }
                  : false;

          const control = (
              <Fragment>
                  <div className="layout-page__control-select">
                      <Search
                          pre={"Search"}
                          placeholder={`by serial number`}
                          onChange={this.filterBySearch}
                          error={error}
                          fieldWidth={380}
                      />
                  </div>
                  <div className="layout-page__control-select">
                      <Select
                          label={{ text: "Usage for", pos: "inline" }}
                          modifiers={["yellow"]}
                          pre={"Usage for"}
                          onSelect={this.filterByYear}
                          options={years}
                          defaultValue={yearSelected}
                          fieldWidth={380}
                      />
                  </div>
              </Fragment>
          );

          return (
              <LayoutPage className="billed-usage" title={title} control={control}>
                  <BreadcrumbsItem to="/accounts/billed-usage">
                      Billed usage
                  </BreadcrumbsItem>

                  {this.renderResults()}

                  <Tab title="All equipment" modifier="equipment">
                      {this.renderAll()}
                  </Tab>
              </LayoutPage>
          );
      }
      else {
          const title = "Billed usage";
          return (
              <LayoutPage className="billed-usage" title={title}>
                  <BreadcrumbsItem to="/accounts/billed-usage">
                      Billed usage
                  </BreadcrumbsItem>

                  <div className="confirmation">
                      <div className="confirmation__text">
                          <p>No results match your search, ngana anō - try again.</p>
                      </div>
                  </div>
              </LayoutPage>
          );
      }
  }
}

export default BilledUsage;
