import { authenticationService } from "scripts/_services";

export const refreshService = {
  startRefreshCycle,
  stopRefreshCycle,
};

/*
 * ID for the refresh interval
 * Will be used to cancel the interval when user logs out.
 */
let refreshIntervId;

/*
 * Refresh interval time in minutes
 */
const refreshIntervTime = 9;

/*
 * Start refresh cycle
 * Send an API request every (9 min) to get new access and refresh token
 * if user was idle for (10 min), he will be logged out, and the cycle stops
 */
function startRefreshCycle() {
  const now = new Date().getTime();

  // Check if timer already started before
  const refreshSetupTime = sessionStorage.getItem("refreshSetupTime");

  // if it didn't, set it to now
  if (refreshSetupTime == null) {
    sessionStorage.setItem("refreshSetupTime", now);
  }

  /*
   * Every second, run check function
   * Checks if time reached 9 minutes since start
   */
  clearInterval(refreshIntervId);

  refreshIntervId = setInterval(checkRefresh, 1000);
}

function checkRefresh() {
  const now = new Date().getTime();
  const refreshSetupTime = sessionStorage.getItem("refreshSetupTime");

  // Check if time went past refreshIntervTime
  if (now - refreshSetupTime > refreshIntervTime * 60 * 1000) {
    stopRefreshCycle();

    // Run refresh token
    authenticationService.refresh().then((_) => {
      // If refresh successful, start cycle again
      startRefreshCycle();
    });
  }
}

function stopRefreshCycle() {
  // Stop the interval
  clearInterval(refreshIntervId);

  // Reset timer
  sessionStorage.removeItem("refreshSetupTime");
}
