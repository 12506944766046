import React, { Component, Fragment } from "react";
import { LayoutPage } from "scripts/components/layouts";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "scripts/components/parts/Table";
// import { Link } from "react-router-dom";
import { PanelNotification } from "scripts/components/parts";
import { AppContext } from "scripts/context/app-context";
import moment from "moment";

import { showPdf } from "scripts/_helpers";
import { invoiceService } from "scripts/_services";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

class UnpaidInvoices extends Component {
  static contextType = AppContext;

  state = {
    statementDetail: null,
  };

  async componentDidMount() {
    const { showLoading, hideLoading } = this.context.loading;
    showLoading();

    try {
      const { statementDetail } = await invoiceService.getAllUnpaid();

      console.log(statementDetail);
      this.processData({ statementDetail });
      hideLoading();
    } catch (err) {
      hideLoading();
    }
  }

  processData = ({ statementDetail }) => {
    statementDetail.forEach((i) => {
      i.dateDisplay = moment(i.invoiceDate).format("DD/MM/YY");
      i.dueDisplay = moment(i.dueDate).format("DD/MM/YY");
    });

    this.setState({ statementDetail });
  };

  notAvaliable = (e) => {
    e.preventDefault();
    const { openModal, closeModal } = this.context.modal;
    const text = (
      <Fragment>
        <p>This kind of invoice is not available yet from MyAccount.</p>
        <p>
          Please talk to your FBNZ Admin Officer for more information.
        </p>
      </Fragment>
    );

    const children = <PanelNotification text={text} onClose={closeModal} />;

    openModal(children);
  };

  renderTable = () => {
    const { statementDetail } = this.state;
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;

    return (
      <Table layout="fixed">
        <TableHead>
          <TableRow>
            <TableCell modifier="invoice">Invoice #</TableCell>
            <TableCell className="hide-md" modifier="invoice-num">
              Date
            </TableCell>
            <TableCell modifier="invoice-num">Due</TableCell>
            <TableCell className="hide-md">Details</TableCell>
            <TableCell modifier="invoice-num">Amount</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {statementDetail.map((item, idx) => {
            // const isDisabled = !item.link || item.link === "";
            const { id, invoiceNo, invoiceDetail } = item;

            // When cliked on view
            const handleView = async () => {
              showLoading();

              // Try retrive that pdf
              try {
                const { invoice } = await invoiceService.getInvoiceData({
                  id,
                  invoiceNo,
                  invoiceDetail,
                });

                showPdf(invoice);
                console.log("Example PDF", invoice);

                hideLoading();
              } catch (err) {
                bar.showBar(
                  {
                    content:
                      typeof err === "string"
                        ? err
                        : "There has been a problem with viewing the pdf",
                    modifier: "red",
                  },
                  8000
                );
                console.log(err);
                hideLoading();
              }
            };

              if (item.canDisplay) {
                  return (
                      <TableRow key={idx}>
                          <TableCell modifier="invoice">
                              <button onClick={handleView} className="text-link-main">
                                  {item.invoiceNo}
                              </button>

                              {/* <Link
                    to={item.link}
                    className={`${isDisabled ? "is-disabled" : ""}`}
                    onClick={isDisabled ? this.notAvaliable : null}
                  >
                    {item.invoice}
                  </Link> */}
                          </TableCell>
                          <TableCell className="hide-md" modifier="invoice-num">
                              {item.dateDisplay}
                          </TableCell>
                          <TableCell modifier="invoice-num">{item.dueDisplay}</TableCell>
                          <TableCell className="hide-md">{item.invoiceDetail}</TableCell>
                          <TableCell modifier="invoice-num">
                              {`$${parseFloat(item.invoiceAmount).toFixed(2)}`}
                          </TableCell>
                      </TableRow>
                  );
              }
              else {
                  return (
                      <TableRow key={idx}>
                          <TableCell modifier="invoice">
                              <button onClick={this.notAvaliable} className="text-link-main">
                                  {item.invoiceNo}
                              </button>
                          </TableCell>
                          <TableCell className="hide-md" modifier="invoice-num">
                              {item.dateDisplay}
                          </TableCell>
                          <TableCell modifier="invoice-num">{item.dueDisplay}</TableCell>
                          <TableCell className="hide-md">{item.invoiceDetail}</TableCell>
                          <TableCell modifier="invoice-num">
                              {`$${parseFloat(item.invoiceAmount).toFixed(2)}`}
                          </TableCell>
                      </TableRow>
                  );
              }
          })}
        </TableBody>
      </Table>
    );
  };

  render() {
    const { statementDetail } = this.state;
    if (!statementDetail) return null;

    const title = "Unpaid invoices";
    const total = statementDetail.reduce(
      (a, b) => a + parseFloat(b.invoiceAmount),
      0
    );

    return (
      <LayoutPage className="unpaid-invoices" title={title}>
        <BreadcrumbsItem to="/accounts/unpaid-invoices">
          Unpaid invoices
        </BreadcrumbsItem>
        {statementDetail.length === 0 ? (
          <div className="notice">
            <div className="notice__text">
              <p>You have no unpaid invoices</p>
            </div>
          </div>
        ) : (
          <Fragment>{this.renderTable()}</Fragment>
        )}

        <div className="unpaid-invoices__total">
          Total due: ${total.toFixed(2)}
        </div>
      </LayoutPage>
    );
  }
}

export default UnpaidInvoices;
