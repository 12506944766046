import React from "react";
import Tablelvl2Context from "./Tablelvl2Context";

function TableCell(props) {
  const {
    children,
    className,
    modifier,
    sort,
    align,
    valign,
    type,
    ...other
  } = props;
  const tablelvl2 = React.useContext(Tablelvl2Context);
  const isHeadCell = tablelvl2 && tablelvl2.variant === "head";

  const Component = isHeadCell ? "th" : "td";
  const classes = [];

  if (className) classes.push(className);
  if (modifier) classes.push(`table-cell--${modifier}`);
  if (type) classes.push(`table-cell--type-${type}`);
  if (tablelvl2.modifier) classes.push(`table-cell--${tablelvl2.modifier}`);
  if (align) classes.push(`table-cell--align-${align}`);
  if (valign) classes.push(`table-cell--valign-${valign}`);

  // console.log(other);
  return (
    <Component className={`table-cell ${classes.join(" ")}`} {...other}>
      <div className={`table-cell__base`}>
        {children}

        {sort ? (
          <div className="table-cell__sort-icon" onClick={sort}></div>
        ) : null}
      </div>
    </Component>
  );
}

export { TableCell };
