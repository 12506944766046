import React, { Component } from "react";

import { LayoutPanel } from "scripts/components/layouts";
import { AppContext } from "scripts/context/app-context";
import { Button, Panel } from "scripts/components/parts";

class EmailSent extends Component {
  static contextType = AppContext;

  render() {
    const { data } = this.props;

    const title = "Email sent";
    const text = (
      <p>
        Please check your email (<b>{data.Email}</b>) to activate your account.
      </p>
    );

    return (
      <LayoutPanel className="login-details">
        <Panel title={title} text={text}>
          <Button to="/login">Back to Login</Button>
        </Panel>
      </LayoutPanel>
    );
  }
}

export default EmailSent;
