import React, { Component, Fragment } from "react";

import Edit from "./Edit";
import Confirm from "./Confirm";
import { meterService } from "scripts/_services";
import { getParam } from "scripts/_helpers";
import { AppContext } from "scripts/context/app-context";
import { MultiStep } from "scripts/components/parts";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { LayoutPage } from "scripts/components/layouts";

import queryString from "query-string";

const steps = [
  { param: "", component: Edit },
  { param: "confirm", component: Confirm },
];

class MeterReadings extends Component {
  static contextType = AppContext;

  state = {
    focusedID: null,
    justSubmitted: [], // An array of just submitted meters, to display in green for 3 seconds

    meters: null,

    /*
      Array of selected / edited meters
      [
        [ 234, 346346 ] - first value - meterID, second newReading
      ]
    */
    selected: [],
  };

  async componentDidMount() {
    this.reuqestMeters();
  }

  async reuqestMeters() {
    const { fromEmail, location } = this.props;
    const { bar } = this.context;
    const { showLoading, hideLoading } = this.context.loading;
    showLoading();

    try {
      let response;
      let meters;

      if (fromEmail) {
        const params = queryString.parse(location.search);
        const RunNum = getParam(params, "RunNum");
        const Email = getParam(params, "Email");
        const MetersID = getParam(params, "MetersID") || getParam(params, "id");
        const Resend = getParam(params, "Resend");

        response = await meterService.getAllByEmailToken(
          parseInt(RunNum),
          Email,
          MetersID,
          Resend === "true"
        );

        // Added filter, to filter out all imported readings
        meters = response.meters.filter((m) => m.importStatus !== "Imported");
      } else {
        response = await meterService.getAll();

        meters = response.meters;
        const { meters: contactMeters } = await meterService.getAllByContact();

        console.log(meters, contactMeters);

        contactMeters.forEach((contactMeter) => {
          const index = meters.findIndex(
            (meter) => meter.metersID === contactMeter.metersID
          );
          if (index >= 0) {
            meters[index].contact = true;
          }
        });
      }

      this.setState({ meters });
      hideLoading();
    } catch (err) {
      bar.showBar(
        {
          content: "There has been a problem with getting meters",
          modifier: "red",
        },
        8000
      );
      console.log(err);
      hideLoading();
    }
  }

  /*
   * Updates "selected" array of meters with inputs, that have value
   */
  updateSelected = (meters) => {
    const selected = [];
    const metersCopy = JSON.parse(JSON.stringify(this.state.meters));

    meters.forEach(({ metersID, newReading }) => {
      const index = selected.findIndex((item) => item.metersID === metersID);

      if (index >= 0) {
        selected[index].newReading = parseInt(newReading);
      } else {
        const newSelected = metersCopy.find((m) => m.metersID === metersID);
        newSelected.newReading = parseInt(newReading);
        selected.push(newSelected);
      }
    });

    console.log("updateSelected", selected);

    this.setState({ selected });
  };

  /*
   * Updates address for meters with "serialNo"
   */
  updateLocation = ({ address, serialNo }) => {
    const metersCopy = JSON.parse(JSON.stringify(this.state.meters));

    metersCopy
      .filter((m) => m.serialNo === serialNo)
      .forEach((m) => {
        m.address = address;
      });

    console.log("updateLocation", metersCopy);

    this.setState({ meters: metersCopy });
  };

  focusMeter = (metersID) => {
    if (metersID) {
      this.setState({ focusedID: metersID });
    } else {
      this.setState({ focusedID: null });
    }
  };

  /*
   * Sends readings to the API
   */
  submitReadings = async () => {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;
    const { fromEmail, location } = this.props;
    const { selected } = this.state;
    // let metersCopy = JSON.parse(JSON.stringify(meters));
    const justSubmitted = [];
    showLoading();

    try {
      let response;

      if (fromEmail) {
        const params = queryString.parse(location.search);
        const RunNum = getParam(params, "RunNum");
        const Email = getParam(params, "Email");
        const MetersID = getParam(params, "MetersID") || getParam(params, "id");
        const Resend = getParam(params, "Resend");

        response = await meterService.submitReadingsByEmailToken({
          RunNum: parseInt(RunNum),
          Email: Email,
          MetersID: MetersID,
          Meters: selected,
          ValidateOnly: false,
          Resend: Resend === "true",
        });
      } else {
        response = await meterService.submitReadings(selected);
      }

      hideLoading();

      /*
       * Loop through response meters
       */
      response.meters.forEach((meter) => {
        /*
         * If meter was successfully imported
         * update justSubmitted array
         */
        if (meter.importStatus === "Imported") {
          justSubmitted.push(meter);
        }
      });

      // Reset all selected inputs
      selected.forEach((s) => {
        document.querySelector(`input[name='meter-${s.metersID}']`).value = "";
      });

      // Remove just submitted from the array in 10 seconds
      setTimeout(() => {
        this.setState({ justSubmitted: [] });
      }, 10000);

      // request updated Meters
      await this.reuqestMeters();

      // Update state
      this.setState({ justSubmitted, selected: [] });

      // Show bar with the message
      const { importError } = response;

      bar.showBar({ content: importError });
      // if (importStatus === "Error") {
      //   bar.showBar({ content: importError, modifier: "red" });
      // } else {
      //   bar.showBar({ content: importError });
      // }
    } catch (error) {
      hideLoading();
      bar.showBar({ content: `Error: ${error}.`, modifier: "red" });
    }
  };

  render() {
    const {
      meters,
      selected,
      focusedID,
      dateDisplay,
      justSubmitted,
    } = this.state;

    // console.log(meters);
    if (meters === null) return null;

    if (meters.length > 0) {
      const isFinished = !meters.some(
        (meter) => meter.importStatus !== "Imported"
      );

      return (
        <Fragment>
          {!this.props.fromEmail ? (
            <BreadcrumbsItem to="/meter-readings">
              Meter readings
            </BreadcrumbsItem>
          ) : null}
          <MultiStep
            steps={steps}
            focusMeter={this.focusMeter}
            updateSelected={this.updateSelected}
            submitReadings={this.submitReadings}
            updateLocation={this.updateLocation}
            isFinished={isFinished}
            focusedID={focusedID}
            meters={meters}
            selected={selected}
            dateDisplay={dateDisplay}
            justSubmitted={justSubmitted}
            {...this.props}
          />
        </Fragment>
      );
    } else {
      return (
        <LayoutPage title={"Meter readings"}>
          <BreadcrumbsItem to="/meter-readings">Meter readings</BreadcrumbsItem>

          <div className="confirmation">
            <div className="confirmation__h">Thank you!</div>
            <div className="confirmation__text">
              <p>
                Thanks for your help - you've supplied us with all the readings.
              </p>
              <p>There are no readings left to submit for this period.</p>
            </div>
          </div>
        </LayoutPage>
      );
    }
  }
}

export default MeterReadings;
