import React, { Component, Fragment } from "react";
import { LayoutPage } from "scripts/components/layouts";
import {
  Button,
  Tooltip,
  Panel,
  Tab,
  Address,
  ButtonGroup,
} from "scripts/components/parts";

import { Formik, Form } from "formik";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "scripts/components/parts/Table";
import { AppContext } from "scripts/context/app-context";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { isInitialValid, FormField } from "scripts/components/form";

import * as Yup from "yup";

import {
  orderService,
  authenticationService,
  // equipmentService,
} from "scripts/_services";
import { getError, getSerialNo } from "scripts/_helpers";
import moment from "moment";

class PendingOrder extends Component {
  static contextType = AppContext;

  state = {
    orders: null,
    selectedOrder: null,
  };

  componentDidMount() {
    this.reuqestOrders();
  }

  async reuqestOrders() {
    const { showLoading, hideLoading } = this.context.loading;
    showLoading();

    try {
      const { orders } = await orderService.getPendingOrders();
      // const { equipments } = await equipmentService.getAll();

      // To delete
      // orders[0].ordStatusId = 5;
      // orders[1].ordStatusId = 5;
      // orders[2].ordStatusId = 5;
      // orders[5].ordStatusId = 5;

      this.processData({ orders });
      hideLoading();
    } catch (err) {
      console.log(err);
      hideLoading();

      // Set order to empty array, so display empty page
      // If user doesnt have access to the orders for example
      this.setState({ orders: [] });
    }
  }

  processData = ({ orders }) => {
    /*
     * Sort orders by date
     */
    orders
      .sort(
        (a, b) =>
          moment(a.shipDate).format("YYYYMMDD") -
          moment(b.shipDate).format("YYYYMMDD")
      )
      .forEach((item) => {
        const { shipDate } = item;
        item.serialNo = getSerialNo(item.serialNo);
        // item.address = equipments.find(
        //   (e) => e.serialNo?.trim() === serialNo?.trim()
        // )?.address;

        const date = moment(shipDate);
        item.shipDate_display = date.isValid() ? date.format("DD/MM/YYYY") : "";
      });

    this.setState({ orders });
  };

  cancelModal = () => {
    const { orderId, qty, itemDesc, lcnClient, clientno } =
      this.state.selectedOrder;

    const { bar } = this.context;
    const { showLoading, hideLoading } = this.context.loading;
    const { openModal, closeModal } = this.context.modal;

    const title = "Cancel<br/>shipment";

    const text = (
      <Fragment>
        <p>
          Shipment of{" "}
          <b>
            {qty} {itemDesc}
          </b>{" "}
          will&nbsp;be&nbsp;cancelled.
        </p>
        <p>This action cannot be undone from&nbsp;inside MyAccount.</p>
      </Fragment>
    );

    const cancelShipment = async () => {
      showLoading();

      try {
        await orderService.cancelOrder({ orderId, clientno, lcnClient });

        const orders = JSON.parse(JSON.stringify(this.state.orders)).filter(
          (o) => o.orderId !== orderId
        );

        this.processData({ orders });
        hideLoading();
        closeModal();
        bar.showBar({ content: `This consumable has been cancelled.` });
        window.scrollTo(0, 0);
      } catch (err) {
        hideLoading();
      }
    };

    const children = (
      <Panel
        title={title}
        text={text}
        onClose={closeModal}
        className={`panel--action`}
      >
        <ButtonGroup>
          <Button modifier="cancel" onClick={closeModal}>
            Back
          </Button>
          <Button modifier="action" onClick={cancelShipment}>
            Confirm
          </Button>
        </ButtonGroup>
      </Panel>
    );

    openModal(children);
  };

  consolidateModal = () => {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;
    const { openModal, closeModal } = this.context.modal;
    const { orderId, lcnClient, clientno, qty, itemDesc } =
      this.state.selectedOrder;
    const { addresses } = authenticationService.currentUserValue.client;
    const initialAddress = addresses.find(
      (a) => a.clientNo === lcnClient
    )?.address;

    const consolidateShipment = async (
      { address },
      { setStatus, setSubmitting }
    ) => {
      showLoading();
      setStatus();

      const data = {
        orderId,
        clientno,
        lcnClient,
      };

      // Only pass AddressKey if address changed from initial value
      if (address !== initialAddress) {
        data.addressKey = address;
      }

      try {
        await orderService.consolidateOrder(data);

        // Update submitted order to "Consolidated"
        // const orders = JSON.parse(JSON.stringify(this.state.orders));
        // orders.forEach((o) => {
        //   if (o.orderId === orderId) {
        //     o.ordStatusId = 4;
        //   }
        // });

        // request updated orders
        await this.reuqestOrders();

        closeModal();
        hideLoading();
        bar.showBar({
          content: `This consumable will be shipped with your next scheduled order.`,
        });
        window.scrollTo(0, 0);

        // this.setState({ orders, selectedOrder: null });
        this.setState({ selectedOrder: null });
      } catch (err) {
        hideLoading();
        setSubmitting(false);
        setStatus(getError(err));
        bar.showBar({ content: `Error: ${getError(err)}.`, modifier: "red" });
      }
    };

    const title = "Consolidate";
    const text = (
      <Fragment>
        <p>
          <b>
            {qty} {itemDesc}
          </b>{" "}
          will be shipped with your next scheduled order.
        </p>
      </Fragment>
    );

    const children = (
      <Panel
        title={title}
        text={text}
        onClose={closeModal}
        className={`panel--action`}
      >
        <Formik
          initialValues={{
            address: initialAddress,
          }}
          isInitialValid={isInitialValid}
          validationSchema={Yup.object().shape({
            address: Yup.string().required("Address is required"),
          })}
          onSubmit={consolidateShipment}
        >
          {(props) => (
            <Form className="form">
              <div className="form__group">
                <FormField
                  label={{ text: "Shipping address" }}
                  name={`address`}
                  isRequired={true}
                >
                  <Address form={props} name={`address`} modifier="default" />
                </FormField>
              </div>

              {props.status && (
                <div className="form__error">{props.status}</div>
              )}

              <div className="form__submit">
                <Button type="submit" disabled={!props.isValid}>
                  Confirm
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Panel>
    );

    openModal(children);
  };

  shipModal = () => {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;
    const { orderId, lcnClient, clientno, qty, itemDesc } =
      this.state.selectedOrder;
    const { openModal, closeModal } = this.context.modal;
    const { addresses } = authenticationService.currentUserValue.client;
    const initialAddress = addresses.find(
      (a) => a.clientNo === lcnClient
    )?.address;

    const handleSubmit = async (fields, { setStatus, setSubmitting }) => {
      showLoading();
      setStatus();

      const { address } = fields;

      const data = {
        orderId,
        clientno,
        lcnClient,
      };

      // Only pass AddressKey if address changed from initial value
      if (address !== initialAddress) {
        data.addressKey = address;
      }

      try {
        await orderService.shipTodayOrder(data);

        // Update submitted order to "Ship today"
        // const orders = JSON.parse(JSON.stringify(this.state.orders));
        // orders.forEach((o) => {
        //   if (o.orderId === orderId) {
        //     o.ordStatusId = 3;
        //   }
        // });

        await this.reuqestOrders();

        closeModal();
        hideLoading();

        bar.showBar({
          content: `This consumable will be on the next shipment.`,
        });

        window.scrollTo(0, 0);

        // this.setState({ orders, selectedOrder: null });
        this.setState({ selectedOrder: null });
      } catch (err) {
        hideLoading();
        setSubmitting(false);
        setStatus(getError(err));

        bar.showBar({ content: `Error: ${getError(err)}.`, modifier: "red" });
      }
    };

    const title = "Ship today";
    const text = (
      <Fragment>
        <p>
          <b>
            {qty} {itemDesc}
          </b>{" "}
          will be sent to you in the next possible shipment.
        </p>
      </Fragment>
    );

    const children = (
      <Panel
        title={title}
        text={text}
        onClose={closeModal}
        className={`panel--action`}
      >
        <Formik
          initialValues={{
            address: initialAddress,
          }}
          isInitialValid={isInitialValid}
          validationSchema={Yup.object().shape({
            address: Yup.string().required("Address is required"),
          })}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form className="form">
              <div className="form__group">
                <FormField
                  label={{ text: "Shipping address" }}
                  name={`address`}
                  isRequired={true}
                >
                  <Address form={props} name={`address`} modifier="default" />
                </FormField>
              </div>

              {props.status && (
                <div className="form__error">{props.status}</div>
              )}

              <div className="form__submit">
                <Button type="submit" disabled={!props.isValid}>
                  Confirm
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Panel>
    );

    openModal(children);
  };

  selectOrder = (e) => {
    const orderId = parseInt(e.target.value);
    const order = this.getOrderById(orderId);

    if (order) {
      this.setState({ selectedOrder: order });
    }
  };

  getOrderById = (orderId) => {
    const { orders } = this.state;
    return orders.find((o) => o.orderId === orderId);
  };

  renderTabs = (shipment_status) => {
    let shipments = [];

    /*
     * Filter out "Ship today" orders
     */
    if (shipment_status === "today") {
      shipments.push({
        title: `Shipping today`,
        orders: this.state.orders.filter(
          ({ ordStatusId }) => ordStatusId === 3
        ),
      });
    }

    /*
     * Filter out "Consolidated" orders
     */
    if (shipment_status === "consolidated") {
      const orders = this.state.orders.filter(
        ({ ordStatusId }) => ordStatusId === 4
      );

      // const date = moment.min(orders.map((o) => moment(o.shipDate)));

      orders.forEach((order) => {
        const shipment = shipments.find(
          (s) => s.shipDate_display === order.shipDate_display
        );

        if (shipment) {
          shipment.orders.push(order);
        } else {
          shipments.push({
            title: `Shipment scheduled ${order.shipDate_display}`,
            shipDate_display: order.shipDate_display,
            orders: [order],
          });
        }
      });

      // shipments.push({
      //   title: `Consolidated shipment ${
      //     date.isValid() ? date.format("DD/MM/YYYY") : ""
      //   }`,
      //   orders,
      // });
    }

    /*
     * Filter out "Future" orders
     */
    if (shipment_status === "future") {
      const orders = this.state.orders.filter(
        ({ ordStatusId }) => ![3, 4].some((i) => i === ordStatusId)
      );

      orders.forEach((order) => {
        const shipment = shipments.find(
          (s) => s.shipDate_display === order.shipDate_display
        );

        if (shipment) {
          shipment.orders.push(order);
        } else {
          shipments.push({
            title: `Shipment scheduled ${order.shipDate_display}`,
            shipDate_display: order.shipDate_display,
            orders: [order],
          });
        }
      });
    }

    return (
      <div className="pending-order__group">
        {shipments.map(this.renderShipment)}
      </div>
    );
  };

  renderShipment = (shipment, index) => {
    const { orders, title } = shipment;

    if (orders.length > 0) {
      return (
        <Tab title={title} key={index} isOpen={index === 0} modifier="pending">
          {this.renderOrders(orders, shipment)}
        </Tab>
      );
    } else {
      return null;
    }
  };

  renderOrders = (orders) => {
    const { selectedOrder } = this.state;

    return (
      <Table>
        <TableHead modifier="dark">
          <TableRow>
            <TableCell style={{ width: "5.9em" }} className="hide-md">
              Quantity
            </TableCell>
            <TableCell>Order of</TableCell>
            <TableCell modifier="pending-serial">For serial number</TableCell>
            <TableCell modifier="pending-select">Select</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order, idx) => {
            const { orderId } = order;
            const isSelected = selectedOrder
              ? selectedOrder.orderId === orderId
              : false;
              if (order.ordStatusId === 3) {
                  return (
                      <TableRow key={idx} isSelected={isSelected}>
                          <TableCell className="hide-md">{order.qty}</TableCell>
                          <TableCell>{order.itemDesc}</TableCell>
                          <TableCell>{order.serialNo}</TableCell>
                          <TableCell></TableCell>
                      </TableRow>
                  );
              }
              else {
                  return (
                      <TableRow key={idx} isSelected={isSelected}>
                          <TableCell className="hide-md">{order.qty}</TableCell>
                          <TableCell>{order.itemDesc}</TableCell>
                          <TableCell>{order.serialNo}</TableCell>
                          <TableCell modifier={"radio"} align={"center"}>
                              <input
                                  type="radio"
                                  id={`log-${orderId}`}
                                  value={orderId}
                                  checked={isSelected}
                                  onChange={this.selectOrder}
                              />
                              <label htmlFor={`log-${orderId}`}></label>
                          </TableCell>

                      </TableRow>
                  );
              }
          })}
        </TableBody>
      </Table>
    );
  };

  render() {
    const { orders, selectedOrder } = this.state;
    if (!orders) return null;
    const title = "Pending order management";
    const noActions = orders.length === 0;

    const tooltip = (
      <Tooltip className="tooltip--white">
        <p>
          Any consumables can be consolidated into one order, which will be
          shipped together to you within the next 4&nbsp;days
        </p>
      </Tooltip>
    );

    let disableToday = true;
    let disableConsolidate = true;
    let disableCancel = true;

    if (selectedOrder) {
      disableToday = selectedOrder.ordStatusId === 3;
      disableConsolidate = disableToday; //|| selectedOrder.ordStatusId === 4;
      disableCancel = disableToday;
    }

    const action = (
      <div className="pending-order__action">
        <Button
          modifier={"solid-medium"}
          disabled={disableToday}
          onClick={this.shipModal}
        >
          Ship Today
        </Button>
        <Button
          modifier={"solid-medium"}
          disabled={disableConsolidate}
          onClick={this.consolidateModal}
          tooltip={tooltip}
        >
          Consolidate
        </Button>
        <Button
          modifier={"solid-medium"}
          disabled={disableCancel}
          onClick={this.cancelModal}
        >
          Cancel
        </Button>
      </div>
    );

    return (
      <LayoutPage
        className="pending-order"
        title={title}
        action={noActions ? null : action}
      >
        <BreadcrumbsItem to="/pending-order-management">
          Pending order management
        </BreadcrumbsItem>

        {noActions ? (
          <div className="notice">
            <div className="notice__text">
              <p>
                You have no automatic consumable orders pending shipment
                from&nbsp;EPBB&nbsp;machines.
              </p>
              <p>
                If this is an error please call 0800 493 769 or{" "}
                <a href="mailto:shm-fbnz-contact.centre@fujifilm.com">
                  Email us
                </a>
              </p>
            </div>
          </div>
        ) : (
          <Fragment>
            {this.renderTabs("today")}
            {this.renderTabs("consolidated")}
            {this.renderTabs("future")}
          </Fragment>
        )}
      </LayoutPage>
    );
  }
}

export default PendingOrder;
