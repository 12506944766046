import React, { Component } from "react";
import { LayoutPage } from "scripts/components/layouts";
import { Button, Search } from "scripts/components/parts";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "scripts/components/parts/Table";

class SelectEquipment extends Component {
  state = {
    filterValue: "",
  };

  filter = (filterValue) => {
    this.setState({ filterValue });
  };

  handleOptionChange = (e) => {
    const { selectEquipment } = this.props;
    selectEquipment(e.target.value);
  };

  renderTable = (newEquipments) => {
    const { selectedEquipment } = this.props;
    const { finishStep } = this.props.injectedProps;

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="hide-sm">Description</TableCell>
            <TableCell colSpan="2">Serial number</TableCell>
            <TableCell>Log a call</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {newEquipments.map((equip, idx) => {
            const { machinesID } = equip;
            let isSelected = false;
            if (selectedEquipment)
              isSelected = selectedEquipment.machinesID === machinesID;

            return (
              <TableRow key={idx} isSelected={isSelected}>
                <TableCell className="hide-sm">
                  {equip.modelDescription}
                </TableCell>
                <TableCell>{equip.serialNo || ""}</TableCell>
                <TableCell modifier={"radio"} align={"center"}>
                  <input
                    type="radio"
                    id={`log-${machinesID}`}
                    value={machinesID}
                    onChange={this.handleOptionChange}
                    checked={isSelected}
                  />
                  <label htmlFor={`log-${machinesID}`}></label>
                </TableCell>
                <TableCell type="narrow">
                  <Button
                    modifier={"solid"}
                    disabled={!isSelected}
                    onClick={finishStep}
                  >
                    Next
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  render() {
    const { equipments, renderStepNav } = this.props;
    const { filterValue } = this.state;

    if (!equipments) return null;
    const title = "Select equipment";

    let newEquipments = JSON.parse(JSON.stringify(equipments));

    if (filterValue) {
      newEquipments = newEquipments.filter(
        (e) =>
          e.serialNo &&
          e.serialNo.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
      );
    }

    const error =
      newEquipments.length < 1
        ? { message: "No results match your search, ngana anō - try again." }
        : false;

    const control = (
      <Search
        pre={"Search"}
        placeholder={`by serial number`}
        onChange={this.filter}
        error={error}
      />
    );

    const action = renderStepNav(this.props.injectedProps);

    return (
      <LayoutPage
        className="service-call"
        title={title}
        control={control}
        action={action}
      >
        {this.renderTable(newEquipments)}
      </LayoutPage>
    );
  }
}

export default SelectEquipment;
