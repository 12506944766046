// import config from 'config';
import { authHeader, handleResponse, config } from "scripts/_helpers";
import { authenticationService } from "scripts/_services";

export const meterService = {
  getAll,
  submitReadings,
  getAllByEmailToken,
  submitReadingsByEmailToken,
  getAllByContact,
};

function getAll() {
  const ClientNo = authenticationService.currentUserValue.client.clientNo;
  console.log("getAll meterService", ClientNo);

  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ ClientNo }),
  };

  return fetch(`${config.apiUrl}/meter/getByClientNo`, requestOptions).then(
    handleResponse
  );
}

function getAllByContact() {
  const ClientNo = authenticationService.currentUserValue.client.clientNo;
  console.log("getAllByContact meterService", ClientNo);

  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ ClientNo }),
  };

  return fetch(`${config.apiUrl}/meter/getByContact`, requestOptions).then(
    handleResponse
  );
}

function submitReadings(meters, ValidateOnly = false) {
  const clientNo = authenticationService.currentUserValue.client.clientNo;
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ meters, clientNo, ValidateOnly }),
  };

  console.log("submitReadings API", requestOptions);

  return fetch(`${config.apiUrl}/meter/submitReadings`, requestOptions).then(
    handleResponse
  );
}

function getAllByEmailToken(RunNum, Email, MetersID, Resend) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign({ "Content-Type": "application/json" }),
    body: JSON.stringify({ RunNum, Email, MetersID, Resend }),
  };
  return fetch(`${config.apiUrl}/meter/getByRunNum`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      sessionStorage.setItem("emailToken", response.accessToken);

      return response;
    });
}

function submitReadingsByEmailToken(payload) {
  console.log(payload);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("emailToken")}`,
    },
    body: JSON.stringify(payload),
  };

  console.log("submitReadingsByEmailToken API", requestOptions);

  return fetch(
    `${config.apiUrl}/meter/submitReadingsByToken`,
    requestOptions
  ).then(handleResponse);
}
