import React, { Component } from "react";
import { w } from "scripts/utils/w";
import { isMobile } from "scripts/utils/browser-detect";

/*
Possible props:


- size: 'small', 'normal',
- className: String,
- isVisible: optional prop, to determine the conrol of hide/show
- children - content of tooltip
*/

class Tooltip extends Component {
  state = {
    isVisible: false,
    pos: "right",
  };

  constructor(props) {
    super(props);
    this.w = w();
    this.contentRef = React.createRef();
  }

  checkPosition = () => {
    // const rect = this.contentRef.current.getBoundingClientRect();
    // console.log(rect.left, rect.right);
    // if (rect.left < 0 || rect.right > this.w.w) {
    //   this.setState({ pos: "top" });
    //   const style = {};
    //   const arrowStyle = {};
    //   if (rect.left < 0) {
    //     const left = Math.round(Math.abs(rect.left) + 15);
    //     style.transform = `translate3d(${left}px, 0, 0)`;
    //     arrowStyle.transform = `translate3d(${-left}px, 0, 0)`;
    //   }
    //   if (rect.right > this.w.w) {
    //     const left = Math.round(Math.abs(rect.left) - 15);
    //     style.transform = `translate3d(${-left}px, 0, 0)`;
    //     arrowStyle.transform = `translate3d(${left - 30 - 20 - 9}px, 0, 0)`;
    //   }
    //   this.setState({ style, arrowStyle });
    // } else {
    //   this.setState({ pos: "right" });
    // }
  };

  componentDidUpdate(prevProps) {
    const { showTooltip } = this.props;
    // Typical usage (don't forget to compare props):
    if (showTooltip !== prevProps.showTooltip) {
      if (showTooltip) this.show();
      else this.hide();
    }
  }

  toggle = () => {
    const { isVisible } = this.state;

    if (isVisible) {
      this.hide();
    } else {
      this.show();
    }
  };

  show = () => {
    const { isVisible } = this.state;
    if (isVisible) return;

    this.checkPosition();
    this.setState({ isVisible: true });
  };

  hide = () => {
    const { isVisible } = this.state;
    if (!isVisible) return;

    // Remove styles, so can detect tooltip position again
    this.setState({ isVisible: false, style: {}, arrowStyle: {} });
  };

  render() {
    let { pos, isVisible } = this.state;
    let {
      children,
      className,
      size,
      noIcon,
      showTooltip,
      align,
      ...rest
    } = this.props;

    let events = {};

    if (isMobile) {
      events.onClick = this.toggle;
    } else {
      events.onMouseEnter = this.show;
      events.onMouseLeave = this.hide;
    }

    let classes = [];

    if (className) classes.push(className);
    if (noIcon) classes.push("tooltip--no-icon");
    if (isMobile) classes.push("tooltip--mobile");
    if (pos) classes.push(`tooltip--${pos}`);
    if (align) classes.push(`tooltip--${pos}`);
    classes.push(`tooltip--${size || "normal"}`);
    classes.push(`tooltip--align-${align || "right"}`);

    return (
      <div className={` tooltip ${classes.join(" ")}`} {...rest}>
        {!noIcon ? <div className="tooltip__icon" {...events} /> : ""}
        <div
          className={`tooltip__content ${isVisible ? "is-visible" : ""}`}
          ref={this.contentRef}
        >
          <div className={`tooltip__arrow`}></div>
          {children}
          <div className={`tooltip__close`} onClick={this.hide}></div>
        </div>
      </div>
    );
  }
}

export { Tooltip };
