import React, { Component } from "react";

class Table extends Component {
  render() {
    const { layout } = this.props;
    return (
      <table className={`table ${layout ? `table--${layout}` : ""}`}>
        {this.props.children}
      </table>
    );
  }
}

export { Table };
