// import config from 'config';
import { authHeader, handleResponse, config } from "scripts/_helpers";
import { authenticationService } from "scripts/_services";

export const contactService = {
  getAll,
  addContact,
  deleteContact,
  getById,
  editContact,
  replaceContact,
};

function getAll() {
  const ClientNo = authenticationService.currentUserValue.client.clientNo;
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ ClientNo }),
  };
  console.log("getAll", ClientNo);
  return fetch(`${config.apiUrl}/contact/getByClientNo`, requestOptions).then(
    handleResponse
  );
}

function addContact(data) {
  const ClientNo = authenticationService.currentUserValue.client.clientNo;

  data.contact.ClientNo = ClientNo;

  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify(data),
  };

  console.log("addContact API", data);

  return fetch(`${config.apiUrl}/contact/add`, requestOptions).then(
    handleResponse
  );
}

function deleteContact(ContNo) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ ContNo }),
  };

  console.log("deleteContact API", requestOptions);

  return fetch(`${config.apiUrl}/contact/delete`, requestOptions).then(
    handleResponse
  );
}

function editContact(data) {
  const ClientNo = authenticationService.currentUserValue.client.clientNo;

  data.contact.ClientNo = ClientNo;
  data.contact.StdCode = "09";

  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify(data),
  };

  console.log("editContact API", data);

  return fetch(`${config.apiUrl}/contact/update`, requestOptions).then(
    handleResponse
  );
}

function replaceContact(data) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify(data),
  };

  console.log("replaceContact API", data);

  return fetch(`${config.apiUrl}/contact/replace`, requestOptions).then(
    handleResponse
  );
}

function getById(ContNo) {
  const requestOptions = {
    method: "POST",
    headers: Object.assign(
      { "Content-Type": "application/json" },
      authHeader()
    ),
    body: JSON.stringify({ ContNo }),
  };
  console.log("getById", requestOptions);
  return fetch(`${config.apiUrl}/contact/getByContNo`, requestOptions).then(
    handleResponse
  );
}
