import React, { Component } from "react";

class StepsNav extends Component {
  renderItem = (item, index) => {
    const { currentIndex } = this.props;
    const { title, onClick } = item;
    const isCurrent = index === currentIndex;

    const classes = ["steps__item"];

    if (!isCurrent && onClick) classes.push("is-clickable");
    if (isCurrent) classes.push("is-current-step");

    return (
      <div key={index} className={classes.join(" ")} onClick={onClick || null}>
        <span>{index + 1}</span> <span>{title}</span>
      </div>
    );
  };

  render() {
    const { items } = this.props;

    return <div className="steps">{items.map(this.renderItem)}</div>;
  }
}

export { StepsNav };
