import React, { Component, Fragment } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { LayoutPanel } from "scripts/components/layouts";
import { Button, Panel, Address } from "scripts/components/parts";
import {
  isInitialValid,
  Input,
  FormField,
  Select,
} from "scripts/components/form";
import { AppContext } from "scripts/context/app-context";
import deptOptions from "scripts/_helpers/getDepartments";
import { addressService } from "scripts/_services";

class Welcome extends Component {
  static contextType = AppContext;

  state = {
    addresses: null,
  };

  async componentDidMount() {
    const { showLoading, hideLoading } = this.context.loading;
    const { AccountNum, SerialNo } = this.props.data;

    showLoading();

    try {
      const { addresses } = await addressService.forRegistration({
        AccountNum,
        SerialNum: SerialNo,
      });

      this.setState({ addresses });
      hideLoading();
    } catch (err) {
      hideLoading();
      console.log("error", err);
    }
  }

  renderForm = (props) => {
    const { isValid } = props;

    const addresses = this.state?.addresses?.map((a) => {
      return {
        value: a.clientNo,
        label: a.address,
      };
    });

    return (
      <Form className="form form--labels-above">
        <div className="form__group">
          <div className="form__col form__col--firstName">
            <Input
              label={{ text: "First name" }}
              name={`firstName`}
              form={props}
              autofocus={true}
            />
          </div>

          <div className="form__col form__col--lastName">
            <Input
              label={{ text: "Last name" }}
              name={`lastName`}
              form={props}
            />
          </div>
        </div>

        <div className="form__group">
          <Input
            label={{ text: "Email address" }}
            name={`email`}
            type={`email`}
            form={props}
          />
        </div>
        <div className="form__group">
          <Input label={{ text: "Phone" }} name={`phone`} form={props} />
        </div>
        <div className="form__group">
          <Input label={{ text: "Job title" }} name={`jobTitle`} form={props} />
        </div>
        <div className="form__group">
          <Select
            label={{ text: "Dept" }}
            name={`department`}
            form={props}
            placeholder="Department"
            defaultValue={deptOptions.find(
              (o) => o.value === props.initialValues.department
            )}
            options={deptOptions}
          />
        </div>

        <div className="form__group">
          <FormField label={{ text: "Location" }} name={`address`}>
            <Address
              form={props}
              options={addresses}
              name={`address`}
              modifier="default"
            />
          </FormField>
        </div>

        <div className="form__submit">
          <Button type="submit" disabled={!isValid}>
            Next
          </Button>
        </div>
      </Form>
    );
  };

  handleSubmit = (fields, { setStatus, setSubmitting }) => {
    const { data } = this.props;
    const { finishStep } = this.props.injectedProps;

    const newData = JSON.parse(JSON.stringify(data));
    newData.FirstName = fields.firstName;
    newData.LastName = fields.lastName;
    newData.Email = fields.email;
    newData.Phone = fields.phone;
    newData.JobTitle = fields.jobTitle;
    newData.Department = fields.department;
    newData.AddressKey = fields.address;

    finishStep(newData);
  };

  render() {
    const { data } = this.props;
    const { firstName, lastName, email } = this.props.data;
    const title = "Welcome!";
    const text = (
      <Fragment>
        <p>
          We have you recorded as a customer&nbsp;from
          <br />
          <em>{data.tradeName}</em>
        </p>
        <p>If this is incorrect please call 0800&nbsp;493&nbsp;769.</p>
      </Fragment>
    );

    return (
      <LayoutPanel className="Welcome">
        <Panel title={title} text={text}>
          <Formik
            initialValues={{
              firstName: firstName || "",
              lastName: lastName || "",
              email: email || "",
              phone: "",
              jobTitle: "",
              department: "",
              address: "",
            }}
            isInitialValid={isInitialValid}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required("Required"),
              lastName: Yup.string().required("Required"),
              email: Yup.string()
                .email("Invalid email")
                .required("Email is required"),
              phone: Yup.string().required("Phone is required"),
              jobTitle: Yup.string().required("Job title is required"),
              department: Yup.string().required("Department is required"),
              address: Yup.string().required("Address is required"),
            })}
            onSubmit={this.handleSubmit}
            children={this.renderForm}
          />
        </Panel>
      </LayoutPanel>
    );
  }
}

export default Welcome;
