import React, { Component } from "react";
import { LayoutPage } from "scripts/components/layouts";

import { Button, DetailsForm } from "scripts/components/parts";
import { Formik, Form } from "formik";
import {
  isInitialValid,
  detailsSchema,
  getContactFromFields,
  getMachinesFromFields,
} from "scripts/components/form";

import { contactService } from "scripts/_services";
import { getError, updateEquipmentSerialNo } from "scripts/_helpers";
import * as Yup from "yup";
import { AppContext } from "scripts/context/app-context";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

class AddContact extends Component {
  static contextType = AppContext;

  state = {
    equipments: null,
    suggestions: null,
  };

  async componentDidMount() {
    const { showLoading, hideLoading } = this.context.loading;

    showLoading();

    try {
      const { contacts, machines: equipments } = await contactService.getAll();

      equipments.forEach(updateEquipmentSerialNo);
      const suggestions = contacts.map((contact) => {
        return {
          value: `${contact.firstName} ${contact.lastName}`,
          link: `/accounts/our-contacts/view-contact/${contact.contNo}`,
        };
      });

      equipments.forEach((e) => {
        e.meters = "0";
        e.service = "0";
        e.consum = "0";
        e.billing = "0";
      });

      console.log(equipments);
      this.setState({ suggestions, equipments });

      hideLoading();
    } catch (err) {
      hideLoading();
    }
  }

  renderForm = (props) => {
    const { isValid, status } = props;

    return (
      <Form className="form">
        <DetailsForm
          form={props}
          type={"add"}
          equipments={this.state.equipments}
          suggestions={this.state.suggestions}
        />

        {status && <div className="form__error">{status}</div>}

        <div className="form__submit">
          <Button type="submit" disabled={!isValid} modifier={"solid-inline"}>
            Save Changes
          </Button>
        </div>
      </Form>
    );
  };

  handleSubmit = async (fields, { setStatus, setSubmitting, resetForm }) => {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;

    const data = {
      contact: getContactFromFields(fields),
      machineContacts: getMachinesFromFields(fields),
    };

    showLoading();
    setStatus();

    try {
      const response = await contactService.addContact(data);
      console.log("response", response);
      hideLoading();
      // Reset the form
      resetForm();
      bar.showBar({
        content: `This new contact has been added successfully.`,
      });
      this.props.history.push("/accounts/our-contacts");
    } catch (err) {
      hideLoading();
      setSubmitting(false);
      setStatus(getError(err));
    }
  };

  render() {
    const title = "Add new contact";

    return (
      <LayoutPage className="add-contact" title={title}>
        <BreadcrumbsItem to="/accounts/our-contacts/add-contact">
          Add new contact
        </BreadcrumbsItem>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            address: "",
            cellPhone: "",
            phone: "",
            jobTitle: "",
            department: "",
            email: "",
          }}
          isInitialValid={isInitialValid}
          validationSchema={Yup.object().shape(detailsSchema)}
          onSubmit={this.handleSubmit}
          children={this.renderForm}
        />
      </LayoutPage>
    );
  }
}

export default AddContact;
