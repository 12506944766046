import React, { Component } from "react";
import { isMobile } from "scripts/utils/browser-detect";
import { Tooltip } from "scripts/components/parts";
import { Field } from "formik";
import { Link } from "react-router-dom";
import {
  isFieldValidClass,
  Password,
  FormField,
  checkCapsLock,
} from "scripts/components/form";

/*
Possible props:

- label: {
    pos: 'above', 'inline',
    className: '',
    text: String
}

- name: String (Formik name id)
- type: String (Field type)
- form: props object (Passed from Formik)
- tooltip: content for tooltip
*/

class Input extends Component {
  state = {
    capsLockOn: false,
    focused: false,
  };

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  focus = (force = false) => {
    // const { autofocus, name } = this.props;
    // console.log(this.ref, 'focus')

    // If mobile, don't focus
    if (isMobile && !force) return;

    setTimeout(() => {
      // console.log(this.ref.current, 'focus')

      if (this.ref.current) {
        this.ref.current.focus();
      }
    }, 1);
  };

  handleKeyUp = (e) => {
    this.setState({ capsLockOn: checkCapsLock(e) });
    // console.log("handleKeyUp", checkCapsLock(e));
  };

  handleMouseDown = (e) => {
    this.setState({ capsLockOn: checkCapsLock(e) });
    // console.log("handleMouseDown", checkCapsLock(e));
  };

  componentDidMount() {
    const { autofocus } = this.props;
    // Focus field if this.props.focused exists
    // When coming back from "Confrim" to "Edit"
    if (autofocus) this.focus();

    document.addEventListener("mousedown", this.onDocumentMouseDown);
    document.addEventListener("mouseup", this.onDocumentMouseUp);
  }

  componentDidUpdate(prevProps) {
    const { autofocus } = this.props;

    if (autofocus !== prevProps.autofocus) {
      // Focus field if this.props.focused exists
      // When coming back from "Confrim" to "Edit"
      // console.log(name, "autofocus:", autofocus);
      if (autofocus) this.focus();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onDocumentMouseDown);
    document.removeEventListener("mouseup", this.onDocumentMouseUp);
  }

  suggestionsFilter = () => {
    const { focused } = this.state;
    const { suggestions, form, name } = this.props;

    if (suggestions && form.values[name] && focused) {
      // if (suggestions && form.values[name]) {
      return suggestions.filter(
        (s) =>
          s.value.toLowerCase().indexOf(form.values[name].toLowerCase()) !== -1
      );
    } else {
      return null;
    }
  };

  handleFocus = () => {
    this.setState({ focused: true });
  };

  handleBlur = (e) => {
    if (this.justClickedOnSuggestionsContainer) {
      this.ref.current.focus();
      return;
    }

    const { form, name } = this.props;

    this.setState({ focused: false });

    // this is going to call setFieldTouched and manually update touched.
    if (form) {
      form.setFieldTouched(name, true);
      form.handleBlur(e);
    }
  };

  handleChange = (e) => {
    const { name, form } = this.props;
    let { value } = e.target;

    // Remove any emoji char
    value = value.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    );
    form.setFieldValue(name, value);
  };

  onDocumentMouseUp = () => {
    if (this.pressedSuggestion && !this.justSelectedSuggestion) {
      this.input.focus();
    }
    this.pressedSuggestion = null;
  };

  onDocumentMouseDown = (event) => {
    this.justClickedOnSuggestionsContainer = false;

    let node =
      (event.detail && event.detail.target) || // This is for testing only. Please show me a better way to emulate this.
      event.target;

    while (node !== null && node !== document) {
      if (
        node.getAttribute &&
        node.getAttribute("data-suggestion-index") !== null
      ) {
        // Suggestion was clicked
        this.justClickedOnSuggestionsContainer = true;
        return;
      }

      if (node === this.suggestionsContainer) {
        // Something else inside suggestions container was clicked
        this.justClickedOnSuggestionsContainer = true;
        return;
      }

      node = node.parentNode;
    }
  };

  suggestionProps = (itemIndex) => {
    return {
      "data-suggestion-index": itemIndex,
      onMouseEnter: this.onSuggestionMouseEnter,
      onMouseLeave: this.onSuggestionMouseLeave,
      onMouseDown: this.onSuggestionMouseDown,
      onTouchStart: this.onSuggestionTouchStart,
      onTouchMove: this.onSuggestionTouchMove,
      onClick: this.onSuggestionClick,
    };
  };
  onSuggestionMouseEnter = (event) => {
    if (event.target === this.pressedSuggestion) {
      this.justSelectedSuggestion = true;
    }

    this.justMouseEntered = true;

    setTimeout(() => {
      this.justMouseEntered = false;
    });
  };
  onSuggestionMouseLeave = (event) => {
    if (
      this.justSelectedSuggestion &&
      event.target === this.pressedSuggestion
    ) {
      this.justSelectedSuggestion = false;
    }
  };
  onSuggestionMouseDown = (event) => {
    // Checking if this.justSelectedSuggestion is already true to not duplicate touch events in chrome
    // See: https://github.com/facebook/react/issues/9809#issuecomment-413978405
    if (!this.justSelectedSuggestion) {
      this.justSelectedSuggestion = true;
      this.pressedSuggestion = event.target;
    }
  };
  onSuggestionTouchStart = (e) => {
    this.justSelectedSuggestion = true;
    // todo: event.preventDefault when https://github.com/facebook/react/issues/2043
    // todo: gets released so onSuggestionMouseDown won't fire in chrome
  };
  onSuggestionTouchMove = (e) => {
    this.justSelectedSuggestion = false;
    this.pressedSuggestion = null;
    this.ref.current.focus();
  };
  onSuggestionClick = (e) => {
    this.setState({ focused: false });

    setTimeout(() => {
      this.justSelectedSuggestion = false;
    });
  };

  render() {
    const { capsLockOn } = this.state;
    const {
      label,
      name,
      type,
      form,
      tooltip,
      isRequired,
      autofocus,
      value,
      suggestions,
      disabledLink,
      disabled,
      ...rest
    } = this.props;

    return (
      <FormField
        label={label}
        name={name}
        isRequired={isRequired}
        capsLockOn={capsLockOn}
        form={form}
      >
        {type === "password" ? (
          <Password
            name={name}
            form={form}
            {...rest}
            onKeyUp={this.handleKeyUp}
            onMouseDown={this.handleMouseDown}
            tooltip={tooltip}
            innerRef={this.ref}
            focusFunc={this.focus}
          />
        ) : (
          <div className="input">
            {disabledLink && disabled && form.values[name] ? (
              <a {...disabledLink} className="input__disabledLink">
                {form.values[name]}
              </a>
            ) : (
              ""
            )}

            <Field
              type={type || "text"}
              name={name}
              className={`input__input ${
                form ? isFieldValidClass(name, form) : ""
              } ${
                disabledLink && disabled && form.values[name]
                  ? "input__input--hidden"
                  : ""
              }`}
              {...rest}
              disabled={disabled}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              innerRef={this.ref}
            />

            {this.suggestionsFilter() ? (
              <div className="input__suggestions">
                {this.suggestionsFilter().map((s, i) => {
                  return (
                    <Link
                      className="input__suggestion"
                      key={i}
                      to={s.link}
                      {...this.suggestionProps(i)}
                      data-suggestion-index={i}
                    >
                      {s.value}
                    </Link>
                  );
                })}
              </div>
            ) : (
              ""
            )}

            {tooltip ? (
              <div className="input__tooltip">
                <Tooltip>{tooltip}</Tooltip>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </FormField>
    );
  }
}

export { Input };
