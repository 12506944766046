export const roles = {
  default: {
    edit_name: true,
    view_contact: true,
    edit_contact: true,
    add_contact: true,
  },
  AuthenticatedUsers: {
    //
  },
  ServerAdministrators: {
    switch_accounts: true,
  },
};
