import React, { Component } from "react";

const items = [
  {
    icon: require("../../../images/big-icon/icon-printer.svg"),
    title: "MANAGE YOUR ACCOUNT",
  },
  {
    icon: require("../../../images/big-icon/icon-meter.svg"),
    title: "SUBMIT A METER&nbsp;READING",
  },
  {
    icon: require("../../../images/big-icon/icon-tools.svg"),
    title: "LOG A SERVICE&nbsp;CALL",
  },
  {
    icon: require("../../../images/big-icon/icon-ink.svg"),
    title: "ORDER CONSUMABLES",
  },
];

class WelcomePanel extends Component {
  renderItem = ({ icon, title }) => {
    return (
      <div className={`welcome-panel__item`} key={title}>
        <div className={`welcome-panel__item-icon`}>
          <img src={icon} alt={title} />
        </div>
        <div
          className={`welcome-panel__item-title`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
    );
  };

  render() {
    return (
      <div className={`welcome-panel`}>
        <div className={`welcome-panel__content`}>
          <div className={`welcome-panel__head`}>
            <h2>Welcome to MyAccount</h2>
            <p>
                Manage all your printing devices, enter meter readings, order consumables, and more from our easy-to-use web portal.
            </p>
          </div>

          <div className={`welcome-panel__items`}>
            {items.map(this.renderItem)}
          </div>

          <div className={`welcome-panel__footer`}>
            <div className={`welcome-panel__footer-h`}>Need to register?</div>
            <div className={`welcome-panel__footer-text`}>
                With MyAccount, you can check your usage, update billing details, order toner and more. If you need to create an account, click ‘Register New User’ or call our Contact Centre on 0800 493 769.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { WelcomePanel };
