import React, { Component } from "react";

class Panel extends Component {
  render() {
    const {
      children,
      title,
      text,
      bottom,
      veryBottom,
      className,
      onClose,
    } = this.props;

    return (
      <div
        className={`panel ${className || ""} ${onClose ? "panel--close" : ""}`}
      >
        {onClose ? (
          <div className={`panel__close`} onClick={onClose}></div>
        ) : (
          ""
        )}
        <div className={`panel__wrap`}>
          {title ? (
            <h1
              className={`panel__title`}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          ) : (
            ""
          )}
          {text ? <div className={`panel__text`}>{text}</div> : ""}
          <div className={`panel__content`}>{children}</div>
          {bottom ? <div className={`panel__bottom`}>{bottom}</div> : ""}
          {veryBottom ? (
            <div className={`panel__very-bottom`}>{veryBottom}</div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export { Panel };
