import React, { Component } from "react";

/* Routing
------------------------------------------------- */
import { BrowserRouter, Route } from "react-router-dom";
import { history } from "scripts/_helpers";
import { authenticationService, refreshService } from "scripts/_services";
import { PrivateRoute } from "scripts/components/routing/PrivateRoute";

/* Pages
------------------------------------------------- */
import Home from "scripts/components/pages/Home";
import Login from "scripts/components/pages/Login";
import Register from "scripts/components/pages/Register/index.js";
import ForgottenDetails from "scripts/components/pages/ForgottenDetails";
import ContactUs from "scripts/components/pages/ContactUs";
import MeterReadings from "scripts/components/pages/MeterReadings/index.js";
import ServiceCall from "scripts/components/pages/ServiceCall/index.js";
import ServiceHistory from "scripts/components/pages/ServiceHistory";
import MyDetails from "scripts/components/pages/MyDetails";

import OurContacts from "scripts/components/pages/OurContacts/index.js";

import UnpaidInvoices from "scripts/components/pages/UnpaidInvoices";
import BilledUsage from "scripts/components/pages/BilledUsage";
import PendingOrder from "scripts/components/pages/PendingOrder";
import LogOut from "scripts/components/pages/LogOut";
import AccountDisabled from "scripts/components/pages/AccountDisabled";
import SwitchAccounts from "scripts/components/pages/SwitchAccounts";
import ValidateUser from "scripts/components/pages/ValidateUser";
import ConsumableReturnsPolicy from "scripts/components/pages/ConsumableReturnsPolicy";

/* Other
------------------------------------------------- */
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import IdleTimer from "react-idle-timer";

import {
  Header,
  ScrollToTop,
  NotificationBar,
  Loading,
} from "scripts/components/parts";
import Modal from "react-modal";
import { AppContext } from "scripts/context/app-context";

Modal.setAppElement("#root");

/*
 * Amount of minutes before triggers idle function
 * Will log user out if was idle for (~10min)
 * If user stayed active, will refersh the token
 */
const idleMinTimeout = 30;

export default class App extends Component {
  themeSections = [];

  barTimeoutId = null;

  constructor(props) {
    super(props);

    this.idleTimer = null;

    this.state = {
      appContext: {
        currentUser: null,
        modal: {
          isOpen: false,
          children: null,
          openModal: this.openModal,
          closeModal: this.closeModal,
        },
        bar: {
          isOpen: false,
          props: {},
          showBar: this.showBar,
        },
        loading: {
          isOpen: false,
          showLoading: this.showLoading,
          hideLoading: this.hideLoading,
        },
      },
    };
  }

  componentDidMount() {
    const newUser = (x) => {
      console.log("App mount. User:", x);
      if (x) {
        /*
         * When successfully logged in
         * Start a token refresh cycle, so that user can keep using API
         * As long as they are not idle
         */
        refreshService.startRefreshCycle();

        document.body.classList.add("is-logged-in");
      } else {
        document.body.classList.remove("is-logged-in");
      }

      this.setState(({ appContext }) => {
        appContext.currentUser = x;
        return { appContext };
      });
    };

    authenticationService.currentUser.subscribe(newUser);
  }

  openModal = (children) => {
    this.setState(({ appContext }) => {
      appContext.modal.isOpen = true;
      appContext.modal.children = children;
      return { appContext };
    });
  };

  closeModal = () => {
    this.setState(({ appContext }) => {
      appContext.modal.isOpen = false;
      appContext.modal.children = null;
      return { appContext };
    });
  };

  showBar = (props, timeout = 4000) => {
    const { isOpen } = this.state.appContext.bar;

    if (isOpen) {
      return;
    }

    this.setState(({ appContext }) => {
      appContext.bar.isOpen = true;
      appContext.bar.props = props;
      return { appContext };
    });

    this.barTimeoutId = setTimeout(() => {
      this.hideBar();
    }, timeout);
  };

  hideBar = () => {
    this.setState(({ appContext }) => {
      appContext.bar.isOpen = false;
      // appContext.bar.props = null;
      return { appContext };
    });
  };

  showLoading = (props) => {
    const { isOpen } = this.state.appContext.loading;
    if (isOpen) {
      return;
    }

    this.setState(({ appContext }) => {
      appContext.loading.isOpen = true;
      return { appContext };
    });
  };

  hideLoading = () => {
    this.setState(({ appContext }) => {
      appContext.loading.isOpen = false;
      return { appContext };
    });
  };

  // Idle timer functions

  handleOnAction = (event) => {
    // console.log("user did something", event);
  };

  handleOnActive = (event) => {
    // console.log("user is active", event);
    // console.log("time remaining", this.idleTimer.getRemainingTime());
  };

  handleOnIdle = (event) => {
    // console.log("user is idle, logging out", event);
    // console.log("last active", this.idleTimer.getLastActiveTime());
    authenticationService.logout();
  };

  render() {
    const { appContext } = this.state;
    const { modal, bar, loading, currentUser } = appContext;

    return (
      <div className={`app`}>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={1000 * 60 * idleMinTimeout}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />

        <AppContext.Provider value={appContext}>
          <BrowserRouter history={history} basename={process.env.PUBLIC_URL}>
            <ScrollToTop />
            {currentUser ? (
              <BreadcrumbsItem to="/">Home</BreadcrumbsItem>
            ) : null}

            <Header />

            <NotificationBar isOpen={bar.isOpen} props={bar.props} />

            <Loading isOpen={loading.isOpen} />

            <Modal
              isOpen={modal.isOpen}
              onRequestClose={this.closeModal}
              shouldCloseOnOverlayClick={true}
              shouldCloseOnEsc={true}
              shouldFocusAfterRender={true}
              portalClassName={`modal`}
              overlayClassName={`modal__overlay`}
              className={`modal__content`}
            >
              {modal.children}
            </Modal>

            <Route path="/login" component={Login} />
            <Route path="/default.aspx" component={Login} />
            <Route path="/forgotten-details" component={ForgottenDetails} />
            <Route path="/register" component={Register} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/validate-user" component={ValidateUser} />
            <Route path="/confirmation.aspx" component={ValidateUser} />
            <Route path="/account-disabled" component={AccountDisabled} />
            <Route
              path="/meter-readings-email"
              render={(props) => <MeterReadings fromEmail={true} {...props} />}
            />
            <Route
              path="/readings/submit.aspx"
              render={(props) => <MeterReadings fromEmail={true} {...props} />}
            />
            <Route
              path="/mreads_cb_submit.aspx"
              render={(props) => <MeterReadings fromEmail={true} {...props} />}
            />
            <Route
              path="/pending-order-management-email/:token"
              render={(props) => <PendingOrder fromEmail={true} {...props} />}
            />
            <Route path="/log-out" component={LogOut} />

            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute path="/meter-readings" component={MeterReadings} />
            <PrivateRoute path="/service-call" component={ServiceCall} />
            <PrivateRoute path="/my-details" component={MyDetails} />
            <PrivateRoute
              path="/accounts/service-history"
              component={ServiceHistory}
            />

            <PrivateRoute
              path="/accounts/our-contacts"
              component={OurContacts}
            />

            <PrivateRoute
              path="/accounts/unpaid-invoices"
              component={UnpaidInvoices}
            />
            <PrivateRoute
              path="/accounts/billed-usage"
              component={BilledUsage}
            />
            <PrivateRoute
              path="/pending-order-management"
              component={PendingOrder}
            />
            <PrivateRoute
              path="/consumable-returns-policy"
              component={ConsumableReturnsPolicy}
            />

            <PrivateRoute
              path="/switch-accounts"
              permissions={["switch_accounts"]}
              component={SwitchAccounts}
            />
          </BrowserRouter>
        </AppContext.Provider>
      </div>
    );
  }
}
