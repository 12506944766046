import React, { Component } from "react";
import { LayoutPage } from "scripts/components/layouts";
import { Reveal } from "scripts/components/parts";
import { getSerialNo } from "scripts/_helpers";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "scripts/components/parts/Table";

import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { AppContext } from "scripts/context/app-context";
import moment from "moment";

import { equipmentService } from "scripts/_services";

class ServiceHistory extends Component {
  static contextType = AppContext;

  state = {
    serviceHistory: null,
  };

  async componentDidMount() {
    const { showLoading, hideLoading } = this.context.loading;
    const { bar } = this.context;
    showLoading();

    try {
      const { serviceHistory } = await equipmentService.getServiceHistory();

      console.log(serviceHistory);
      hideLoading();
      this.processData({ serviceHistory });
    } catch (err) {
      bar.showBar(
        {
          content: "There has been a problem with getting service history",
          modifier: "red",
        },
        8000
      );
      hideLoading();
    }
  }

  processData = ({ serviceHistory }) => {
    serviceHistory.forEach((s) => {
      s.serialNo = getSerialNo(s.serialNo);
      s.dateDisplay = moment(s.callDate).format("DD/MM/YY [at] h:mma");
    });

    this.setState({ serviceHistory });
  };

  renderTable = () => {
    const { serviceHistory } = this.state;

    return (
      <Table layout="fixed">
        <TableHead>
          <TableRow>
            <TableCell className="hide-md" style={{ width: "180px" }}>
              Date logged
            </TableCell>
            <TableCell style={{ width: "150px" }}>Serial number</TableCell>
            <TableCell>Your description</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {serviceHistory.map((item, idx) => {
            return (
              <TableRow key={idx}>
                <TableCell className="hide-md" valign="top">
                  {item.dateDisplay}
                </TableCell>
                <TableCell valign="top">{item.serialNo}</TableCell>
                <TableCell valign="top">
                  <Reveal>{item.notes}</Reveal>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  render() {
    const { serviceHistory } = this.state;
    if (!serviceHistory) return null;

    const title = "Service history";
    if (serviceHistory.length > 0) {
      return (
        <LayoutPage className="service-history" title={title}>
          <BreadcrumbsItem to="/accounts/service-history">
            Service history
          </BreadcrumbsItem>
          {this.renderTable()}
        </LayoutPage>
      );
    } else {
      return (
        <LayoutPage className="service-history" title={title}>
          <BreadcrumbsItem to="/accounts/service-history">
            Service history
          </BreadcrumbsItem>

          <div className="confirmation">
            <div className="confirmation__text">
              <p>
                You have not raised any Service or Analyst Calls in My Account
                yet.
              </p>
            </div>
          </div>
        </LayoutPage>
      );
    }
  }
}

export default ServiceHistory;
